import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITanimAracBilgiKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITanimAracBilgiKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_FirmaListe: any[];
  Get_Yonetim_FirmaListe_dummy: any[];
  Get_Tanim_TedarikciListe: any[];
  Get_Tanim_TedarikciListe_dummy: any[];
  Get_Tanim_AracById: any[];
  Get_Tanim_AracById_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  Add_Tanim_Arac: number;
  Add_Tanim_Arac_dummy: number;
  Update_Tanim_Arac: number;
  Update_Tanim_Arac_dummy: number;
  isComp237055Visible: "visible" | "hidden";
  isComp657860Visible: "visible" | "hidden";
  isComp304910Visible: "visible" | "hidden";
  isComptanimaracbilgikart_810791AuthorizationVisible: "visible" | "hidden";
}

export class TanimAracBilgiKart_ScreenBase extends React.PureComponent<ITanimAracBilgiKart_ScreenProps, any> {
  tanimaracbilgikart_810791_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimaracbilgikart_128337_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimaracbilgikart_237055_value_kuikaAutoCompleteRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "f0f4ae06-ab78-497b-9cde-c279dac5539c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 891214, PropertyName: "value", Value: "ARAÇ EKLEME - DÜZENLEME" },
        { Id: 249608, PropertyName: "value", Value: "Firma" },
        { Id: 810791, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 48474, PropertyName: "value", Value: "Plaka" },
        { Id: 198377, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 125168, PropertyName: "value", Value: "Tipi" },
        { Id: 128337, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 767051, PropertyName: "value", Value: "Ruhsat No" },
        { Id: 607530, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 994318, PropertyName: "value", Value: "Marka" },
        { Id: 293322, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 684043, PropertyName: "value", Value: "Model" },
        { Id: 249909, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 172445, PropertyName: "value", Value: "Model Yılı" },
        { Id: 742449, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 909494, PropertyName: "value", Value: "Öz Mal" },
        { Id: 657860, PropertyName: "value", Value: "Tedarikçi" },
        { Id: 237055, PropertyName: "placeholder", Value: "Search..." },
        { Id: 974814, PropertyName: "label", Value: "Vazgeç" },
        { Id: 865072, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tanimaracbilgikart_810791_value_kuikaSelectBoxRef = React.createRef();
    this.tanimaracbilgikart_128337_value_kuikaSelectBoxRef = React.createRef();
    this.tanimaracbilgikart_237055_value_kuikaAutoCompleteRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_FirmaListe: [],
      Get_Tanim_TedarikciListe: [],
      Get_Tanim_AracById: [],
      ChangeVisibilityOf: "",
      Add_Tanim_Arac: 0,
      Update_Tanim_Arac: 0,
      dataSource_128337: [
        { key: "CEKICI", text: "ÇEKİCİ" },
        { key: "DORSE", text: "DORSE" }
      ],
      isComp237055Visible: "visible",
      isComp657860Visible: "visible",
      isComp304910Visible: "hidden",
      isComptanimaracbilgikart_810791AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tanimaracbilgikart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TanimAracBilgiKartPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComptanimaracbilgikart_810791AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "SistemAdmin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("tanimaracbilgikart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tanimaracbilgikart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TanimAracBilgiKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tanimaracbilgikart_810791_value: this.state.Get_Tanim_AracById?.at?.(0)?.firmaId ?? undefined,
      tanimaracbilgikart_198377_value: this.state.Get_Tanim_AracById?.at?.(0)?.plaka ?? undefined,
      tanimaracbilgikart_128337_value: this.state.Get_Tanim_AracById?.at?.(0)?.tip ?? undefined,
      tanimaracbilgikart_607530_value: this.state.Get_Tanim_AracById?.at?.(0)?.ruhsatNo ?? undefined,
      tanimaracbilgikart_293322_value: this.state.Get_Tanim_AracById?.at?.(0)?.marka ?? undefined,
      tanimaracbilgikart_249909_value: this.state.Get_Tanim_AracById?.at?.(0)?.model ?? undefined,
      tanimaracbilgikart_742449_value: this.state.Get_Tanim_AracById?.at?.(0)?.modelYili ?? undefined,
      tanimaracbilgikart_514377_value: this.state.Get_Tanim_AracById?.at?.(0)?.ozMal ?? undefined,
      tanimaracbilgikart_237055_value: this.state.Get_Tanim_AracById?.at?.(0)?.tedarikciId ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TanimAracBilgiKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TanimAracBilgiKart/TanimAracBilgiKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.isComp304910Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.Get_Helpers_LoginUserInfo?.length > 0 ? stateVars.Get_Helpers_LoginUserInfo[0]?.rolName : null
        ),
        "SistemAdmin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.Get_Yonetim_FirmaListe = result?.data.get_Yonetim_FirmaListe;

    formVars.tanimaracbilgikart_810791_options = stateVars.Get_Yonetim_FirmaListe;
    stateVars.Get_Tanim_TedarikciListe = result?.data.get_Tanim_TedarikciListe;

    formVars.tanimaracbilgikart_237055_options = stateVars.Get_Tanim_TedarikciListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimAracBilgiKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TanimAracBilgiKart/TanimAracBilgiKartPageInit1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Get_Tanim_AracById = result?.data.get_Tanim_AracById;
      formVars.tanimaracbilgikart_810791_value =
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.firmaId : null;
      formVars.tanimaracbilgikart_198377_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.plaka : null
      );
      formVars.tanimaracbilgikart_128337_value =
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.tip : null;
      formVars.tanimaracbilgikart_607530_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.ruhsatNo : null
      );
      formVars.tanimaracbilgikart_293322_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.marka : null
      );
      formVars.tanimaracbilgikart_249909_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.model : null
      );
      formVars.tanimaracbilgikart_742449_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.modelYili : null
      );
      formVars.tanimaracbilgikart_514377_value = ReactSystemFunctions.value(
        this,
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.ozMal : null
      );
      formVars.tanimaracbilgikart_237055_value =
        stateVars.Get_Tanim_AracById?.length > 0 ? stateVars.Get_Tanim_AracById[0]?.tedarikciId : null;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp304910Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.rolName : null
        ),
        "SistemAdmin"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimAracBilgiKartPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_810791_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.firmaId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;
    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimAracBilgiKartPageInit5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_198377_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.plaka : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_128337_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.tip : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_607530_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.ruhsatNo : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_293322_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.marka : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_249909_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.model : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_742449_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.modelYili : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_514377_value = ReactSystemFunctions.value(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.ozMal : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimAracBilgiKartPageInit13_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimaracbilgikart_237055_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_AracById?.length > 0 ? this.state.Get_Tanim_AracById[0]?.tedarikciId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_237055 = this.state.Get_Tanim_TedarikciListe;
    stateVars.dataSource_237055 = this.state.Get_Tanim_TedarikciListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TanimAracBilgiKartComponent_585616_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimAracBilgiKartComponent_514377_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_514377_value", "value", "", "", "")
        ),
        false
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp237055Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_514377_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_514377_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartComponent_514377_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_514377_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp237055Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_514377_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_514377_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartComponent_514377_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_514377_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp657860Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_514377_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_514377_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimAracBilgiKartComponent_514377_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_514377_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp657860Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TanimAracBilgiKartComponent_974814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "43fc490a_712c_ab21_16b0_3731dcc99abb_confirmation",
        this.defaultML,
        "Kayıt edilmeyen veriler iptal edilecek"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TanimAracBilgiKartComponent_865072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.firmaId : null
        ),
        "Guid"
      ),
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_198377_value", "value", "", "", "")
        ),
        "string"
      ),
      Tip_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_128337_value", "value", "", "key", "")
        ),
        "string"
      ),
      RuhsatNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_607530_value", "value", "", "", "")
        ),
        "string"
      ),
      Marka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_293322_value", "value", "", "", "")
        ),
        "string"
      ),
      Model_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_249909_value", "value", "", "", "")
        ),
        "string"
      ),
      ModelYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_742449_value", "value", "", "", "")
        ),
        "number"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      OzMal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_514377_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TedarikciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimaracbilgikart_237055_value",
            "value",
            "Get_Tanim_TedarikciListe",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimAracBilgiKart/TanimAracBilgiKartComponent_865072_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Add_Tanim_Arac = result?.data.add_Tanim_Arac;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_865072_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_865072_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimAracBilgiKartComponent_865072_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tip_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_128337_value", "value", "", "key", "")
        ),
        "string"
      ),
      RuhsatNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_607530_value", "value", "", "", "")
        ),
        "string"
      ),
      Marka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_293322_value", "value", "", "", "")
        ),
        "string"
      ),
      Model_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_249909_value", "value", "", "", "")
        ),
        "string"
      ),
      ModelYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_742449_value", "value", "", "", "")
        ),
        "number"
      ),
      Ozmal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimaracbilgikart_514377_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TedarikciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimaracbilgikart_237055_value",
            "value",
            "Get_Tanim_TedarikciListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.AracId ?? this.props.screenInputs.aracid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimAracBilgiKart/TanimAracBilgiKartComponent_865072_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Update_Tanim_Arac = result?.data.update_Tanim_Arac;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_865072_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimAracBilgiKartComponent_865072_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimAracBilgiKartComponent_865072_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
