import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYonetimIlceKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYonetimIlceKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_UlkeListe: any[];
  Get_Yonetim_UlkeListe_dummy: any[];
  Get_Yonetim_IlceById: any[];
  Get_Yonetim_IlceById_dummy: any[];
  Get_Yonetim_SehirListe_ByUlkeId: any[];
  Get_Yonetim_SehirListe_ByUlkeId_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  Add_Yonetim_Ilce: number;
  Add_Yonetim_Ilce_dummy: number;
}

export class YonetimIlceKart_ScreenBase extends React.PureComponent<IYonetimIlceKart_ScreenProps, any> {
  yonetimilcekart_77019_value_kuikaSelectBoxRef: React.RefObject<any>;
  yonetimilcekart_296045_value_kuikaAutoCompleteRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "46fb891b-bdad-4783-afd8-5f739825bbb4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 891214, PropertyName: "value", Value: "İLÇE EKLEME - DÜZENLEME" },
        { Id: 44144, PropertyName: "value", Value: "Ülke" },
        { Id: 77019, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 249608, PropertyName: "value", Value: "Şehir Kodu" },
        { Id: 296045, PropertyName: "placeholder", Value: "Search..." },
        { Id: 821217, PropertyName: "value", Value: "İlçe Adı" },
        { Id: 65269, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 974814, PropertyName: "label", Value: "Vazgeç" },
        { Id: 865072, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.yonetimilcekart_77019_value_kuikaSelectBoxRef = React.createRef();
    this.yonetimilcekart_296045_value_kuikaAutoCompleteRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_UlkeListe: [],
      Get_Yonetim_IlceById: [],
      Get_Yonetim_SehirListe_ByUlkeId: [],
      GoBack: "",
      Add_Yonetim_Ilce: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yonetimilcekart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YonetimIlceKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yonetimilcekart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yonetimilcekart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YonetimIlceKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      yonetimilcekart_77019_value: this.state.Get_Yonetim_IlceById?.at?.(0)?.ulkeId ?? undefined,
      yonetimilcekart_65269_value: this.state.Get_Yonetim_IlceById?.at?.(0)?.ilceAdi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YonetimIlceKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YonetimIlceKart/YonetimIlceKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Yonetim_UlkeListe = result?.data.get_Yonetim_UlkeListe;

    formVars.yonetimilcekart_77019_options = stateVars.Get_Yonetim_UlkeListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimIlceKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimIlceKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.IlceId ?? this.props.screenInputs.ilceid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.IlceId ?? this.props.screenInputs.ilceid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "YonetimIlceKart/YonetimIlceKartPageInit1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Get_Yonetim_IlceById = result?.data.get_Yonetim_IlceById;
      formVars.yonetimilcekart_77019_value =
        stateVars.Get_Yonetim_IlceById?.length > 0 ? stateVars.Get_Yonetim_IlceById[0]?.ulkeId : null;
      formVars.yonetimilcekart_65269_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Yonetim_IlceById?.length > 0 ? stateVars.Get_Yonetim_IlceById[0]?.ilceAdi : null
      );
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimIlceKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimIlceKartPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimIlceKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.IlceId ?? this.props.screenInputs.ilceid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimilcekart_77019_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_IlceById?.length > 0 ? this.state.Get_Yonetim_IlceById[0]?.ulkeId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimIlceKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimIlceKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimIlceKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_77019 = this.state.Get_Yonetim_UlkeListe;
    stateVars.dataSource_77019 = this.state.Get_Yonetim_UlkeListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimIlceKartPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YonetimIlceKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.IlceId ?? this.props.screenInputs.ilceid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimilcekart_65269_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_IlceById?.length > 0 ? this.state.Get_Yonetim_IlceById[0]?.ilceAdi : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YonetimIlceKartComponent_77019_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UlkeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yonetimilcekart_77019_value", "value", "Get_Yonetim_UlkeListe", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YonetimIlceKart/YonetimIlceKartComponent_77019_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Yonetim_SehirListe_ByUlkeId = result?.data.get_Yonetim_SehirListe_ByUlkeId;

    formVars.yonetimilcekart_296045_options = stateVars.Get_Yonetim_SehirListe_ByUlkeId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimIlceKartComponent_77019_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimIlceKartComponent_77019_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_296045 = this.state.Get_Yonetim_SehirListe_ByUlkeId;
    stateVars.dataSource_296045 = this.state.Get_Yonetim_SehirListe_ByUlkeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YonetimIlceKartComponent_974814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "43fc490a_712c_ab21_16b0_3731dcc99abb_confirmation",
        this.defaultML,
        "Kayıt edilmeyen veriler iptal edilecek"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  YonetimIlceKartComponent_865072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IlceAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yonetimilcekart_65269_value", "value", "", "", "")
        ),
        "string"
      ),
      IlKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yonetimilcekart_296045_value",
            "value",
            "Get_Yonetim_SehirListe_ByUlkeId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YonetimIlceKart/YonetimIlceKartComponent_865072_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Add_Yonetim_Ilce = result?.data.add_Yonetim_Ilce;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimIlceKartComponent_865072_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimIlceKartComponent_865072_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
