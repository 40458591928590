import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHomePage_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHomePage_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Date: any[];
  Get_Date_dummy: any[];
  ReadFromSessionStorage: string;
  ReadFromSessionStorage_dummy: string;
  Get_Home_Summary_FTL_Bekleyen: any[];
  Get_Home_Summary_FTL_Bekleyen_dummy: any[];
  Get_Home_Summary_LTL_Bekleyen: any[];
  Get_Home_Summary_LTL_Bekleyen_dummy: any[];
  Get_Home_Summary_FTL_Tamamlanan: any[];
  Get_Home_Summary_FTL_Tamamlanan_dummy: any[];
  Get_Home_Summary_LTL_Tamamlanan: any[];
  Get_Home_Summary_LTL_Tamamlanan_dummy: any[];
}

export class HomePage_ScreenBase extends React.PureComponent<IHomePage_ScreenProps, any> {
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "e50155bf-c0f4-4caa-aa98-042cbb3d9b2f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 740733, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 692118, PropertyName: "value", Value: "Merhaba," },
        { Id: 900581, PropertyName: "value", Value: "4 Temmuz," },
        { Id: 291688, PropertyName: "value", Value: "Perşembe" },
        { Id: 209655, PropertyName: "value", Value: "Full Truck Sevkiyat" },
        { Id: 67523, PropertyName: "value", Value: "Tamamlanan FTL Sevkiyatlar" },
        { Id: 550791, PropertyName: "value", Value: "Yıl" },
        { Id: 982081, PropertyName: "placeholder", Value: "Select Year..." },
        { Id: 245914, PropertyName: "value", Value: "Parsiyel Sevkiyat" },
        { Id: 715265, PropertyName: "value", Value: "Tamamlanan LTL Sevkiyatlar" },
        { Id: 969831, PropertyName: "value", Value: "Yıl" },
        { Id: 271474, PropertyName: "placeholder", Value: "Select Year..." }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Date: [],
      ReadFromSessionStorage: "",
      Get_Home_Summary_FTL_Bekleyen: [],
      Get_Home_Summary_LTL_Bekleyen: [],
      Get_Home_Summary_FTL_Tamamlanan: [],
      Get_Home_Summary_LTL_Tamamlanan: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("homepage", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HomePagePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("homepage", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("homepage", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HomePagePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      homepage_982081_value: this.state.Get_Date?.at?.(0)?.yil ?? undefined,
      homepage_271474_value: this.state.Get_Date?.at?.(0)?.yil ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HomePagePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePagePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Date = result?.data.get_Date;
    formVars.homepage_982081_value = ReactSystemFunctions.value(
      this,
      stateVars.Get_Date?.length > 0 ? stateVars.Get_Date[0]?.yil : null
    );
    formVars.homepage_271474_value = ReactSystemFunctions.value(
      this,
      stateVars.Get_Date?.length > 0 ? stateVars.Get_Date[0]?.yil : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePagePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("FirmaNo");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePagePageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  HomePagePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      FirmaNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      TeslimYili_2: ReactSystemFunctions.convertToTypeByName(2024, "number"),
      FirmaNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      FirmaNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      TeslimatYili_3: ReactSystemFunctions.convertToTypeByName(2024, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePagePageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Home_Summary_FTL_Bekleyen = result?.data.get_Home_Summary_FTL_Bekleyen;
    formVars.homepage_877346_value = ReactSystemFunctions.toString(
      this,
      stateVars.Get_Home_Summary_FTL_Bekleyen?.length > 0 ? stateVars.Get_Home_Summary_FTL_Bekleyen[0]?.bekleyen : null
    );
    stateVars.Get_Home_Summary_LTL_Bekleyen = result?.data.get_Home_Summary_LTL_Bekleyen;
    formVars.homepage_739463_value = ReactSystemFunctions.toString(
      this,
      stateVars.Get_Home_Summary_LTL_Bekleyen?.length > 0 ? stateVars.Get_Home_Summary_LTL_Bekleyen[0]?.bekleyen : null
    );
    stateVars.Get_Home_Summary_FTL_Tamamlanan = result?.data.get_Home_Summary_FTL_Tamamlanan;

    stateVars.Get_Home_Summary_LTL_Tamamlanan = result?.data.get_Home_Summary_LTL_Tamamlanan;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HomePagePageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagePageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.homepage_877346_value = ReactSystemFunctions.toString(
      this,
      this.state.Get_Home_Summary_FTL_Bekleyen?.length > 0
        ? this.state.Get_Home_Summary_FTL_Bekleyen[0]?.bekleyen
        : null
    );

    formVars.homepage_982081_value = ReactSystemFunctions.value(
      this,
      this.state.Get_Date?.length > 0 ? this.state.Get_Date[0]?.yil : null
    );

    stateVars.dataSource_245416 = this.state.Get_Home_Summary_FTL_Tamamlanan;
    formVars.homepage_739463_value = ReactSystemFunctions.toString(
      this,
      this.state.Get_Home_Summary_LTL_Bekleyen?.length > 0
        ? this.state.Get_Home_Summary_LTL_Bekleyen[0]?.bekleyen
        : null
    );

    formVars.homepage_271474_value = ReactSystemFunctions.value(
      this,
      this.state.Get_Date?.length > 0 ? this.state.Get_Date[0]?.yil : null
    );

    stateVars.dataSource_105487 = this.state.Get_Home_Summary_LTL_Tamamlanan;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HomePageComponent_542684_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePage",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "179034",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [179034] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HomePagePageInit();
    }
  }
}
