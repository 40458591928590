import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITanimMusteriTedarikciTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITanimMusteriTedarikciTanim_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Tanim_MusteriTanimEkran: any[];
  Get_Tanim_MusteriTanimEkran_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  Delete_Tanim_MusteriTedarikci: number;
  Delete_Tanim_MusteriTedarikci_dummy: number;
  Get_Tanim_MusteriTedarikciListe: any[];
  Get_Tanim_MusteriTedarikciListe_dummy: any[];
  isComp853816Visible: "visible" | "hidden";
  isComp796072Visible: "visible" | "hidden";
}

export class TanimMusteriTedarikciTanim_ScreenBase extends React.PureComponent<
  ITanimMusteriTedarikciTanim_ScreenProps,
  any
> {
  tanimmusteritedarikcitanim_371125_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c51d833f-2605-4cea-acd3-9642f47aab0a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 740733, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 989668, PropertyName: "value", Value: "Müşteri - Tedarikçi Tanımları" },
        { Id: 537809, PropertyName: "label", Value: "Kayıt Ekle" },
        { Id: 466573, PropertyName: "value", Value: "Müşteri - Tedarikçi Tipi" },
        { Id: 200011, PropertyName: "value", Value: "Müşteri  Tipi" },
        { Id: 371125, PropertyName: "nodatafoundmessage", Value: "Kayıt bulunamadı" },
        { Id: 787271, PropertyName: "title", Value: "İşlemler" },
        { Id: 381194, PropertyName: "title", Value: "Kart Tipi" },
        { Id: 175954, PropertyName: "value", Value: "[datafield:tipidisplay]" },
        { Id: 226164, PropertyName: "title", Value: "FTL" },
        { Id: 192145, PropertyName: "title", Value: "LTL" },
        { Id: 61816, PropertyName: "title", Value: "Firma Adı" },
        { Id: 338286, PropertyName: "value", Value: "[datafield:firmaadi]" },
        { Id: 707461, PropertyName: "title", Value: "İlgili Kişi" },
        { Id: 565911, PropertyName: "value", Value: "[datafield:ilgilikisiadi]" },
        { Id: 144639, PropertyName: "title", Value: "Telefon" },
        { Id: 976713, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 689575, PropertyName: "title", Value: "E-Posta" },
        { Id: 725701, PropertyName: "value", Value: "[datafield:eposta]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tanimmusteritedarikcitanim_371125_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_LoginUserInfo: [],
      Get_Tanim_MusteriTanimEkran: [],
      SetValueOf: "",
      ChangeVisibilityOf: "",
      Delete_Tanim_MusteriTedarikci: 0,
      Get_Tanim_MusteriTedarikciListe: [],
      isComp853816Visible: "hidden",
      isComp796072Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tanimmusteritedarikcitanim", "");
      return;
    }

    if (true) {
      await this.TanimMusteriTedarikciTanimPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tanimmusteritedarikcitanim", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tanimmusteritedarikcitanim", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TanimMusteriTedarikciTanimPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TanimMusteriTedarikciTanimPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      KartTipi_1: ReactSystemFunctions.convertToTypeByName("ALL", "string"),
      MusTipi_1: ReactSystemFunctions.convertToTypeByName("ALL", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriTedarikciTanim/TanimMusteriTedarikciTanimPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Tanim_MusteriTanimEkran = result?.data.get_Tanim_MusteriTanimEkran;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriTedarikciTanimPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciTanimPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tanimmusteritedarikcitanim_304074_value",
      "ALL",
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tanimmusteritedarikcitanim_60483_value",
      "ALL",
      null
    );
    stateVars.isComp853816Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_304074_value", "value", "", "", "")
        ),
        "SUP"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_371125 = this.state.Get_Tanim_MusteriTanimEkran;
    stateVars.isComp796072Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_371125_value", "tipi"),
        "M"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TanimMusteriTedarikciTanimComponent_542684_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TanimMusteriTedarikciTanim",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "179034",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriTedarikciTanimComponent_537809_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TanimMusteriTedarikciBilgiKart",
      "MusteriTedarikciId",
      "00000000-0000-0000-0000-000000000000"
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TanimMusteriTedarikciTanim",
      "TanimMusteriTedarikciBilgiKart",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "577129",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriTedarikciTanimComponent_304074_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_304074_value", "value", "", "", "")
        ),
        "SUP"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp853816Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciTanimComponent_304074_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciTanimComponent_304074_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciTanimComponent_304074_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_304074_value", "value", "", "", "")
        ),
        "SUP"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp853816Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciTanimComponent_304074_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciTanimComponent_304074_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciTanimComponent_304074_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      KartTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_304074_value", "value", "", "", "")
        ),
        "string"
      ),
      MusTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_60483_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriTedarikciTanim/TanimMusteriTedarikciTanimComponent_304074_onChange2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Tanim_MusteriTanimEkran = result?.data.get_Tanim_MusteriTanimEkran;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriTedarikciTanimComponent_304074_onChange3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciTanimComponent_304074_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_371125 = this.state.Get_Tanim_MusteriTanimEkran;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriTedarikciTanimComponent_60483_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      KartTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_304074_value", "value", "", "", "")
        ),
        "string"
      ),
      MusTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_60483_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriTedarikciTanim/TanimMusteriTedarikciTanimComponent_60483_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Tanim_MusteriTanimEkran = result?.data.get_Tanim_MusteriTanimEkran;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriTedarikciTanimComponent_60483_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciTanimComponent_60483_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_371125 = this.state.Get_Tanim_MusteriTanimEkran;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriTedarikciTanimComponent_433037_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TanimMusteriTedarikciBilgiKart",
      "MusteriTedarikciId",
      ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_371125_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TanimMusteriTedarikciTanim",
      "TanimMusteriTedarikciBilgiKart",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "924286",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriTedarikciTanimComponent_988522_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_371125_value", "id"),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b9cac305_a27d_0bff_c444_9c977e50778a_confirmation",
        this.defaultML,
        "Seçilen kayıt silinecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimMusteriTedarikciTanim/TanimMusteriTedarikciTanimComponent_988522_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Delete_Tanim_MusteriTedarikci = result?.data.delete_Tanim_MusteriTedarikci;
      stateVars.Get_Tanim_MusteriTedarikciListe = result?.data.get_Tanim_MusteriTedarikciListe;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TanimMusteriTedarikciTanimComponent_796072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TanimMusteriNoktaTanim",
      "MusteriId",
      ReactSystemFunctions.value(this, "tanimmusteritedarikcitanim_371125_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TanimMusteriTedarikciTanim",
      "TanimMusteriNoktaTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [179034, 577129, 924286] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TanimMusteriTedarikciTanimPageInit();
    }
  }
}
