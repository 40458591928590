import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISiparisListesiFullTruck_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISiparisListesiFullTruck_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Siparis_Get_FullTruck_Liste: any[];
  Siparis_Get_FullTruck_Liste_dummy: any[];
  Siparis_Delete_FullTruck: number;
  Siparis_Delete_FullTruck_dummy: number;
  isCompsiparislistesifulltruck_623195AuthorizationVisible: "visible" | "hidden";
}

export class SiparisListesiFullTruck_ScreenBase extends React.PureComponent<ISiparisListesiFullTruck_ScreenProps, any> {
  siparislistesifulltruck_193255_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c3726c09-9336-4cf7-840e-3a3b07e0fc42",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "SİPARİŞ LİSTESİ (FULL TRUCK)" },
        { Id: 550332, PropertyName: "label", Value: "Yeni Sipariş Oluştur" },
        { Id: 27694, PropertyName: "value", Value: "Teslim Edilenleri Göster" },
        { Id: 193255, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 342782, PropertyName: "title", Value: "İşlemler" },
        { Id: 653167, PropertyName: "title", Value: "Tarih" },
        { Id: 259672, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 263151, PropertyName: "title", Value: "Sipariş Numarası" },
        { Id: 287292, PropertyName: "value", Value: "[datafield:siparisno]" },
        { Id: 643111, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 957391, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 631987, PropertyName: "value", Value: "[datafield:firmaadi]" },
        { Id: 733547, PropertyName: "title", Value: "Çıkış Yeri" },
        { Id: 113319, PropertyName: "value", Value: "[datafield:yuklemeyeri]" },
        { Id: 18272, PropertyName: "title", Value: "Varış Yeri" },
        { Id: 53533, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 23613, PropertyName: "title", Value: "Yükleme Yapıldı" },
        { Id: 793144, PropertyName: "title", Value: "Teslimat Yapıldı" },
        { Id: 636581, PropertyName: "title", Value: "Plaka" },
        { Id: 672121, PropertyName: "value", Value: "[datafield:plaka]" },
        { Id: 180840, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9648, PropertyName: "title", Value: "Surucu" },
        { Id: 292630, PropertyName: "value", Value: "[datafield:surucuadi]" },
        { Id: 18915, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.siparislistesifulltruck_193255_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Siparis_Get_FullTruck_Liste: [],
      Siparis_Delete_FullTruck: 0,
      isCompsiparislistesifulltruck_623195AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siparislistesifulltruck", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SiparisListesiFullTruckPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsiparislistesifulltruck_623195AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("siparislistesifulltruck", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("siparislistesifulltruck", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SiparisListesiFullTruckPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      siparislistesifulltruck_28495_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SiparisListesiFullTruckPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      TeslimGoster_1: ReactSystemFunctions.convertToTypeByName(false, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisListesiFullTruck/SiparisListesiFullTruckPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Siparis_Get_FullTruck_Liste = result?.data.siparis_Get_FullTruck_Liste;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListesiFullTruckPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListesiFullTruckPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.Siparis_Get_FullTruck_Liste;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SiparisListesiFullTruckComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisListesiFullTruck",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListesiFullTruckComponent_550332_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SiparisDetayFullTruck", "SiparisId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisListesiFullTruck",
      "SiparisDetayFullTruck",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "446469",
      null,
      null,
      "true",
      "1000px",
      "75vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListesiFullTruckComponent_28495_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeslimGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislistesifulltruck_28495_value", "value", "", "", "")
        ),
        "boolean"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisListesiFullTruck/SiparisListesiFullTruckComponent_28495_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Get_FullTruck_Liste = result?.data.siparis_Get_FullTruck_Liste;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListesiFullTruckComponent_28495_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListesiFullTruckComponent_28495_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.Siparis_Get_FullTruck_Liste;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListesiFullTruckComponent_980554_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SiparisDetayFullTruck",
      "SiparisId",
      ReactSystemFunctions.value(this, "siparislistesifulltruck_193255_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisListesiFullTruck",
      "SiparisDetayFullTruck",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "584167",
      null,
      null,
      "true",
      "1000px",
      "75vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListesiFullTruckComponent_623195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siparislistesifulltruck_193255_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisListesiFullTruck/SiparisListesiFullTruckComponent_623195_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Delete_FullTruck = result?.data.siparis_Delete_FullTruck;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182, 446469, 584167] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SiparisListesiFullTruckPageInit();
    }
  }
}
