import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITanimMusteriNoktaBilgiKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITanimMusteriNoktaBilgiKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_UlkeListe: any[];
  Get_Yonetim_UlkeListe_dummy: any[];
  Get_Yonetim_FirmaListe: any[];
  Get_Yonetim_FirmaListe_dummy: any[];
  Get_Tanim_MusteriTedarikciListe: any[];
  Get_Tanim_MusteriTedarikciListe_dummy: any[];
  Get_Tanim_MusteriNoktaById: any[];
  Get_Tanim_MusteriNoktaById_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  Get_Yonetim_SehirListe_ByUlkeId: any[];
  Get_Yonetim_SehirListe_ByUlkeId_dummy: any[];
  Get_Yonetim_IlceListe_BySehirId: any[];
  Get_Yonetim_IlceListe_BySehirId_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  Add_Tanim_MusteriNokta: number;
  Add_Tanim_MusteriNokta_dummy: number;
  Update_Tanim_MusteriNokta: number;
  Update_Tanim_MusteriNokta_dummy: number;
  isComp128337Enabled: "enabled" | "disabled";
}

export class TanimMusteriNoktaBilgiKart_ScreenBase extends React.PureComponent<
  ITanimMusteriNoktaBilgiKart_ScreenProps,
  any
> {
  tanimmusterinoktabilgikart_810791_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimmusterinoktabilgikart_128337_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimmusterinoktabilgikart_418064_value_kuikaAutoCompleteRef: React.RefObject<any>;
  tanimmusterinoktabilgikart_157471_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimmusterinoktabilgikart_236737_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "48dc9c4e-8053-43bd-adbb-d220bb266b41",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 891214, PropertyName: "value", Value: "MÜŞTERİ NOKTA BİLGİ KARTI" },
        { Id: 249608, PropertyName: "value", Value: "Firma Kodu" },
        { Id: 810791, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 125168, PropertyName: "value", Value: "Müşteri" },
        { Id: 128337, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 767051, PropertyName: "value", Value: "Nokta Adı" },
        { Id: 607530, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 172445, PropertyName: "value", Value: "Nokta Kodu" },
        { Id: 742449, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 994318, PropertyName: "value", Value: "İlgili Kişi" },
        { Id: 293322, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 684043, PropertyName: "value", Value: "Telefon" },
        { Id: 249909, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 296617, PropertyName: "value", Value: "Ülke" },
        { Id: 418064, PropertyName: "placeholder", Value: "Search..." },
        { Id: 388090, PropertyName: "value", Value: "Şehir" },
        { Id: 157471, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 514819, PropertyName: "value", Value: "İlçe" },
        { Id: 236737, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 993158, PropertyName: "value", Value: "Açık Adres" },
        { Id: 199541, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 974814, PropertyName: "label", Value: "Vazgeç" },
        { Id: 865072, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tanimmusterinoktabilgikart_810791_value_kuikaSelectBoxRef = React.createRef();
    this.tanimmusterinoktabilgikart_128337_value_kuikaSelectBoxRef = React.createRef();
    this.tanimmusterinoktabilgikart_418064_value_kuikaAutoCompleteRef = React.createRef();
    this.tanimmusterinoktabilgikart_157471_value_kuikaSelectBoxRef = React.createRef();
    this.tanimmusterinoktabilgikart_236737_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_UlkeListe: [],
      Get_Yonetim_FirmaListe: [],
      Get_Tanim_MusteriTedarikciListe: [],
      Get_Tanim_MusteriNoktaById: [],
      ChangeEnabledOf: "",
      Get_Yonetim_SehirListe_ByUlkeId: [],
      Get_Yonetim_IlceListe_BySehirId: [],
      GoBack: "",
      Add_Tanim_MusteriNokta: 0,
      Update_Tanim_MusteriNokta: 0,
      isComp128337Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tanimmusterinoktabilgikart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TanimMusteriNoktaBilgiKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tanimmusterinoktabilgikart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tanimmusterinoktabilgikart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TanimMusteriNoktaBilgiKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tanimmusterinoktabilgikart_810791_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.firmaId ?? undefined,
      tanimmusterinoktabilgikart_128337_value: this.props.screenInputs.MusteriId ?? this.props.screenInputs.musteriid,
      tanimmusterinoktabilgikart_607530_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.noktaAdi ?? undefined,
      tanimmusterinoktabilgikart_742449_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.noktaKodu ?? undefined,
      tanimmusterinoktabilgikart_293322_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.ilgiliKisi ?? undefined,
      tanimmusterinoktabilgikart_249909_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.telefon ?? undefined,
      tanimmusterinoktabilgikart_418064_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.ulkeId ?? undefined,
      tanimmusterinoktabilgikart_157471_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.sehirId ?? undefined,
      tanimmusterinoktabilgikart_236737_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.ilceId ?? undefined,
      tanimmusterinoktabilgikart_199541_value: this.state.Get_Tanim_MusteriNoktaById?.at?.(0)?.acikAdres ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TanimMusteriNoktaBilgiKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriNoktaBilgiKart/TanimMusteriNoktaBilgiKartPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Yonetim_UlkeListe = result?.data.get_Yonetim_UlkeListe;

    formVars.tanimmusterinoktabilgikart_418064_options = stateVars.Get_Yonetim_UlkeListe;
    stateVars.Get_Yonetim_FirmaListe = result?.data.get_Yonetim_FirmaListe;

    formVars.tanimmusterinoktabilgikart_810791_options = stateVars.Get_Yonetim_FirmaListe;
    stateVars.Get_Tanim_MusteriTedarikciListe = result?.data.get_Tanim_MusteriTedarikciListe;

    formVars.tanimmusterinoktabilgikart_128337_options = stateVars.Get_Tanim_MusteriTedarikciListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      NoktaId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimMusteriNoktaBilgiKart/TanimMusteriNoktaBilgiKartPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Get_Tanim_MusteriNoktaById = result?.data.get_Tanim_MusteriNoktaById;
      formVars.tanimmusterinoktabilgikart_810791_value =
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.firmaId : null;
      formVars.tanimmusterinoktabilgikart_607530_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.noktaAdi : null
      );
      formVars.tanimmusterinoktabilgikart_742449_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.noktaKodu : null
      );
      formVars.tanimmusterinoktabilgikart_293322_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.ilgiliKisi : null
      );
      formVars.tanimmusterinoktabilgikart_249909_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.telefon : null
      );
      formVars.tanimmusterinoktabilgikart_418064_value =
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.ulkeId : null;
      formVars.tanimmusterinoktabilgikart_157471_value =
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.sehirId : null;
      formVars.tanimmusterinoktabilgikart_236737_value =
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.ilceId : null;
      formVars.tanimmusterinoktabilgikart_199541_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriNoktaById?.length > 0 ? stateVars.Get_Tanim_MusteriNoktaById[0]?.acikAdres : null
      );
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp128337Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_810791_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.firmaId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;
    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;

    stateVars.dataSource_128337 = this.state.Get_Tanim_MusteriTedarikciListe;
    stateVars.dataSource_128337 = this.state.Get_Tanim_MusteriTedarikciListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_607530_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.noktaAdi : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_742449_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.noktaKodu : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_293322_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.ilgiliKisi : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_249909_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.telefon : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit10_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_418064_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.ulkeId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_418064 = this.state.Get_Yonetim_UlkeListe;
    stateVars.dataSource_418064 = this.state.Get_Yonetim_UlkeListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit12_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_157471_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.sehirId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_236737_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.ilceId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusterinoktabilgikart_199541_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriNoktaById?.length > 0 ? this.state.Get_Tanim_MusteriNoktaById[0]?.acikAdres : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TanimMusteriNoktaBilgiKartComponent_418064_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UlkeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusterinoktabilgikart_418064_value",
            "value",
            "Get_Yonetim_UlkeListe",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriNoktaBilgiKart/TanimMusteriNoktaBilgiKartComponent_418064_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Yonetim_SehirListe_ByUlkeId = result?.data.get_Yonetim_SehirListe_ByUlkeId;

    formVars.tanimmusterinoktabilgikart_157471_options = stateVars.Get_Yonetim_SehirListe_ByUlkeId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriNoktaBilgiKartComponent_418064_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartComponent_418064_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_157471 = this.state.Get_Yonetim_SehirListe_ByUlkeId;
    stateVars.dataSource_157471 = this.state.Get_Yonetim_SehirListe_ByUlkeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriNoktaBilgiKartComponent_157471_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusterinoktabilgikart_157471_value",
            "value",
            "Get_Yonetim_SehirListe_ByUlkeId",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriNoktaBilgiKart/TanimMusteriNoktaBilgiKartComponent_157471_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Yonetim_IlceListe_BySehirId = result?.data.get_Yonetim_IlceListe_BySehirId;

    formVars.tanimmusterinoktabilgikart_236737_options = stateVars.Get_Yonetim_IlceListe_BySehirId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriNoktaBilgiKartComponent_157471_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartComponent_157471_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_236737 = this.state.Get_Yonetim_IlceListe_BySehirId;
    stateVars.dataSource_236737 = this.state.Get_Yonetim_IlceListe_BySehirId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriNoktaBilgiKartComponent_974814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "43fc490a_712c_ab21_16b0_3731dcc99abb_confirmation",
        this.defaultML,
        "Kayıt edilmeyen veriler iptal edilecek"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TanimMusteriNoktaBilgiKartComponent_865072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AcikAdres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_199541_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusterinoktabilgikart_810791_value",
            "value",
            "Get_Yonetim_FirmaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IlceId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusterinoktabilgikart_236737_value",
            "value",
            "Get_Yonetim_IlceListe_BySehirId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_293322_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      MusteriId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MusteriId ?? this.props.screenInputs.musteriid,
        "Guid"
      ),
      NoktaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_607530_value", "value", "", "", "")
        ),
        "string"
      ),
      NoktaKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_742449_value", "value", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_249909_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimMusteriNoktaBilgiKart/TanimMusteriNoktaBilgiKartComponent_865072_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Add_Tanim_MusteriNokta = result?.data.add_Tanim_MusteriNokta;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartComponent_865072_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartComponent_865072_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartComponent_865072_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AcikAdres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_199541_value", "value", "", "", "")
        ),
        "string"
      ),
      IlceId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusterinoktabilgikart_236737_value",
            "value",
            "Get_Yonetim_IlceListe_BySehirId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_293322_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      NoktaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_607530_value", "value", "", "", "")
        ),
        "string"
      ),
      NoktaKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_742449_value", "value", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusterinoktabilgikart_249909_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NoktaId ?? this.props.screenInputs.noktaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimMusteriNoktaBilgiKart/TanimMusteriNoktaBilgiKartComponent_865072_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Update_Tanim_MusteriNokta = result?.data.update_Tanim_MusteriNokta;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartComponent_865072_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriNoktaBilgiKartComponent_865072_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriNoktaBilgiKartComponent_865072_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
