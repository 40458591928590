import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParsiyelMusteridenMalKabul_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParsiyelMusteridenMalKabul_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Yonetim_DepoListe: any[];
  Get_Yonetim_DepoListe_dummy: any[];
  Get_Tanim_AracListe: any[];
  Get_Tanim_AracListe_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  MusteriMalKabul_BarkodOkut: any[];
  MusteriMalKabul_BarkodOkut_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  SetFocusTo: any;
  SetFocusTo_dummy: any;
}

export class ParsiyelMusteridenMalKabul_ScreenBase extends React.PureComponent<
  IParsiyelMusteridenMalKabul_ScreenProps,
  any
> {
  parsiyelmusteridenmalkabul_213603_value_kuikaSelectBoxRef: React.RefObject<any>;
  parsiyelmusteridenmalkabul_658022_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "e22bc258-95a6-4e12-8715-5826d9a68a24",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "PARSİYEL MAL KABUL" },
        { Id: 275970, PropertyName: "value", Value: "Giriş Deposu" },
        { Id: 213603, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 258869, PropertyName: "value", Value: "Araç Plakası" },
        { Id: 658022, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 462904, PropertyName: "value", Value: "Palet Barkodu" },
        { Id: 555597, PropertyName: "placeholder", Value: "Palet barkodunu okutun ..." },
        { Id: 410855, PropertyName: "label", Value: "Vazgeç" },
        { Id: 185855, PropertyName: "label", Value: "Ekle" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.parsiyelmusteridenmalkabul_213603_value_kuikaSelectBoxRef = React.createRef();
    this.parsiyelmusteridenmalkabul_658022_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Yonetim_DepoListe: [],
      Get_Tanim_AracListe: [],
      SetValueOf: "",
      MusteriMalKabul_BarkodOkut: [],
      Notify: false,
      SetFocusTo: ""
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusteridenmalkabul", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ParsiyelMusteridenMalKabulPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusteridenmalkabul", "");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["SistemAdmin", "SuperUser", "Operasyon", "OperasyonLTL", "DepoLTL"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusteridenmalkabul", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParsiyelMusteridenMalKabulPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ParsiyelMusteridenMalKabulPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteridenMalKabul/ParsiyelMusteridenMalKabulPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Yonetim_DepoListe = result?.data.get_Yonetim_DepoListe;

    formVars.parsiyelmusteridenmalkabul_213603_options = stateVars.Get_Yonetim_DepoListe;
    stateVars.Get_Tanim_AracListe = result?.data.get_Tanim_AracListe;

    formVars.parsiyelmusteridenmalkabul_658022_options = stateVars.Get_Tanim_AracListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteridenMalKabulPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteridenMalKabulPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_213603 = this.state.Get_Yonetim_DepoListe;
    stateVars.dataSource_213603 = this.state.Get_Yonetim_DepoListe;

    stateVars.dataSource_658022 = this.state.Get_Tanim_AracListe;
    stateVars.dataSource_658022 = this.state.Get_Tanim_AracListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParsiyelMusteridenMalKabulComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelMusteridenMalKabul",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteridenMalKabulComponent_410855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "parsiyelmusteridenmalkabul_555597_value",
      "",
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteridenMalKabulComponent_185855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PaletBarkod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusteridenmalkabul_555597_value", "value", "", "", "")
        ),
        "string"
      ),
      DepoId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "parsiyelmusteridenmalkabul_213603_value",
            "value",
            "Get_Yonetim_DepoListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "parsiyelmusteridenmalkabul_658022_value",
            "value",
            "Get_Tanim_AracListe",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteridenMalKabul/ParsiyelMusteridenMalKabulComponent_185855_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MusteriMalKabul_BarkodOkut = result?.data.musteriMalKabul_BarkodOkut;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteridenMalKabulComponent_185855_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteridenMalKabulComponent_185855_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MusteriMalKabul_BarkodOkut?.length > 0 ? this.state.MusteriMalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "8eea922c_99c8_3d7f_e9ee_699c4fcdeed4_notify",
          this.defaultML,
          "Başarılı"
        ),
        "success",
        "bottom-right",
        0,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteridenMalKabulComponent_185855_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteridenMalKabulComponent_185855_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelMusteridenMalKabulComponent_185855_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MusteriMalKabul_BarkodOkut?.length > 0 ? this.state.MusteriMalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(
          this,
          this.state.MusteriMalKabul_BarkodOkut?.length > 0 ? this.state.MusteriMalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "danger",
        "bottom-right",
        0,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteridenMalKabulComponent_185855_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteridenMalKabulComponent_185855_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelMusteridenMalKabulComponent_185855_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MusteriMalKabul_BarkodOkut?.length > 0 ? this.state.MusteriMalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "parsiyelmusteridenmalkabul_555597_value",
        "",
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteridenMalKabulComponent_185855_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteridenMalKabulComponent_185855_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelMusteridenMalKabulComponent_185855_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("555597");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ParsiyelMusteridenMalKabulPageInit();
    }
  }
}
