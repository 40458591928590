import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRaporFullTruckListe_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRaporFullTruckListe_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Raporlar_FullTruck: any[];
  Get_Raporlar_FullTruck_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
}

export class RaporFullTruckListe_ScreenBase extends React.PureComponent<IRaporFullTruckListe_ScreenProps, any> {
  raporfulltruckliste_193255_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "4e2411c3-9a8b-482a-99b6-77d5b8891d2c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "FULL TRUCK SİPARİŞ RAPORU" },
        { Id: 51993, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 867271, PropertyName: "label", Value: "Ara" },
        { Id: 981071, PropertyName: "label", Value: "Excele Aktar" },
        { Id: 193255, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 342782, PropertyName: "title", Value: "İşlemler" },
        { Id: 653167, PropertyName: "title", Value: "Tarih" },
        { Id: 259672, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 263151, PropertyName: "title", Value: "Sipariş Numarası" },
        { Id: 287292, PropertyName: "value", Value: "[datafield:siparisno]" },
        { Id: 643111, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 957391, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 631987, PropertyName: "value", Value: "[datafield:musteriadi]" },
        { Id: 733547, PropertyName: "title", Value: "Çıkış Yeri" },
        { Id: 113319, PropertyName: "value", Value: "[datafield:yuklemeyeri]" },
        { Id: 18272, PropertyName: "title", Value: "Varış Yeri" },
        { Id: 53533, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 74679, PropertyName: "title", Value: "Plaka" },
        { Id: 532163, PropertyName: "value", Value: "[datafield:plaka]" },
        { Id: 316521, PropertyName: "title", Value: "Sürücü" },
        { Id: 358138, PropertyName: "value", Value: "[datafield:surucuadi]" },
        { Id: 23613, PropertyName: "title", Value: "Yükleme Yapıldı" },
        { Id: 602887, PropertyName: "title", Value: "Yükleme Tarihi" },
        { Id: 804932, PropertyName: "value", Value: "[datafield:yuklemetarihi]" },
        { Id: 793144, PropertyName: "title", Value: "Teslimat Yapıldı" },
        { Id: 851513, PropertyName: "title", Value: "Teslim Tarihi" },
        { Id: 754497, PropertyName: "value", Value: "[datafield:teslimattarihi]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.raporfulltruckliste_193255_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Raporlar_FullTruck: [],
      ExportExcel: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("raporfulltruckliste", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RaporFullTruckListePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("raporfulltruckliste", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("raporfulltruckliste", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RaporFullTruckListePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  RaporFullTruckListePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchText_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "RaporFullTruckListe/RaporFullTruckListePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Raporlar_FullTruck = result?.data.get_Raporlar_FullTruck;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporFullTruckListePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporFullTruckListePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.Get_Raporlar_FullTruck;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RaporFullTruckListeComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "RaporFullTruckListe",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporFullTruckListeComponent_867271_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SearchText_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "raporfulltruckliste_51993_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporFullTruckListe/RaporFullTruckListeComponent_867271_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Raporlar_FullTruck = result?.data.get_Raporlar_FullTruck;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporFullTruckListeComponent_867271_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporFullTruckListeComponent_867271_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.Get_Raporlar_FullTruck;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporFullTruckListeComponent_981071_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.Get_Raporlar_FullTruck),
        "any"
      ),
      header_0: ReactSystemFunctions.convertToTypeByName("FULL TRUCK YÜKLEME RAPORU", "string"),
      footer_0: ReactSystemFunctions.convertToTypeByName("ARUNA LOJİSTİK YÖNETİM PORTAL", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporFullTruckListe/RaporFullTruckListeComponent_981071_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  RaporFullTruckListeComponent_980554_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SiparisDetayFullTruck",
      "SiparisId",
      ReactSystemFunctions.value(this, "raporfulltruckliste_193255_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "RaporFullTruckListe",
      "SiparisDetayFullTruck",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "584167",
      null,
      null,
      "true",
      "1000px",
      "75vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182, 584167] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RaporFullTruckListePageInit();
    }
  }
}
