import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITanimAracTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITanimAracTanim_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Tanim_AracListe: any[];
  Get_Tanim_AracListe_dummy: any[];
  Delete_Tanim_Arac: number;
  Delete_Tanim_Arac_dummy: number;
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: any;
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport_dummy: any;
  DownloadReport: any;
  DownloadReport_dummy: any;
}

export class TanimAracTanim_ScreenBase extends React.PureComponent<ITanimAracTanim_ScreenProps, any> {
  tanimaractanim_371125_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "7bf81625-5983-41e6-b9ef-9b91b9fc4c50",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 740733, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 989668, PropertyName: "value", Value: "Araç Tanımları" },
        { Id: 537809, PropertyName: "label", Value: "Araç Ekle" },
        { Id: 371125, PropertyName: "nodatafoundmessage", Value: "Kayıt bulunamadı" },
        { Id: 787271, PropertyName: "title", Value: "İşlemler" },
        { Id: 381194, PropertyName: "title", Value: "Plaka" },
        { Id: 513495, PropertyName: "value", Value: "[datafield:plaka]" },
        { Id: 61816, PropertyName: "title", Value: "Tipi" },
        { Id: 338286, PropertyName: "value", Value: "[datafield:tip]" },
        { Id: 707461, PropertyName: "title", Value: "Marka" },
        { Id: 565911, PropertyName: "value", Value: "[datafield:marka]" },
        { Id: 144639, PropertyName: "title", Value: "Model" },
        { Id: 976713, PropertyName: "value", Value: "[datafield:model]" },
        { Id: 689575, PropertyName: "title", Value: "Model Yılı" },
        { Id: 725701, PropertyName: "value", Value: "[datafield:modelyili]" },
        { Id: 740806, PropertyName: "title", Value: "Öz Mal" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tanimaractanim_371125_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_LoginUserInfo: [],
      Get_Tanim_AracListe: [],
      Delete_Tanim_Arac: 0,
      KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: "",
      DownloadReport: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tanimaractanim", "");
      return;
    }

    if (true) {
      await this.TanimAracTanimPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tanimaractanim", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tanimaractanim", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TanimAracTanimPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TanimAracTanimPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TanimAracTanim/TanimAracTanimPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Tanim_AracListe = result?.data.get_Tanim_AracListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimAracTanimPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimAracTanimPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_371125 = this.state.Get_Tanim_AracListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TanimAracTanimComponent_542684_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TanimAracTanim",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "179034",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimAracTanimComponent_537809_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TanimAracBilgiKart", "AracId", "00000000-0000-0000-0000-000000000000");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TanimAracTanim",
      "TanimAracBilgiKart",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "577129",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimAracTanimComponent_433037_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TanimAracBilgiKart",
      "AracId",
      ReactSystemFunctions.value(this, "tanimaractanim_371125_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TanimAracTanim",
      "TanimAracBilgiKart",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "924286",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimAracTanimComponent_988522_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "tanimaractanim_371125_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fabb8437_75ce_6855_19b9_ee661ef93442_confirmation",
        this.defaultML,
        "Araç tanımı silinecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimAracTanim/TanimAracTanimComponent_988522_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Delete_Tanim_Arac = result?.data.delete_Tanim_Arac;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TanimAracTanimComponent_771480_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      _ReportName_0: ReactSystemFunctions.convertToTypeByName("AracTanim", "string"),
      _SevkEmriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "tanimaractanim_371125_value", "id"),
        "string"
      ),
      _UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimAracTanim/TanimAracTanimComponent_771480_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport =
      result?.data.kuikaReportGetReportBase64_GetReportBaseFromArunakuikareport;
    stateVars.DownloadReport = result?.data.downloadReport;
    ReactSystemFunctions.downloadFile(result?.data?.downloadReport);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [179034, 577129, 924286] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TanimAracTanimPageInit();
    }
  }
}
