import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYonetimKullaniciEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYonetimKullaniciEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_FirmaListe: any[];
  Get_Yonetim_FirmaListe_dummy: any[];
  Get_Helpers_RoleList: any[];
  Get_Helpers_RoleList_dummy: any[];
  Get_Yonetim_KullaniciById: any[];
  Get_Yonetim_KullaniciById_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  CreateUserWithPassword: string;
  CreateUserWithPassword_dummy: string;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Add_Yonetim_KullaniciFirma: number;
  Add_Yonetim_KullaniciFirma_dummy: number;
  isComp742500Visible: "visible" | "hidden";
}

export class YonetimKullaniciEkle_ScreenBase extends React.PureComponent<IYonetimKullaniciEkle_ScreenProps, any> {
  yonetimkullaniciekle_810791_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "821627c7-4e82-4e3d-8497-9940b380ff4c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 891214, PropertyName: "value", Value: "KULLANICI EKLEME - DÜZENLEME" },
        { Id: 48474, PropertyName: "value", Value: "Kullanıcı Adı" },
        { Id: 198377, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 767051, PropertyName: "value", Value: "Parola" },
        { Id: 607530, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 684043, PropertyName: "value", Value: "Ad" },
        { Id: 249909, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 172445, PropertyName: "value", Value: "Soyad" },
        { Id: 742449, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 249608, PropertyName: "value", Value: "Firma" },
        { Id: 810791, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 974814, PropertyName: "label", Value: "Vazgeç" },
        { Id: 865072, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.yonetimkullaniciekle_810791_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_FirmaListe: [],
      Get_Helpers_RoleList: [],
      Get_Yonetim_KullaniciById: [],
      GoBack: "",
      CreateUserWithPassword: "",
      Get_Helpers_UserInfoByUserName: [],
      Add_Yonetim_KullaniciFirma: 0,
      isComp742500Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yonetimkullaniciekle", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YonetimKullaniciEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yonetimkullaniciekle", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yonetimkullaniciekle", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YonetimKullaniciEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      yonetimkullaniciekle_198377_value: this.state.Get_Yonetim_KullaniciById?.at?.(0)?.userName ?? undefined,
      yonetimkullaniciekle_249909_value: this.state.Get_Yonetim_KullaniciById?.at?.(0)?.firstName ?? undefined,
      yonetimkullaniciekle_742449_value: this.state.Get_Yonetim_KullaniciById?.at?.(0)?.lastName ?? undefined,
      yonetimkullaniciekle_810791_value: this.state.Get_Yonetim_KullaniciById?.at?.(0)?.firmaId ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YonetimKullaniciEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YonetimKullaniciEkle/YonetimKullaniciEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Yonetim_FirmaListe = result?.data.get_Yonetim_FirmaListe;

    formVars.yonetimkullaniciekle_810791_options = stateVars.Get_Yonetim_FirmaListe;
    stateVars.Get_Helpers_RoleList = result?.data.get_Helpers_RoleList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimKullaniciEklePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimKullaniciEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YonetimKullaniciEkle/YonetimKullaniciEklePageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Get_Yonetim_KullaniciById = result?.data.get_Yonetim_KullaniciById;
      formVars.yonetimkullaniciekle_198377_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Yonetim_KullaniciById?.length > 0 ? stateVars.Get_Yonetim_KullaniciById[0]?.userName : null
      );
      formVars.yonetimkullaniciekle_249909_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Yonetim_KullaniciById?.length > 0 ? stateVars.Get_Yonetim_KullaniciById[0]?.firstName : null
      );
      formVars.yonetimkullaniciekle_742449_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Yonetim_KullaniciById?.length > 0 ? stateVars.Get_Yonetim_KullaniciById[0]?.lastName : null
      );
      formVars.yonetimkullaniciekle_810791_value =
        stateVars.Get_Yonetim_KullaniciById?.length > 0 ? stateVars.Get_Yonetim_KullaniciById[0]?.firmaId : null;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimKullaniciEklePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimkullaniciekle_198377_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_KullaniciById?.length > 0 ? this.state.Get_Yonetim_KullaniciById[0]?.userName : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimKullaniciEklePageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp742500Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "00000000-0000-0000-0000-000000000000"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimKullaniciEklePageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YonetimKullaniciEklePageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimkullaniciekle_249909_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_KullaniciById?.length > 0 ? this.state.Get_Yonetim_KullaniciById[0]?.firstName : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimKullaniciEklePageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimkullaniciekle_742449_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_KullaniciById?.length > 0 ? this.state.Get_Yonetim_KullaniciById[0]?.lastName : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimKullaniciEklePageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimkullaniciekle_810791_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_KullaniciById?.length > 0 ? this.state.Get_Yonetim_KullaniciById[0]?.firmaId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEklePageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimKullaniciEklePageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;
    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YonetimKullaniciEkleComponent_974814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "43fc490a_712c_ab21_16b0_3731dcc99abb_confirmation",
        this.defaultML,
        "Kayıt edilmeyen veriler iptal edilecek"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  YonetimKullaniciEkleComponent_865072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.CreateUserWithPassword = await ReactSystemFunctions.createUserWithPassword(
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "yonetimkullaniciekle_198377_value", "value", "", "", "")
      ),
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "yonetimkullaniciekle_607530_value", "value", "", "", "")
      ),
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "yonetimkullaniciekle_249909_value", "value", "", "", "")
      ),
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "yonetimkullaniciekle_742449_value", "value", "", "", "")
      ),
      false,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimKullaniciEkleComponent_865072_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YonetimKullaniciEkleComponent_865072_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yonetimkullaniciekle_198377_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YonetimKullaniciEkle/YonetimKullaniciEkleComponent_865072_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimKullaniciEkleComponent_865072_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimKullaniciEkleComponent_865072_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yonetimkullaniciekle_810791_value",
            "value",
            "Get_Yonetim_FirmaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.KullaniciId ?? this.props.screenInputs.kullaniciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YonetimKullaniciEkle/YonetimKullaniciEkleComponent_865072_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Add_Yonetim_KullaniciFirma = result?.data.add_Yonetim_KullaniciFirma;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEkleComponent_865072_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimKullaniciEkleComponent_865072_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimKullaniciEkleComponent_865072_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
