import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISiparisDetayParsiyel_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISiparisDetayParsiyel_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Yonetim_FirmaListe: any[];
  Get_Yonetim_FirmaListe_dummy: any[];
  Get_Tanim_MusteriListe_LTL: any[];
  Get_Tanim_MusteriListe_LTL_dummy: any[];
  Siparis_Get_Parsiyel_ById: any[];
  Siparis_Get_Parsiyel_ById_dummy: any[];
  Siparis_Get_SevkEmirListe: any[];
  Siparis_Get_SevkEmirListe_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  Siparis_Save_Parsiyel: number;
  Siparis_Save_Parsiyel_dummy: number;
  Siparis_Delete_SevkEmriDetay: number;
  Siparis_Delete_SevkEmriDetay_dummy: number;
  Siparis_Parsiyel_BarkodOlustur: number;
  Siparis_Parsiyel_BarkodOlustur_dummy: number;
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: any;
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport_dummy: any;
  DownloadReport: any;
  DownloadReport_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  isComp184425Enabled: "enabled" | "disabled";
}

export class SiparisDetayParsiyel_ScreenBase extends React.PureComponent<ISiparisDetayParsiyel_ScreenProps, any> {
  siparisdetayparsiyel_348053_value_kuikaDateRef: React.RefObject<any>;
  siparisdetayparsiyel_906945_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisdetayparsiyel_61825_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "a9ea61bc-01c7-42b0-97d4-5aaf338c9f39",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 984146, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "SİPARİŞ DOSYA BİLGİLERİ" },
        { Id: 59869, PropertyName: "value", Value: "Sipariş No" },
        { Id: 184425, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 814991, PropertyName: "value", Value: "Müşteri Sipariş No" },
        { Id: 289156, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 943220, PropertyName: "value", Value: "Tarih" },
        { Id: 348053, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 574267, PropertyName: "value", Value: "Müşteri" },
        { Id: 906945, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 365389, PropertyName: "value", Value: "Açıklama" },
        { Id: 271174, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 664583, PropertyName: "tab", Value: "Sipariş Detayları" },
        { Id: 621201, PropertyName: "label", Value: "Yeni Sevk Emri" },
        { Id: 61825, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 161036, PropertyName: "title", Value: "İşlemler" },
        { Id: 595857, PropertyName: "title", Value: "SiraNo" },
        { Id: 494226, PropertyName: "value", Value: "[datafield:sirano]" },
        { Id: 96534, PropertyName: "placeholder", Value: "15" },
        { Id: 630845, PropertyName: "title", Value: "Yukleme Noktası" },
        { Id: 136405, PropertyName: "value", Value: "[datafield:yuklemeyeri]" },
        { Id: 205776, PropertyName: "title", Value: "Varış Noktası" },
        { Id: 203950, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 401051, PropertyName: "title", Value: "Palet Adedi" },
        { Id: 662659, PropertyName: "value", Value: "[datafield:paletadedi]" },
        { Id: 422013, PropertyName: "title", Value: "Yukleme Yapıldı" },
        { Id: 231707, PropertyName: "title", Value: "Depoya Alındı" },
        { Id: 808179, PropertyName: "title", Value: "Teslim " },
        { Id: 8606, PropertyName: "label", Value: "Vazgeç" },
        { Id: 205082, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.siparisdetayparsiyel_348053_value_kuikaDateRef = React.createRef();
    this.siparisdetayparsiyel_906945_value_kuikaSelectBoxRef = React.createRef();
    this.siparisdetayparsiyel_61825_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Yonetim_FirmaListe: [],
      Get_Tanim_MusteriListe_LTL: [],
      Siparis_Get_Parsiyel_ById: [],
      Siparis_Get_SevkEmirListe: [],
      ChangeEnabledOf: "",
      Siparis_Save_Parsiyel: 0,
      Siparis_Delete_SevkEmriDetay: 0,
      Siparis_Parsiyel_BarkodOlustur: 0,
      KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: "",
      DownloadReport: "",
      GoBack: "",
      isComp184425Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siparisdetayparsiyel", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SiparisDetayParsiyelPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("siparisdetayparsiyel", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("siparisdetayparsiyel", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SiparisDetayParsiyelPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      siparisdetayparsiyel_184425_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.siparisNo ?? undefined,
      siparisdetayparsiyel_289156_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.musteriSiparisNo ?? undefined,
      siparisdetayparsiyel_348053_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.tarih ?? undefined,
      siparisdetayparsiyel_906945_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.musteriId ?? undefined,
      siparisdetayparsiyel_271174_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SiparisDetayParsiyelPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      search_2: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisDetayParsiyel/SiparisDetayParsiyelPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Yonetim_FirmaListe = result?.data.get_Yonetim_FirmaListe;
    stateVars.Get_Tanim_MusteriListe_LTL = result?.data.get_Tanim_MusteriListe_LTL;

    formVars.siparisdetayparsiyel_906945_options = stateVars.Get_Tanim_MusteriListe_LTL;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayParsiyelPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisDetayParsiyel/SiparisDetayParsiyelPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Siparis_Get_Parsiyel_ById = result?.data.siparis_Get_Parsiyel_ById;
      formVars.siparisdetayparsiyel_184425_value = ReactSystemFunctions.toString(
        this,
        stateVars.Siparis_Get_Parsiyel_ById?.length > 0 ? stateVars.Siparis_Get_Parsiyel_ById[0]?.siparisNo : null
      );
      formVars.siparisdetayparsiyel_289156_value = ReactSystemFunctions.toString(
        this,
        stateVars.Siparis_Get_Parsiyel_ById?.length > 0
          ? stateVars.Siparis_Get_Parsiyel_ById[0]?.musteriSiparisNo
          : null
      );
      formVars.siparisdetayparsiyel_348053_value = ReactSystemFunctions.value(
        this,
        stateVars.Siparis_Get_Parsiyel_ById?.length > 0 ? stateVars.Siparis_Get_Parsiyel_ById[0]?.tarih : null
      );
      formVars.siparisdetayparsiyel_906945_value =
        stateVars.Siparis_Get_Parsiyel_ById?.length > 0 ? stateVars.Siparis_Get_Parsiyel_ById[0]?.musteriId : null;
      formVars.siparisdetayparsiyel_271174_value = ReactSystemFunctions.toString(
        this,
        stateVars.Siparis_Get_Parsiyel_ById?.length > 0 ? stateVars.Siparis_Get_Parsiyel_ById[0]?.aciklama : null
      );
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayParsiyel/SiparisDetayParsiyelPageInit2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Get_SevkEmirListe = result?.data.siparis_Get_SevkEmirListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayParsiyelPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp184425Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayParsiyelPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.siparisdetayparsiyel_184425_value = ReactSystemFunctions.toString(
        this,
        this.state.Siparis_Get_Parsiyel_ById?.length > 0 ? this.state.Siparis_Get_Parsiyel_ById[0]?.siparisNo : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.siparisdetayparsiyel_289156_value = ReactSystemFunctions.toString(
        this,
        this.state.Siparis_Get_Parsiyel_ById?.length > 0
          ? this.state.Siparis_Get_Parsiyel_ById[0]?.musteriSiparisNo
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.siparisdetayparsiyel_348053_value = ReactSystemFunctions.value(
        this,
        this.state.Siparis_Get_Parsiyel_ById?.length > 0 ? this.state.Siparis_Get_Parsiyel_ById[0]?.tarih : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.siparisdetayparsiyel_906945_value = ReactSystemFunctions.toString(
        this,
        this.state.Siparis_Get_Parsiyel_ById?.length > 0 ? this.state.Siparis_Get_Parsiyel_ById[0]?.musteriId : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_906945 = this.state.Get_Tanim_MusteriListe_LTL;
    stateVars.dataSource_906945 = this.state.Get_Tanim_MusteriListe_LTL;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayParsiyelPageInit9_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.siparisdetayparsiyel_271174_value = ReactSystemFunctions.toString(
        this,
        this.state.Siparis_Get_Parsiyel_ById?.length > 0 ? this.state.Siparis_Get_Parsiyel_ById[0]?.aciklama : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisDetayParsiyelPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_61825 = this.state.Siparis_Get_SevkEmirListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SiparisDetayParsiyelComponent_258771_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisDetayParsiyel",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "453804",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayParsiyelComponent_621201_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      SonSiparisNo_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Tarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_348053_value", "value", "", "", "")
        ),
        "Date"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_271174_value", "value", "", "", "")
        ),
        "string"
      ),
      SipNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_184425_value", "value", "", "", "")
        ),
        "string"
      ),
      MusteriSiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_289156_value", "value", "", "", "")
        ),
        "string"
      ),
      MusteriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayparsiyel_906945_value",
            "value",
            "Get_Tanim_MusteriListe_LTL",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.firmaId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayParsiyel/SiparisDetayParsiyelComponent_621201_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Save_Parsiyel = result?.data.siparis_Save_Parsiyel;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayParsiyelComponent_621201_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayParsiyelComponent_621201_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SiparisSevkEmriDetay",
      "SiparisId",
      this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid
    );
    KuikaAppManager.addToPageInputVariables(
      "SiparisSevkEmriDetay",
      "MusteriId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(
          this,
          "siparisdetayparsiyel_906945_value",
          "value",
          "Get_Tanim_MusteriListe_LTL",
          "id",
          "id"
        )
      )
    );
    KuikaAppManager.addToPageInputVariables("SiparisSevkEmriDetay", "SevkEmriId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisDetayParsiyel",
      "SiparisSevkEmriDetay",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "968196",
      null,
      null,
      "true",
      "1000px",
      "75vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayParsiyelComponent_190424_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SiparisSevkEmriDetay",
      "SiparisId",
      this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid
    );
    KuikaAppManager.addToPageInputVariables("SiparisSevkEmriDetay", "MusteriId", null);
    KuikaAppManager.addToPageInputVariables(
      "SiparisSevkEmriDetay",
      "SevkEmriId",
      ReactSystemFunctions.value(this, "siparisdetayparsiyel_61825_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisDetayParsiyel",
      "SiparisSevkEmriDetay",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "348695",
      null,
      null,
      "true",
      "1000px",
      "75vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayParsiyelComponent_791152_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SevkEmriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siparisdetayparsiyel_61825_value", "id"),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "be740d04_3f3b_8312_065d_0720134edaac_confirmation",
        this.defaultML,
        "Seçilen satır silinecektir. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisDetayParsiyel/SiparisDetayParsiyelComponent_791152_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Siparis_Delete_SevkEmriDetay = result?.data.siparis_Delete_SevkEmriDetay;
      stateVars.Siparis_Get_SevkEmirListe = result?.data.siparis_Get_SevkEmirListe;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayParsiyelComponent_791152_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayParsiyelComponent_791152_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_61825 = this.state.Siparis_Get_SevkEmirListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayParsiyelComponent_722409_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BarkodOlustu_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      SevkEmriDetayId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siparisdetayparsiyel_61825_value", "id"),
        "Guid"
      ),
      PaletAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siparisdetayparsiyel_61825_value", "paletAdedi"),
        "number"
      ),
      SonPaletNo_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siparisdetayparsiyel_61825_value", "siparisNo"),
        "string"
      ),
      Sayac_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      _ReportName_2: ReactSystemFunctions.convertToTypeByName("MalKabulEtiket", "string"),
      _SevkEmriId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siparisdetayparsiyel_61825_value", "id"),
        "string"
      ),
      _UserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayParsiyel/SiparisDetayParsiyelComponent_722409_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Parsiyel_BarkodOlustur = result?.data.siparis_Parsiyel_BarkodOlustur;
    stateVars.Siparis_Get_SevkEmirListe = result?.data.siparis_Get_SevkEmirListe;

    stateVars.KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport =
      result?.data.kuikaReportGetReportBase64_GetReportBaseFromArunakuikareport;
    stateVars.DownloadReport = result?.data.downloadReport;
    ReactSystemFunctions.downloadFile(result?.data?.downloadReport);
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayParsiyelComponent_722409_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayParsiyelComponent_722409_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_61825 = this.state.Siparis_Get_SevkEmirListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayParsiyelComponent_8606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "777920e6_9b6c_21a2_f7dd_096b3af52592_confirmation",
        this.defaultML,
        "Kaydedilmemiş veriler iptal edilecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisDetayParsiyelComponent_205082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayParsiyelComponent_205082_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SiparisDetayParsiyelComponent_205082_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      SonSiparisNo_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Tarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_348053_value", "value", "", "", "")
        ),
        "Date"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_271174_value", "value", "", "", "")
        ),
        "string"
      ),
      SipNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_184425_value", "value", "", "", "")
        ),
        "string"
      ),
      MusteriSiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayparsiyel_289156_value", "value", "", "", "")
        ),
        "string"
      ),
      MusteriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayparsiyel_906945_value",
            "value",
            "Get_Tanim_MusteriListe_LTL",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.firmaId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayParsiyel/SiparisDetayParsiyelComponent_205082_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Save_Parsiyel = result?.data.siparis_Save_Parsiyel;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [453804, 968196, 348695] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SiparisDetayParsiyelPageInit();
    }
  }
}
