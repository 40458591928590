import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYuklemeListesi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYuklemeListesi_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Date: any[];
  Get_Date_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  Yukleme_Get_YukListesi: any[];
  Yukleme_Get_YukListesi_dummy: any[];
  Export_YuklemeListesi: any[];
  Export_YuklemeListesi_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: any;
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport_dummy: any;
  DownloadReport: any;
  DownloadReport_dummy: any;
  Yukleme_Delete_YuklemeDetay: number;
  Yukleme_Delete_YuklemeDetay_dummy: number;
  isComp475831Visible: "visible" | "hidden";
  isComp160456Visible: "visible" | "hidden";
}

export class YuklemeListesi_ScreenBase extends React.PureComponent<IYuklemeListesi_ScreenProps, any> {
  yuklemelistesi_129888_value_kuikaDateRef: React.RefObject<any>;
  yuklemelistesi_140956_value_kuikaDateRef: React.RefObject<any>;
  yuklemelistesi_631038_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "15e3149e-28df-4b05-99b9-ff53d8d6da5a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "YÜKLEME EMRİ TAKİP LİSTESİ" },
        { Id: 186535, PropertyName: "label", Value: "Excel Kaydet" },
        { Id: 755618, PropertyName: "value", Value: "TARİH ARALIĞI SEÇİNİZ" },
        { Id: 129888, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 140956, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 111189, PropertyName: "label", Value: "Yenile" },
        { Id: 631038, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 334098, PropertyName: "title", Value: "Title" },
        { Id: 938941, PropertyName: "value", Value: "Yükleme No" },
        { Id: 368138, PropertyName: "value", Value: "[datafield:yuklemenoo]" },
        { Id: 351326, PropertyName: "value", Value: "Açıklama" },
        { Id: 866260, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 865117, PropertyName: "value", Value: "Plaka" },
        { Id: 591670, PropertyName: "value", Value: "[datafield:plaka]" },
        { Id: 251557, PropertyName: "value", Value: "Navlun Bedeli :" },
        { Id: 981562, PropertyName: "value", Value: "[datafield:navlunbedeli]" },
        { Id: 679642, PropertyName: "value", Value: "Masraf Tutarı" },
        { Id: 338230, PropertyName: "value", Value: "[datafield:masraftutar]" },
        { Id: 410217, PropertyName: "value", Value: "Toplam" },
        { Id: 483632, PropertyName: "value", Value: "[datafield:toplamfiyat]" },
        { Id: 732269, PropertyName: "value", Value: "Palet Sayısı" },
        { Id: 51784, PropertyName: "value", Value: "[datafield:paletadedi]" },
        { Id: 26698, PropertyName: "value", Value: "Sevk Edilen" },
        { Id: 682258, PropertyName: "value", Value: "[datafield:sevkedilenpaletadedi]" },
        { Id: 340843, PropertyName: "value", Value: "Ürün Cinsi" },
        { Id: 960153, PropertyName: "value", Value: "Siparis No" },
        { Id: 725517, PropertyName: "value", Value: "Yükleme Yeri" },
        { Id: 379780, PropertyName: "value", Value: "Varış Yeri" },
        { Id: 307806, PropertyName: "value", Value: "Palet Sayısı" },
        { Id: 716075, PropertyName: "value", Value: "Sevk Edilen" },
        { Id: 231890, PropertyName: "value", Value: "[datafield:uruncinsi]" },
        { Id: 197976, PropertyName: "value", Value: "[datafield:siparisno]" },
        { Id: 951902, PropertyName: "value", Value: "[datafield:yuklemeyeri]" },
        { Id: 584256, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 51801, PropertyName: "value", Value: "[datafield:paletadedi]" },
        { Id: 96633, PropertyName: "value", Value: "[datafield:sevkedilenpaletadedi]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.yuklemelistesi_129888_value_kuikaDateRef = React.createRef();
    this.yuklemelistesi_140956_value_kuikaDateRef = React.createRef();
    this.yuklemelistesi_631038_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Date: [],
      SetValueOf: "",
      Yukleme_Get_YukListesi: [],
      Export_YuklemeListesi: [],
      ExportExcel: "",
      KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: "",
      DownloadReport: "",
      Yukleme_Delete_YuklemeDetay: 0,
      isComp475831Visible: "hidden",
      isComp160456Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yuklemelistesi", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YuklemeListesiPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yuklemelistesi", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yuklemelistesi", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YuklemeListesiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YuklemeListesiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YuklemeListesi/YuklemeListesiPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Date = result?.data.get_Date;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeListesiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeListesiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yuklemelistesi_129888_value",
      ReactSystemFunctions.value(this, this.state.Get_Date?.length > 0 ? this.state.Get_Date[0]?.ayIlkGun : null),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yuklemelistesi_140956_value",
      ReactSystemFunctions.value(this, this.state.Get_Date?.length > 0 ? this.state.Get_Date[0]?.aySonGun : null),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeListesiPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YuklemeListesiPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      BaslangicTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_129888_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitisTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_140956_value", "value", "", "", "")
        ),
        "Date"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YuklemeListesi/YuklemeListesiPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Yukleme_Get_YukListesi = result?.data.yukleme_Get_YukListesi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeListesiPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeListesiPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_631038 = this.state.Yukleme_Get_YukListesi;
    stateVars.isComp475831Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "yuklemelistesi_631038_value", "yuklemeNoo"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160456Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "yuklemelistesi_631038_value", "yuklemeNoo"),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YuklemeListesiComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YuklemeListesi",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeListesiComponent_186535_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BaslangicTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_129888_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitisTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_140956_value", "value", "", "", "")
        ),
        "Date"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("YÜKLEME LİSTESİ", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeListesi/YuklemeListesiComponent_186535_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Export_YuklemeListesi = result?.data.export_YuklemeListesi;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  YuklemeListesiComponent_111189_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BaslangicTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_129888_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitisTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_140956_value", "value", "", "", "")
        ),
        "Date"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeListesi/YuklemeListesiComponent_111189_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Yukleme_Get_YukListesi = result?.data.yukleme_Get_YukListesi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeListesiComponent_111189_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeListesiComponent_111189_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_631038 = this.state.Yukleme_Get_YukListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeListesiComponent_755674_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      _ReportName_0: ReactSystemFunctions.convertToTypeByName("TasimaIrsaliyesi", "string"),
      _SevkEmriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "yuklemelistesi_631038_value", "id"),
        "string"
      ),
      _UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeListesi/YuklemeListesiComponent_755674_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport =
      result?.data.kuikaReportGetReportBase64_GetReportBaseFromArunakuikareport;
    stateVars.DownloadReport = result?.data.downloadReport;
    ReactSystemFunctions.downloadFile(result?.data?.downloadReport);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  YuklemeListesiComponent_146804_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "YuklemeTeslimatBilgi",
      "SiparisId",
      ReactSystemFunctions.value(this, "yuklemelistesi_631038_value", "siparisId")
    );
    KuikaAppManager.addToPageInputVariables(
      "YuklemeTeslimatBilgi",
      "MusteriId",
      ReactSystemFunctions.value(this, "yuklemelistesi_631038_value", "musteriId")
    );
    KuikaAppManager.addToPageInputVariables(
      "YuklemeTeslimatBilgi",
      "SevkEmriId",
      ReactSystemFunctions.value(this, "yuklemelistesi_631038_value", "sevkEmriDetayId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YuklemeListesi",
      "YuklemeTeslimatBilgi",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeListesiComponent_60003_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "yuklemelistesi_631038_value", "yuklemeDetayId"),
        "Guid"
      ),
      BaslangicTarih_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_129888_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitisTarih_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemelistesi_140956_value", "value", "", "", "")
        ),
        "Date"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4f049599_4447_98e7_abc8_a17660b30c12_confirmation",
        this.defaultML,
        "Seçilen kayıt yükleme listesinden silinip tekrar yükleme bekleyenlere alınacak. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YuklemeListesi/YuklemeListesiComponent_60003_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Yukleme_Delete_YuklemeDetay = result?.data.yukleme_Delete_YuklemeDetay;
      stateVars.Yukleme_Get_YukListesi = result?.data.yukleme_Get_YukListesi;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeListesiComponent_60003_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeListesiComponent_60003_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_631038 = this.state.Yukleme_Get_YukListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.YuklemeListesiPageInit();
    }
  }
}
