import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISiparisDetayFullTruck_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISiparisDetayFullTruck_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Yonetim_FirmaListe: any[];
  Get_Yonetim_FirmaListe_dummy: any[];
  Get_Tanim_MusteriListe_FTL: any[];
  Get_Tanim_MusteriListe_FTL_dummy: any[];
  Get_Tanim_AracListe: any[];
  Get_Tanim_AracListe_dummy: any[];
  Get_Tanim_SurucuListe: any[];
  Get_Tanim_SurucuListe_dummy: any[];
  Siparis_Get_FullTruck_ById: any[];
  Siparis_Get_FullTruck_ById_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  Siparis_Get_FullTruck_VarisNoktalar: any[];
  Siparis_Get_FullTruck_VarisNoktalar_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  Get_Tanim_MusteriNoktaListe: any[];
  Get_Tanim_MusteriNoktaListe_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Siparis_Save_FullTruck: number;
  Siparis_Save_FullTruck_dummy: number;
  Siparis_Add_FullTruck_VarisNokta: number;
  Siparis_Add_FullTruck_VarisNokta_dummy: number;
  Siparis_Delete_FullTruck_VarisNokta: number;
  Siparis_Delete_FullTruck_VarisNokta_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  isComp184425Enabled: "enabled" | "disabled";
  isComp937297Enabled: "enabled" | "disabled";
  isCompsiparisdetayfulltruck_184425AuthorizationVisible: "visible" | "hidden";
}

export class SiparisDetayFullTruck_ScreenBase extends React.PureComponent<ISiparisDetayFullTruck_ScreenProps, any> {
  siparisdetayfulltruck_937297_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisdetayfulltruck_348053_value_kuikaDateRef: React.RefObject<any>;
  siparisdetayfulltruck_711444_value_kuikaAutoCompleteRef: React.RefObject<any>;
  siparisdetayfulltruck_458182_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisdetayfulltruck_385152_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisdetayfulltruck_82104_value_kuikaTableRef: React.RefObject<any>;
  siparisdetayfulltruck_997723_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisdetayfulltruck_281991_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisdetayfulltruck_830996_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisdetayfulltruck_969512_value_kuikaDateRef: React.RefObject<any>;
  siparisdetayfulltruck_95704_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "25c07fbe-5722-4ae9-9428-389e8121a846",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 619463, PropertyName: "value", Value: "Firma" },
        { Id: 937297, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 162294, PropertyName: "value", Value: "SİPARİŞ DOSYA BİLGİLERİ" },
        { Id: 59869, PropertyName: "value", Value: "Sipariş No" },
        { Id: 184425, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 814991, PropertyName: "value", Value: "Müşteri Sip. No" },
        { Id: 289156, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 943220, PropertyName: "value", Value: "Tarih" },
        { Id: 348053, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 574267, PropertyName: "value", Value: "Müşteri" },
        { Id: 711444, PropertyName: "placeholder", Value: "Search..." },
        { Id: 826654, PropertyName: "value", Value: "Faturalanacak" },
        { Id: 365389, PropertyName: "value", Value: "Açıklama" },
        { Id: 271174, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 741963, PropertyName: "value", Value: "YÜKLEME - VARIŞ ADRESLERİ" },
        { Id: 541626, PropertyName: "value", Value: "Yükleme Noktası" },
        { Id: 458182, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 682918, PropertyName: "value", Value: "Varış Noktası" },
        { Id: 385152, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 509047, PropertyName: "value", Value: "Sıra No" },
        { Id: 980405, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 405729, PropertyName: "label", Value: "Yeni Nokta  Oluştur" },
        { Id: 528800, PropertyName: "label", Value: "Nokta  Ekle" },
        { Id: 82104, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 891684, PropertyName: "title", Value: "İşlemler" },
        { Id: 76337, PropertyName: "title", Value: "Nokta Adı" },
        { Id: 352360, PropertyName: "value", Value: "[datafield:noktaadi]" },
        { Id: 222161, PropertyName: "title", Value: "Sira No" },
        { Id: 152280, PropertyName: "value", Value: "[datafield:sirano]" },
        { Id: 56624, PropertyName: "placeholder", Value: "15" },
        { Id: 853831, PropertyName: "value", Value: "ÜRÜN - FİYAT BİLGİLERİ" },
        { Id: 99168, PropertyName: "value", Value: "Ürün Cinsi" },
        { Id: 949947, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 688024, PropertyName: "value", Value: "Fiyat" },
        { Id: 731384, PropertyName: "placeholder", Value: "0" },
        { Id: 304833, PropertyName: "value", Value: "Kdv Dahil" },
        { Id: 916382, PropertyName: "value", Value: "Derece" },
        { Id: 997723, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 5207, PropertyName: "value", Value: "ARAÇ BİLGİLERİ" },
        { Id: 501297, PropertyName: "value", Value: "Araç Plaka" },
        { Id: 281991, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 489227, PropertyName: "value", Value: "Sürücü" },
        { Id: 830996, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 262440, PropertyName: "value", Value: "YÜKLEME - TESLİMAT DURUMLARI" },
        { Id: 120422, PropertyName: "value", Value: "Yükleme Yapıldı" },
        { Id: 130816, PropertyName: "value", Value: "Yükleme Tarihi" },
        { Id: 969512, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 668368, PropertyName: "value", Value: "Teslimat Yapıldı" },
        { Id: 307408, PropertyName: "value", Value: "Teslimat Tarihi" },
        { Id: 95704, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 8606, PropertyName: "label", Value: "Vazgeç" },
        { Id: 205082, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.siparisdetayfulltruck_937297_value_kuikaSelectBoxRef = React.createRef();
    this.siparisdetayfulltruck_348053_value_kuikaDateRef = React.createRef();
    this.siparisdetayfulltruck_711444_value_kuikaAutoCompleteRef = React.createRef();
    this.siparisdetayfulltruck_458182_value_kuikaSelectBoxRef = React.createRef();
    this.siparisdetayfulltruck_385152_value_kuikaSelectBoxRef = React.createRef();
    this.siparisdetayfulltruck_82104_value_kuikaTableRef = React.createRef();
    this.siparisdetayfulltruck_997723_value_kuikaSelectBoxRef = React.createRef();
    this.siparisdetayfulltruck_281991_value_kuikaSelectBoxRef = React.createRef();
    this.siparisdetayfulltruck_830996_value_kuikaSelectBoxRef = React.createRef();
    this.siparisdetayfulltruck_969512_value_kuikaDateRef = React.createRef();
    this.siparisdetayfulltruck_95704_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_UserInfoByUserName: [],
      Get_Yonetim_FirmaListe: [],
      Get_Tanim_MusteriListe_FTL: [],
      Get_Tanim_AracListe: [],
      Get_Tanim_SurucuListe: [],
      Siparis_Get_FullTruck_ById: [],
      TriggerEvent: "",
      Siparis_Get_FullTruck_VarisNoktalar: [],
      ChangeEnabledOf: "",
      Get_Tanim_MusteriNoktaListe: [],
      NAVIGATE: "",
      Siparis_Save_FullTruck: 0,
      Siparis_Add_FullTruck_VarisNokta: 0,
      Siparis_Delete_FullTruck_VarisNokta: 0,
      GoBack: "",
      dataSource_997723: [
        { key: "-18", text: "-18" },
        { key: "0", text: "0" },
        { key: "+4", text: "+4" },
        { key: "+18", text: "+18" }
      ],
      isComp184425Enabled: "enabled",
      isComp937297Enabled: "enabled",
      isCompsiparisdetayfulltruck_184425AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siparisdetayfulltruck", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SiparisDetayFullTruckPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsiparisdetayfulltruck_184425AuthorizationVisible", []);

    KuikaAppManager.calculateAndSetBodyHeight("siparisdetayfulltruck", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("siparisdetayfulltruck", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SiparisDetayFullTruckPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      siparisdetayfulltruck_937297_value: this.state.Get_Helpers_UserInfoByUserName?.at?.(0)?.firmaId ?? undefined,
      siparisdetayfulltruck_184425_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.siparisNo ?? undefined,
      siparisdetayfulltruck_289156_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.musteriSiparisNo ?? undefined,
      siparisdetayfulltruck_348053_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.tarih ?? undefined,
      siparisdetayfulltruck_711444_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.musteriId ?? undefined,
      siparisdetayfulltruck_690238_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.faturaOlussun ?? undefined,
      siparisdetayfulltruck_271174_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.aciklama ?? undefined,
      siparisdetayfulltruck_458182_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.yuklemeNokta ?? undefined,
      siparisdetayfulltruck_949947_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.urunCinsi ?? undefined,
      siparisdetayfulltruck_731384_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.fiyat ?? undefined,
      siparisdetayfulltruck_549657_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.kdvDahil ?? undefined,
      siparisdetayfulltruck_997723_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.nakliyeDerece ?? undefined,
      siparisdetayfulltruck_281991_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.aracId ?? undefined,
      siparisdetayfulltruck_830996_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.surucuId ?? undefined,
      siparisdetayfulltruck_275883_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.yuklemeYapildi ?? undefined,
      siparisdetayfulltruck_969512_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.yuklemeTarihi ?? undefined,
      siparisdetayfulltruck_846146_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.teslimatYapildi ?? undefined,
      siparisdetayfulltruck_95704_value: this.state.Siparis_Get_FullTruck_ById?.at?.(0)?.teslimatTarihi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SiparisDetayFullTruckPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      search_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayFullTruck/SiparisDetayFullTruckPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    formVars.siparisdetayfulltruck_937297_value =
      stateVars.Get_Helpers_UserInfoByUserName?.length > 0
        ? stateVars.Get_Helpers_UserInfoByUserName[0]?.firmaId
        : null;
    formVars.siparisdetayfulltruck_937297_options = stateVars.Get_Yonetim_FirmaListe;
    stateVars.Get_Yonetim_FirmaListe = result?.data.get_Yonetim_FirmaListe;

    formVars.siparisdetayfulltruck_937297_value =
      stateVars.Get_Helpers_UserInfoByUserName?.length > 0
        ? stateVars.Get_Helpers_UserInfoByUserName[0]?.firmaId
        : null;
    formVars.siparisdetayfulltruck_937297_options = stateVars.Get_Yonetim_FirmaListe;
    stateVars.Get_Tanim_MusteriListe_FTL = result?.data.get_Tanim_MusteriListe_FTL;

    formVars.siparisdetayfulltruck_711444_options = stateVars.Get_Tanim_MusteriListe_FTL;
    formVars.siparisdetayfulltruck_711444_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.musteriId : null;
    stateVars.Get_Tanim_AracListe = result?.data.get_Tanim_AracListe;

    formVars.siparisdetayfulltruck_281991_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.aracId : null;
    formVars.siparisdetayfulltruck_281991_options = stateVars.Get_Tanim_AracListe;
    stateVars.Get_Tanim_SurucuListe = result?.data.get_Tanim_SurucuListe;

    formVars.siparisdetayfulltruck_830996_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.surucuId : null;
    formVars.siparisdetayfulltruck_830996_options = stateVars.Get_Tanim_SurucuListe;
    stateVars.Siparis_Get_FullTruck_ById = result?.data.siparis_Get_FullTruck_ById;
    formVars.siparisdetayfulltruck_184425_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.siparisNo : null
    );
    formVars.siparisdetayfulltruck_289156_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0
        ? stateVars.Siparis_Get_FullTruck_ById[0]?.musteriSiparisNo
        : null
    );
    formVars.siparisdetayfulltruck_348053_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.tarih : null
    );
    formVars.siparisdetayfulltruck_711444_options = stateVars.Get_Tanim_MusteriListe_FTL;
    formVars.siparisdetayfulltruck_711444_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.musteriId : null;
    formVars.siparisdetayfulltruck_690238_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.faturaOlussun : null
    );
    formVars.siparisdetayfulltruck_271174_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.aciklama : null
    );
    formVars.siparisdetayfulltruck_458182_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.yuklemeNokta : null;
    formVars.siparisdetayfulltruck_949947_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.urunCinsi : null
    );
    formVars.siparisdetayfulltruck_731384_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.fiyat : null
    );
    formVars.siparisdetayfulltruck_549657_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.kdvDahil : null
    );
    formVars.siparisdetayfulltruck_997723_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.nakliyeDerece : null;
    formVars.siparisdetayfulltruck_281991_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.aracId : null;
    formVars.siparisdetayfulltruck_281991_options = stateVars.Get_Tanim_AracListe;
    formVars.siparisdetayfulltruck_830996_value =
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.surucuId : null;
    formVars.siparisdetayfulltruck_830996_options = stateVars.Get_Tanim_SurucuListe;
    formVars.siparisdetayfulltruck_275883_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.yuklemeYapildi : null
    );
    formVars.siparisdetayfulltruck_969512_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.yuklemeTarihi : null
    );
    formVars.siparisdetayfulltruck_846146_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.teslimatYapildi : null
    );
    formVars.siparisdetayfulltruck_95704_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_FullTruck_ById?.length > 0 ? stateVars.Siparis_Get_FullTruck_ById[0]?.teslimatTarihi : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayFullTruckPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.SiparisDetayFullTruckComponent_711444_onBlur()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SiparisDetayFullTruckPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      SiparisId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayFullTruck/SiparisDetayFullTruckPageInit2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Get_FullTruck_VarisNoktalar = result?.data.siparis_Get_FullTruck_VarisNoktalar;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayFullTruckPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp184425Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SiparisDetayFullTruckPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.rolName
            : null
        ),
        "SistemAdmin"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp937297Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayFullTruckPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisDetayFullTruckPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisDetayFullTruckPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.siparisdetayfulltruck_937297_value = ReactSystemFunctions.toString(
      this,
      this.state.Get_Helpers_UserInfoByUserName?.length > 0
        ? this.state.Get_Helpers_UserInfoByUserName[0]?.firmaId
        : null
    );

    stateVars.dataSource_937297 = this.state.Get_Yonetim_FirmaListe;
    stateVars.dataSource_937297 = this.state.Get_Yonetim_FirmaListe;
    formVars.siparisdetayfulltruck_184425_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.siparisNo : null
    );

    formVars.siparisdetayfulltruck_289156_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0
        ? this.state.Siparis_Get_FullTruck_ById[0]?.musteriSiparisNo
        : null
    );

    formVars.siparisdetayfulltruck_348053_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.tarih : null
    );

    formVars.siparisdetayfulltruck_711444_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.musteriId : null
    );

    stateVars.dataSource_711444 = this.state.Get_Tanim_MusteriListe_FTL;
    stateVars.dataSource_711444 = this.state.Get_Tanim_MusteriListe_FTL;
    formVars.siparisdetayfulltruck_690238_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.faturaOlussun : null
    );

    formVars.siparisdetayfulltruck_271174_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.aciklama : null
    );

    formVars.siparisdetayfulltruck_458182_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.yuklemeNokta : null
    );

    stateVars.dataSource_82104 = this.state.Siparis_Get_FullTruck_VarisNoktalar;
    formVars.siparisdetayfulltruck_949947_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.urunCinsi : null
    );

    formVars.siparisdetayfulltruck_731384_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.fiyat : null
    );

    formVars.siparisdetayfulltruck_549657_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.kdvDahil : null
    );

    formVars.siparisdetayfulltruck_997723_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.nakliyeDerece : null
    );

    formVars.siparisdetayfulltruck_281991_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.aracId : null
    );

    stateVars.dataSource_281991 = this.state.Get_Tanim_AracListe;
    stateVars.dataSource_281991 = this.state.Get_Tanim_AracListe;
    formVars.siparisdetayfulltruck_830996_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.surucuId : null
    );

    stateVars.dataSource_830996 = this.state.Get_Tanim_SurucuListe;
    stateVars.dataSource_830996 = this.state.Get_Tanim_SurucuListe;
    formVars.siparisdetayfulltruck_275883_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0
        ? this.state.Siparis_Get_FullTruck_ById[0]?.yuklemeYapildi
        : null
    );

    formVars.siparisdetayfulltruck_969512_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0 ? this.state.Siparis_Get_FullTruck_ById[0]?.yuklemeTarihi : null
    );

    formVars.siparisdetayfulltruck_846146_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0
        ? this.state.Siparis_Get_FullTruck_ById[0]?.teslimatYapildi
        : null
    );

    formVars.siparisdetayfulltruck_95704_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_FullTruck_ById?.length > 0
        ? this.state.Siparis_Get_FullTruck_ById[0]?.teslimatTarihi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SiparisDetayFullTruckComponent_711444_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MusteriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_711444_value",
            "value",
            "Get_Tanim_MusteriListe_FTL",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayFullTruck/SiparisDetayFullTruckComponent_711444_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Tanim_MusteriNoktaListe = result?.data.get_Tanim_MusteriNoktaListe;

    formVars.siparisdetayfulltruck_458182_options = stateVars.Get_Tanim_MusteriNoktaListe;

    formVars.siparisdetayfulltruck_385152_options = stateVars.Get_Tanim_MusteriNoktaListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckComponent_711444_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayFullTruckComponent_711444_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_458182 = this.state.Get_Tanim_MusteriNoktaListe;
    stateVars.dataSource_458182 = this.state.Get_Tanim_MusteriNoktaListe;

    stateVars.dataSource_385152 = this.state.Get_Tanim_MusteriNoktaListe;
    stateVars.dataSource_385152 = this.state.Get_Tanim_MusteriNoktaListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayFullTruckComponent_711444_onSearch = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_711444_value",
            "",
            "Get_Tanim_MusteriListe_FTL",
            "id",
            "k_searchValue"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayFullTruck/SiparisDetayFullTruckComponent_711444_onSearch",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Tanim_MusteriListe_FTL = result?.data.get_Tanim_MusteriListe_FTL;

    formVars.siparisdetayfulltruck_711444_options = stateVars.Get_Tanim_MusteriListe_FTL;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckComponent_711444_onSearch1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayFullTruckComponent_711444_onSearch1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_711444 = this.state.Get_Tanim_MusteriListe_FTL;
    stateVars.dataSource_711444 = this.state.Get_Tanim_MusteriListe_FTL;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayFullTruckComponent_405729_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TanimMusteriNoktaBilgiKart",
      "NoktaId",
      "00000000-0000-0000-0000-000000000000"
    );
    KuikaAppManager.addToPageInputVariables(
      "TanimMusteriNoktaBilgiKart",
      "MusteriId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(
          this,
          "siparisdetayfulltruck_711444_value",
          "value",
          "Get_Tanim_MusteriListe_FTL",
          "id",
          "id"
        )
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.SiparisDetayFullTruckComponent_405729_onClick1_,
      "SiparisDetayFullTruck",
      "TanimMusteriNoktaBilgiKart",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "691812",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  SiparisDetayFullTruckComponent_405729_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MusteriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_711444_value",
            "value",
            "Get_Tanim_MusteriListe_FTL",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayFullTruck/SiparisDetayFullTruckComponent_405729_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Tanim_MusteriNoktaListe = result?.data.get_Tanim_MusteriNoktaListe;

    formVars.siparisdetayfulltruck_458182_options = stateVars.Get_Tanim_MusteriNoktaListe;

    formVars.siparisdetayfulltruck_385152_options = stateVars.Get_Tanim_MusteriNoktaListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckComponent_405729_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayFullTruckComponent_405729_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_458182 = this.state.Get_Tanim_MusteriNoktaListe;
    stateVars.dataSource_458182 = this.state.Get_Tanim_MusteriNoktaListe;

    stateVars.dataSource_385152 = this.state.Get_Tanim_MusteriNoktaListe;
    stateVars.dataSource_385152 = this.state.Get_Tanim_MusteriNoktaListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayFullTruckComponent_528800_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      SonSiparisNo_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      KdvDahil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_549657_value", "value", "", "", "")
        ),
        "boolean"
      ),
      FaturaOlussun_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_690238_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeslimatYapildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_846146_value", "value", "", "", "")
        ),
        "boolean"
      ),
      YuklemeYapildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_275883_value", "value", "", "", "")
        ),
        "boolean"
      ),
      YuklemeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_969512_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeslimatTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_95704_value", "value", "", "", "")
        ),
        "Date"
      ),
      Tarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_348053_value", "value", "", "", "")
        ),
        "Date"
      ),
      Fiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_731384_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_271174_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeDerece_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_997723_value", "value", "", "key", "")
        ),
        "string"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_184425_value", "value", "", "", "")
        ),
        "string"
      ),
      MusteriSiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_289156_value", "value", "", "", "")
        ),
        "string"
      ),
      UrunCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_949947_value", "value", "", "", "")
        ),
        "string"
      ),
      VarisUlke_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      VarisSehir_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      VarisIlce_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      MusteriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_711444_value",
            "value",
            "Get_Tanim_MusteriListe_FTL",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      YuklemeNokta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_458182_value",
            "value",
            "Get_Tanim_MusteriNoktaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      YuklemeUlke_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      YuklemeSehir_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      YuklemeIlce_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_281991_value",
            "value",
            "Get_Tanim_AracListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_937297_value",
            "value",
            "Get_Yonetim_FirmaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SurucuId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_830996_value",
            "value",
            "Get_Tanim_SurucuListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SiparisId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      NoktaId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_385152_value",
            "value",
            "Get_Tanim_MusteriNoktaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SiraNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_980405_value", "value", "", "", "")
        ),
        "number"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayFullTruck/SiparisDetayFullTruckComponent_528800_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Save_FullTruck = result?.data.siparis_Save_FullTruck;
    stateVars.Siparis_Add_FullTruck_VarisNokta = result?.data.siparis_Add_FullTruck_VarisNokta;
    stateVars.Siparis_Get_FullTruck_VarisNoktalar = result?.data.siparis_Get_FullTruck_VarisNoktalar;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckComponent_528800_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayFullTruckComponent_528800_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_82104 = this.state.Siparis_Get_FullTruck_VarisNoktalar;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisDetayFullTruckComponent_840194_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siparisdetayfulltruck_82104_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "18c54b0b_6e32_196b_3386_f864d857be4e_confirmation",
        this.defaultML,
        "Varış Noktası silinecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SiparisDetayFullTruck/SiparisDetayFullTruckComponent_840194_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Siparis_Delete_FullTruck_VarisNokta = result?.data.siparis_Delete_FullTruck_VarisNokta;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SiparisDetayFullTruckComponent_8606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9bec7ecf_c7c0_75cf_dc45_5def49f72aa6_confirmation",
        this.defaultML,
        "Kaydedilmemiş veriler iptal edilecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisDetayFullTruckComponent_205082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      SonSiparisNo_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      KdvDahil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_549657_value", "value", "", "", "")
        ),
        "boolean"
      ),
      FaturaOlussun_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_690238_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeslimatYapildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_846146_value", "value", "", "", "")
        ),
        "boolean"
      ),
      YuklemeYapildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_275883_value", "value", "", "", "")
        ),
        "boolean"
      ),
      YuklemeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_969512_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeslimatTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_95704_value", "value", "", "", "")
        ),
        "Date"
      ),
      Tarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_348053_value", "value", "", "", "")
        ),
        "Date"
      ),
      Fiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_731384_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_271174_value", "value", "", "", "")
        ),
        "string"
      ),
      NakliyeDerece_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_997723_value", "value", "", "key", "")
        ),
        "string"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_184425_value", "value", "", "", "")
        ),
        "string"
      ),
      MusteriSiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_289156_value", "value", "", "", "")
        ),
        "string"
      ),
      UrunCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisdetayfulltruck_949947_value", "value", "", "", "")
        ),
        "string"
      ),
      VarisUlke_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      VarisSehir_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      VarisIlce_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      MusteriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_711444_value",
            "value",
            "Get_Tanim_MusteriListe_FTL",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      YuklemeNokta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_458182_value",
            "value",
            "Get_Tanim_MusteriNoktaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      YuklemeUlke_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      YuklemeSehir_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      YuklemeIlce_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_281991_value",
            "value",
            "Get_Tanim_AracListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_937297_value",
            "value",
            "Get_Yonetim_FirmaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SurucuId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparisdetayfulltruck_830996_value",
            "value",
            "Get_Tanim_SurucuListe",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisDetayFullTruck/SiparisDetayFullTruckComponent_205082_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Save_FullTruck = result?.data.siparis_Save_FullTruck;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisDetayFullTruckComponent_205082_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisDetayFullTruckComponent_205082_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [691812] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SiparisDetayFullTruckPageInit();
    }
    if (diId == 691812) {
      isErrorOccurred = await this.SiparisDetayFullTruckComponent_405729_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
