import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYonetimFirmaKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYonetimFirmaKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_FirmaById: any[];
  Get_Yonetim_FirmaById_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  Add_Yonetim_FirmaListe: number;
  Add_Yonetim_FirmaListe_dummy: number;
  Update_Yonetim_Firma: number;
  Update_Yonetim_Firma_dummy: number;
  isComp65269Enabled: "enabled" | "disabled";
  isComp503860Enabled: "enabled" | "disabled";
}

export class YonetimFirmaKart_ScreenBase extends React.PureComponent<IYonetimFirmaKart_ScreenProps, any> {
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "290cbcfc-da31-4d78-9a53-e30472084f64",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 891214, PropertyName: "value", Value: "FİRMA EKLEME - DÜZENLEME" },
        { Id: 249608, PropertyName: "value", Value: "Firma No" },
        { Id: 503860, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 821217, PropertyName: "value", Value: "Firma Adı" },
        { Id: 65269, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 129735, PropertyName: "value", Value: "Aktif" },
        { Id: 974814, PropertyName: "label", Value: "Vazgeç" },
        { Id: 865072, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_FirmaById: [],
      ChangeEnabledOf: "",
      GoBack: "",
      Add_Yonetim_FirmaListe: 0,
      Update_Yonetim_Firma: 0,
      isComp65269Enabled: "enabled",
      isComp503860Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yonetimfirmakart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YonetimFirmaKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yonetimfirmakart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yonetimfirmakart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YonetimFirmaKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      yonetimfirmakart_503860_value: this.state.Get_Yonetim_FirmaById?.at?.(0)?.firmaNo ?? undefined,
      yonetimfirmakart_65269_value: this.state.Get_Yonetim_FirmaById?.at?.(0)?.firmaAdi ?? undefined,
      yonetimfirmakart_454062_value: this.state.Get_Yonetim_FirmaById?.at?.(0)?.aktif ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YonetimFirmaKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YonetimFirmaKart/YonetimFirmaKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimFirmaKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimFirmaKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "YonetimFirmaKart/YonetimFirmaKartPageInit1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Get_Yonetim_FirmaById = result?.data.get_Yonetim_FirmaById;
      formVars.yonetimfirmakart_503860_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Yonetim_FirmaById?.length > 0 ? stateVars.Get_Yonetim_FirmaById[0]?.firmaNo : null
      );
      formVars.yonetimfirmakart_65269_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Yonetim_FirmaById?.length > 0 ? stateVars.Get_Yonetim_FirmaById[0]?.firmaAdi : null
      );
      formVars.yonetimfirmakart_454062_value = ReactSystemFunctions.value(
        this,
        stateVars.Get_Yonetim_FirmaById?.length > 0 ? stateVars.Get_Yonetim_FirmaById[0]?.aktif : null
      );
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimFirmaKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp65269Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimFirmaKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp503860Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimFirmaKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimfirmakart_503860_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_FirmaById?.length > 0 ? this.state.Get_Yonetim_FirmaById[0]?.firmaNo : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimFirmaKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimfirmakart_65269_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Yonetim_FirmaById?.length > 0 ? this.state.Get_Yonetim_FirmaById[0]?.firmaAdi : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YonetimFirmaKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.yonetimfirmakart_454062_value = ReactSystemFunctions.value(
        this,
        this.state.Get_Yonetim_FirmaById?.length > 0 ? this.state.Get_Yonetim_FirmaById[0]?.aktif : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YonetimFirmaKartComponent_974814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "43fc490a_712c_ab21_16b0_3731dcc99abb_confirmation",
        this.defaultML,
        "Kayıt edilmeyen veriler iptal edilecek"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  YonetimFirmaKartComponent_865072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "yonetimfirmakart_503860_value", "value", "", "", "")
        ),
        "number"
      ),
      FirmaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yonetimfirmakart_65269_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YonetimFirmaKart/YonetimFirmaKartComponent_865072_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Add_Yonetim_FirmaListe = result?.data.add_Yonetim_FirmaListe;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartComponent_865072_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartComponent_865072_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimFirmaKartComponent_865072_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aktif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yonetimfirmakart_454062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.FirmaId ?? this.props.screenInputs.firmaid, null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YonetimFirmaKart/YonetimFirmaKartComponent_865072_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Update_Yonetim_Firma = result?.data.update_Yonetim_Firma;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartComponent_865072_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YonetimFirmaKartComponent_865072_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimFirmaKartComponent_865072_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
