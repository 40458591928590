import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParsiyelDatMalKabul_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParsiyelDatMalKabul_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Yonetim_DepoListe: any[];
  Get_Yonetim_DepoListe_dummy: any[];
  MalKabul_AracSecim: any[];
  MalKabul_AracSecim_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  SetFocusTo: any;
  SetFocusTo_dummy: any;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  MalKabul_Okutulanlar: any[];
  MalKabul_Okutulanlar_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  MalKabul_BarkodOkut: any[];
  MalKabul_BarkodOkut_dummy: any[];
  isComp185855Visible: "visible" | "hidden";
}

export class ParsiyelDatMalKabul_ScreenBase extends React.PureComponent<IParsiyelDatMalKabul_ScreenProps, any> {
  parsiyeldatmalkabul_213603_value_kuikaSelectBoxRef: React.RefObject<any>;
  parsiyeldatmalkabul_193255_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c7e890f6-1143-4c28-937d-6fadd00b9a2f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "DEPO TRANSFER MAL KABUL" },
        { Id: 275970, PropertyName: "value", Value: "Giriş Deposu" },
        { Id: 213603, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 258869, PropertyName: "value", Value: "Araç Plakası" },
        { Id: 500313, PropertyName: "placeholder", Value: "Araç plakasını okutun ..." },
        { Id: 462904, PropertyName: "value", Value: "Palet Barkodu" },
        { Id: 555597, PropertyName: "placeholder", Value: "Palet barkodunu okutun ..." },
        { Id: 410855, PropertyName: "label", Value: "Vazgeç" },
        { Id: 185855, PropertyName: "label", Value: "Ekle" },
        { Id: 193255, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 389021, PropertyName: "title", Value: "Barkod" },
        { Id: 272957, PropertyName: "value", Value: "[datafield:barkod]" },
        { Id: 72781, PropertyName: "title", Value: "Ürün Cinsi" },
        { Id: 696211, PropertyName: "value", Value: "[datafield:uruncinsi]" },
        { Id: 733547, PropertyName: "title", Value: "Çıkış Yeri" },
        { Id: 113319, PropertyName: "value", Value: "[datafield:cikisyeri]" },
        { Id: 18272, PropertyName: "title", Value: "Varış Yeri" },
        { Id: 53533, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 453204, PropertyName: "title", Value: "Toplam Palet Sayısı" },
        { Id: 339359, PropertyName: "value", Value: "[datafield:paletadedi]" },
        { Id: 732477, PropertyName: "title", Value: "Palet Tipi" },
        { Id: 432326, PropertyName: "value", Value: "[datafield:palettipi]" },
        { Id: 706659, PropertyName: "title", Value: "Derece" },
        { Id: 879049, PropertyName: "value", Value: "[datafield:nakliyederece]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.parsiyeldatmalkabul_213603_value_kuikaSelectBoxRef = React.createRef();
    this.parsiyeldatmalkabul_193255_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Yonetim_DepoListe: [],
      MalKabul_AracSecim: [],
      Notify: false,
      SetFocusTo: "",
      ToggleVisibilityOf: "",
      MalKabul_Okutulanlar: [],
      SetValueOf: "",
      MalKabul_BarkodOkut: [],
      isComp185855Visible: "hidden"
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parsiyeldatmalkabul", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ParsiyelDatMalKabulPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parsiyeldatmalkabul", "");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["SistemAdmin", "SuperUser", "Operasyon", "OperasyonLTL", "DepoLTL"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parsiyeldatmalkabul", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParsiyelDatMalKabulPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ParsiyelDatMalKabulPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParsiyelDatMalKabul/ParsiyelDatMalKabulPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Yonetim_DepoListe = result?.data.get_Yonetim_DepoListe;

    formVars.parsiyeldatmalkabul_213603_options = stateVars.Get_Yonetim_DepoListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelDatMalKabulPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelDatMalKabulPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_213603 = this.state.Get_Yonetim_DepoListe;
    stateVars.dataSource_213603 = this.state.Get_Yonetim_DepoListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParsiyelDatMalKabulComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelDatMalKabul",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelDatMalKabulComponent_500313_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      DepoId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "parsiyeldatmalkabul_213603_value",
            "value",
            "Get_Yonetim_DepoListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyeldatmalkabul_500313_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelDatMalKabul/ParsiyelDatMalKabulComponent_500313_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MalKabul_AracSecim = result?.data.malKabul_AracSecim;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_500313_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MalKabul_AracSecim?.length > 0 ? this.state.MalKabul_AracSecim[0]?.aractaMalVarMi : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "da6ce7b1_8d65_e1e9_2fc1_b23b5f2b2ec5_notify",
          this.defaultML,
          "Aracın bu depoya seferi kayıtlı değil"
        ),
        "danger",
        "bottom-right",
        0,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_500313_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.MalKabul_AracSecim?.length > 0
            ? this.state.MalKabul_AracSecim[this.state.MalKabul_AracSecim.length - 1].aractaMalVarMi
            : null
        ),
        "0"
      )
    ) {
      stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("555597");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_500313_onPressEnter3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.MalKabul_AracSecim?.length > 0 ? this.state.MalKabul_AracSecim[0]?.aractaMalVarMi : null
        ),
        "0"
      )
    ) {
      stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp185855Visible");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_500313_onPressEnter4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyeldatmalkabul_500313_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelDatMalKabul/ParsiyelDatMalKabulComponent_500313_onPressEnter4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MalKabul_Okutulanlar = result?.data.malKabul_Okutulanlar;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelDatMalKabulComponent_500313_onPressEnter5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_500313_onPressEnter5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.MalKabul_Okutulanlar;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelDatMalKabulComponent_410855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parsiyeldatmalkabul_555597_value", "", null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelDatMalKabulComponent_185855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyeldatmalkabul_500313_value", "value", "", "", "")
        ),
        "string"
      ),
      PaletBarkod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyeldatmalkabul_555597_value", "value", "", "", "")
        ),
        "string"
      ),
      VarisDepoId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "parsiyeldatmalkabul_213603_value",
            "value",
            "Get_Yonetim_DepoListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelDatMalKabul/ParsiyelDatMalKabulComponent_185855_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MalKabul_BarkodOkut = result?.data.malKabul_BarkodOkut;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_185855_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyeldatmalkabul_500313_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MalKabul_BarkodOkut?.length > 0 ? this.state.MalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParsiyelDatMalKabul/ParsiyelDatMalKabulComponent_185855_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.MalKabul_Okutulanlar = result?.data.malKabul_Okutulanlar;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_185855_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MalKabul_BarkodOkut?.length > 0 ? this.state.MalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parsiyeldatmalkabul_555597_value", "", null);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_185855_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("555597");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_185855_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MalKabul_BarkodOkut?.length > 0 ? this.state.MalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelDatMalKabulComponent_185855_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelDatMalKabulComponent_185855_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MalKabul_BarkodOkut?.length > 0 ? this.state.MalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      stateVars.dataSource_193255 = this.state.MalKabul_Okutulanlar;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ParsiyelDatMalKabulPageInit();
    }
  }
}
