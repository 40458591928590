import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRaporLTLMusteriIlDetayli_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRaporLTLMusteriIlDetayli_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  ReadFromSessionStorage: string;
  ReadFromSessionStorage_dummy: string;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  Get_Rapor_LTL_Musteri_Il: any[];
  Get_Rapor_LTL_Musteri_Il_dummy: any[];
  Get_Rapor_LTL_MusteriDetay: any[];
  Get_Rapor_LTL_MusteriDetay_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
}

export class RaporLTLMusteriIlDetayli_ScreenBase extends React.PureComponent<
  IRaporLTLMusteriIlDetayli_ScreenProps,
  any
> {
  raporltlmusteriildetayli_317091_value_kuikaSelectBoxRef: React.RefObject<any>;
  raporltlmusteriildetayli_946698_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "ec99fbe9-7a88-4e50-8302-fb85e8013e00",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 268581, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 761318, PropertyName: "value", Value: "MÜŞTERİ - İL BAZINDA PARSİYEL  RAPORU" },
        { Id: 317091, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 400539, PropertyName: "label", Value: "Yenile" },
        { Id: 135221, PropertyName: "label", Value: "Excele Aktar" },
        { Id: 946698, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 781525, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 627699, PropertyName: "value", Value: "[datafield:firmaadi]" },
        { Id: 213247, PropertyName: "title", Value: "Teslim İl" },
        { Id: 173570, PropertyName: "value", Value: "[datafield:teslimil]" },
        { Id: 167449, PropertyName: "title", Value: "Teslimat Ay" },
        { Id: 144755, PropertyName: "value", Value: "[datafield:teslimatayadi]" },
        { Id: 611298, PropertyName: "title", Value: "Teslimat Sayısı" },
        { Id: 113177, PropertyName: "value", Value: "[datafield:teslimatsayisi]" },
        { Id: 429683, PropertyName: "title", Value: "Palet Sayısı" },
        { Id: 746010, PropertyName: "value", Value: "[datafield:paletsayisi]" },
        { Id: 722440, PropertyName: "title", Value: "Toplam Tutar" },
        { Id: 311842, PropertyName: "value", Value: "[datafield:toplamfiyat]" },
        { Id: 23709, PropertyName: "title", Value: "Masraf" },
        { Id: 222869, PropertyName: "value", Value: "[datafield:masraftutar]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.raporltlmusteriildetayli_317091_value_kuikaSelectBoxRef = React.createRef();
    this.raporltlmusteriildetayli_946698_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      ReadFromSessionStorage: "",
      Get_Helpers_LoginUserInfo: [],
      SetValueOf: "",
      Get_Rapor_LTL_Musteri_Il: [],
      Get_Rapor_LTL_MusteriDetay: [],
      ExportExcel: "",
      dataSource_317091: [
        { key: "2023", text: "2023" },
        { key: "2024", text: "2024" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("raporltlmusteriildetayli", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RaporLTLMusteriIlDetayliPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("raporltlmusteriildetayli", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("raporltlmusteriildetayli", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RaporLTLMusteriIlDetayliPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  RaporLTLMusteriIlDetayliPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("FirmaNo");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriIlDetayliPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RaporLTLMusteriIlDetayliPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriIlDetayli/RaporLTLMusteriIlDetayliPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriIlDetayliPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporLTLMusteriIlDetayliPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "raporltlmusteriildetayli_317091_value",
      "2024",
      null,
      "Key"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriIlDetayliPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RaporLTLMusteriIlDetayliPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      TeslimatYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "raporltlmusteriildetayli_317091_value", "value", "", "key", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriIlDetayli/RaporLTLMusteriIlDetayliPageInit3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Rapor_LTL_Musteri_Il = result?.data.get_Rapor_LTL_Musteri_Il;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriIlDetayliPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporLTLMusteriIlDetayliPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_946698 = this.state.Get_Rapor_LTL_Musteri_Il;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RaporLTLMusteriIlDetayliComponent_208479_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "RaporLTLMusteriIlDetayli",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "413355",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporLTLMusteriIlDetayliComponent_400539_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      TeslimatYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "raporltlmusteriildetayli_317091_value", "value", "", "key", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriIlDetayli/RaporLTLMusteriIlDetayliComponent_400539_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Rapor_LTL_MusteriDetay = result?.data.get_Rapor_LTL_MusteriDetay;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  RaporLTLMusteriIlDetayliComponent_135221_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.Get_Rapor_LTL_Musteri_Il),
        "any"
      ),
      header_0: ReactSystemFunctions.convertToTypeByName("MÜŞTERİ - İL BAZINDA PARSİYEL RAPORU", "string"),
      footer_0: ReactSystemFunctions.convertToTypeByName("SU-Fİ Lojistik", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriIlDetayli/RaporLTLMusteriIlDetayliComponent_135221_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [413355] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RaporLTLMusteriIlDetayliPageInit();
    }
  }
}
