import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { YonetimIlceKart_Screen } from "../screens/yonetimilcekart/yonetimilcekart";
import { TanimAracTanim_Screen } from "../screens/tanimaractanim/tanimaractanim";
import { ArunaLoading_Screen } from "../screens/arunaloading/arunaloading";
import { YonetimSehirTanim_Screen } from "../screens/yonetimsehirtanim/yonetimsehirtanim";
import { ParsiyelMusteridenMalKabul_Screen } from "../screens/parsiyelmusteridenmalkabul/parsiyelmusteridenmalkabul";
import { YonetimSehirKart_Screen } from "../screens/yonetimsehirkart/yonetimsehirkart";
import { RaporFullTruckListe_Screen } from "../screens/raporfulltruckliste/raporfulltruckliste";
import { SiparisDetayParsiyel_Screen } from "../screens/siparisdetayparsiyel/siparisdetayparsiyel";
import { YuklemeListesi_Screen } from "../screens/yuklemelistesi/yuklemelistesi";
import { SiparisListesiParsiyel_Screen } from "../screens/siparislistesiparsiyel/siparislistesiparsiyel";
import { TanimSurucuTanim_Screen } from "../screens/tanimsurucutanim/tanimsurucutanim";
import { ParsiyelAracYukleme_Screen } from "../screens/parsiyelaracyukleme/parsiyelaracyukleme";
import { TanimMusteriNoktaTanim_Screen } from "../screens/tanimmusterinoktatanim/tanimmusterinoktatanim";
import { ComponentTest_Screen } from "../screens/componenttest/componenttest";
import { Signup_Screen } from "../screens/signup/signup";
import { SiparisSevkEmriDetay_Screen } from "../screens/siparissevkemridetay/siparissevkemridetay";
import { ParsiyelMusteriSevkListeDetay_Screen } from "../screens/parsiyelmusterisevklistedetay/parsiyelmusterisevklistedetay";
import { YonetimFirmaKart_Screen } from "../screens/yonetimfirmakart/yonetimfirmakart";
import { TanimMusteriTedarikciTanim_Screen } from "../screens/tanimmusteritedarikcitanim/tanimmusteritedarikcitanim";
import { YonetimKullaniciKart_Screen } from "../screens/yonetimkullanicikart/yonetimkullanicikart";
import { BarkodListesi_Screen } from "../screens/barkodlistesi/barkodlistesi";
import { YonetimUlkeTanim_Screen } from "../screens/yonetimulketanim/yonetimulketanim";
import { YonetimIlceTanim_Screen } from "../screens/yonetimilcetanim/yonetimilcetanim";
import { ParsiyelMusteriSevkListe_Screen } from "../screens/parsiyelmusterisevkliste/parsiyelmusterisevkliste";
import { SiparisDetayFullTruck_Screen } from "../screens/siparisdetayfulltruck/siparisdetayfulltruck";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { YonetimKullaniciTanim_Screen } from "../screens/yonetimkullanicitanim/yonetimkullanicitanim";
import { HomePage_Screen } from "../screens/homepage/homepage";
import { YonetimUlkeKart_Screen } from "../screens/yonetimulkekart/yonetimulkekart";
import { RaporLTLMusteriDetayli_Screen } from "../screens/raporltlmusteridetayli/raporltlmusteridetayli";
import { ParsiyelDatMalKabul_Screen } from "../screens/parsiyeldatmalkabul/parsiyeldatmalkabul";
import { SiparisListesiFullTruck_Screen } from "../screens/siparislistesifulltruck/siparislistesifulltruck";
import { YuklemeBekleyenListe_Screen } from "../screens/yuklemebekleyenliste/yuklemebekleyenliste";
import { TanimAracBilgiKart_Screen } from "../screens/tanimaracbilgikart/tanimaracbilgikart";
import { RaporLTLMusteriIlDetayli_Screen } from "../screens/raporltlmusteriildetayli/raporltlmusteriildetayli";
import { RaporFTLMusteriDetayli_Screen } from "../screens/raporftlmusteridetayli/raporftlmusteridetayli";
import { YonetimFirmaTanim_Screen } from "../screens/yonetimfirmatanim/yonetimfirmatanim";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { YonetimKullaniciEkle_Screen } from "../screens/yonetimkullaniciekle/yonetimkullaniciekle";
import { RaporParsiyelYukleme_Screen } from "../screens/raporparsiyelyukleme/raporparsiyelyukleme";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { YuklemeTeslimatBilgi_Screen } from "../screens/yuklemeteslimatbilgi/yuklemeteslimatbilgi";
import { TanimSurucuBilgiKart_Screen } from "../screens/tanimsurucubilgikart/tanimsurucubilgikart";
import { TanimMusteriTedarikciBilgiKart_Screen } from "../screens/tanimmusteritedarikcibilgikart/tanimmusteritedarikcibilgikart";
import { HomePagecopy_Screen } from "../screens/homepagecopy/homepagecopy";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { TanimMusteriNoktaBilgiKart_Screen } from "../screens/tanimmusterinoktabilgikart/tanimmusterinoktabilgikart";
import { ParsiyelMusteriSevk_Screen } from "../screens/parsiyelmusterisevk/parsiyelmusterisevk";
import { Signin_Screen } from "../screens/signin/signin";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={HomePage_Screen} />
                        <PrivateRoute exact path="/yonetimilcekart" component={YonetimIlceKart_Screen} />
            <Route exact path="/tanimaractanim" component={TanimAracTanim_Screen} />
            <Route exact path="/arunaloading" component={ArunaLoading_Screen} />
            <Route exact path="/yonetimsehirtanim" component={YonetimSehirTanim_Screen} />
            <PrivateRoute exact path="/parsiyelmusteridenmalkabul" component={ParsiyelMusteridenMalKabul_Screen} />
            <PrivateRoute exact path="/yonetimsehirkart" component={YonetimSehirKart_Screen} />
            <PrivateRoute exact path="/raporfulltruckliste" component={RaporFullTruckListe_Screen} />
            <PrivateRoute exact path="/siparisdetayparsiyel" component={SiparisDetayParsiyel_Screen} />
            <PrivateRoute exact path="/yuklemelistesi" component={YuklemeListesi_Screen} />
            <PrivateRoute exact path="/siparislistesiparsiyel" component={SiparisListesiParsiyel_Screen} />
            <Route exact path="/tanimsurucutanim" component={TanimSurucuTanim_Screen} />
            <PrivateRoute exact path="/parsiyelaracyukleme" component={ParsiyelAracYukleme_Screen} />
            <Route exact path="/tanimmusterinoktatanim" component={TanimMusteriNoktaTanim_Screen} />
            <PrivateRoute exact path="/componenttest" component={ComponentTest_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <PrivateRoute exact path="/siparissevkemridetay" component={SiparisSevkEmriDetay_Screen} />
            <Route exact path="/parsiyelmusterisevklistedetay" component={ParsiyelMusteriSevkListeDetay_Screen} />
            <PrivateRoute exact path="/yonetimfirmakart" component={YonetimFirmaKart_Screen} />
            <Route exact path="/tanimmusteritedarikcitanim" component={TanimMusteriTedarikciTanim_Screen} />
            <PrivateRoute exact path="/yonetimkullanicikart" component={YonetimKullaniciKart_Screen} />
            <PrivateRoute exact path="/barkodlistesi" component={BarkodListesi_Screen} />
            <Route exact path="/yonetimulketanim" component={YonetimUlkeTanim_Screen} />
            <PrivateRoute exact path="/yonetimilcetanim" component={YonetimIlceTanim_Screen} />
            <Route exact path="/parsiyelmusterisevkliste" component={ParsiyelMusteriSevkListe_Screen} />
            <PrivateRoute exact path="/siparisdetayfulltruck" component={SiparisDetayFullTruck_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <Route exact path="/yonetimkullanicitanim" component={YonetimKullaniciTanim_Screen} />
            <PrivateRoute exact path="/homepage" component={HomePage_Screen} />
            <PrivateRoute exact path="/yonetimulkekart" component={YonetimUlkeKart_Screen} />
            <PrivateRoute exact path="/raporltlmusteridetayli" component={RaporLTLMusteriDetayli_Screen} />
            <PrivateRoute exact path="/parsiyeldatmalkabul" component={ParsiyelDatMalKabul_Screen} />
            <PrivateRoute exact path="/siparislistesifulltruck" component={SiparisListesiFullTruck_Screen} />
            <PrivateRoute exact path="/yuklemebekleyenliste" component={YuklemeBekleyenListe_Screen} />
            <PrivateRoute exact path="/tanimaracbilgikart" component={TanimAracBilgiKart_Screen} />
            <PrivateRoute exact path="/raporltlmusteriildetayli" component={RaporLTLMusteriIlDetayli_Screen} />
            <PrivateRoute exact path="/raporftlmusteridetayli" component={RaporFTLMusteriDetayli_Screen} />
            <Route exact path="/yonetimfirmatanim" component={YonetimFirmaTanim_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/yonetimkullaniciekle" component={YonetimKullaniciEkle_Screen} />
            <PrivateRoute exact path="/raporparsiyelyukleme" component={RaporParsiyelYukleme_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/yuklemeteslimatbilgi" component={YuklemeTeslimatBilgi_Screen} />
            <PrivateRoute exact path="/tanimsurucubilgikart" component={TanimSurucuBilgiKart_Screen} />
            <PrivateRoute exact path="/tanimmusteritedarikcibilgikart" component={TanimMusteriTedarikciBilgiKart_Screen} />
            <PrivateRoute exact path="/homepagecopy" component={HomePagecopy_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/tanimmusterinoktabilgikart" component={TanimMusteriNoktaBilgiKart_Screen} />
            <PrivateRoute exact path="/parsiyelmusterisevk" component={ParsiyelMusteriSevk_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
