import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParsiyelAracYukleme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParsiyelAracYukleme_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  AracYukle_Okutulanlar: any[];
  AracYukle_Okutulanlar_dummy: any[];
  SetFocusTo: any;
  SetFocusTo_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  AracYukle_KayitEkle: any[];
  AracYukle_KayitEkle_dummy: any[];
  AracYukle_KayitSil: number;
  AracYukle_KayitSil_dummy: number;
  isCompparsiyelaracyukleme_623195AuthorizationVisible: "visible" | "hidden";
}

export class ParsiyelAracYukleme_ScreenBase extends React.PureComponent<IParsiyelAracYukleme_ScreenProps, any> {
  parsiyelaracyukleme_193255_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "2e103038-0ade-4fba-bb74-9e570333a392",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "PARSİYEL ARAÇ YÜKLEME" },
        { Id: 275970, PropertyName: "value", Value: "Araç Plakası" },
        { Id: 96705, PropertyName: "placeholder", Value: "Araç plakasını okutun ..." },
        { Id: 462904, PropertyName: "value", Value: "Palet Barkodu" },
        { Id: 555597, PropertyName: "placeholder", Value: "Palet barkodunu okutun ..." },
        { Id: 410855, PropertyName: "label", Value: "Vazgeç" },
        { Id: 185855, PropertyName: "label", Value: "Ekle" },
        { Id: 193255, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 342782, PropertyName: "title", Value: "İşlemler" },
        { Id: 389021, PropertyName: "title", Value: "Barkod" },
        { Id: 272957, PropertyName: "value", Value: "[datafield:barkod]" },
        { Id: 72781, PropertyName: "title", Value: "Ürün Cinsi" },
        { Id: 696211, PropertyName: "value", Value: "[datafield:uruncinsi]" },
        { Id: 733547, PropertyName: "title", Value: "Çıkış Yeri" },
        { Id: 113319, PropertyName: "value", Value: "[datafield:cikisyeri]" },
        { Id: 18272, PropertyName: "title", Value: "Varış Yeri" },
        { Id: 53533, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 453204, PropertyName: "title", Value: "Toplam Palet Sayısı" },
        { Id: 339359, PropertyName: "value", Value: "[datafield:paletadedi]" },
        { Id: 732477, PropertyName: "title", Value: "Palet Tipi" },
        { Id: 432326, PropertyName: "value", Value: "[datafield:palettipi]" },
        { Id: 706659, PropertyName: "title", Value: "Derece" },
        { Id: 879049, PropertyName: "value", Value: "[datafield:nakliyederece]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.parsiyelaracyukleme_193255_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      AracYukle_Okutulanlar: [],
      SetFocusTo: "",
      SetValueOf: "",
      AracYukle_KayitEkle: [],
      AracYukle_KayitSil: 0,
      isCompparsiyelaracyukleme_623195AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parsiyelaracyukleme", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ParsiyelAracYuklemePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompparsiyelaracyukleme_623195AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("parsiyelaracyukleme", "");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["SistemAdmin", "SuperUser", "Operasyon", "OperasyonLTL", "DepoLTL"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parsiyelaracyukleme", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParsiyelAracYuklemePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ParsiyelAracYuklemePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Plaka_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_96705_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParsiyelAracYukleme/ParsiyelAracYuklemePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.AracYukle_Okutulanlar = result?.data.aracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelAracYuklemePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelAracYuklemePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.AracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParsiyelAracYuklemeComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelAracYukleme",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelAracYuklemeComponent_96705_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("555597");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelAracYuklemeComponent_96705_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_96705_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelAracYukleme/ParsiyelAracYuklemeComponent_96705_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AracYukle_Okutulanlar = result?.data.aracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelAracYuklemeComponent_96705_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelAracYuklemeComponent_96705_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.AracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelAracYuklemeComponent_410855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parsiyelaracyukleme_96705_value", "", null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parsiyelaracyukleme_555597_value", "", null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelAracYuklemeComponent_410855_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ParsiyelAracYuklemeComponent_410855_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_96705_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelAracYukleme/ParsiyelAracYuklemeComponent_410855_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AracYukle_Okutulanlar = result?.data.aracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelAracYuklemeComponent_410855_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelAracYuklemeComponent_410855_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.AracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelAracYuklemeComponent_185855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BekleyenPaletSayisi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_96705_value", "value", "", "", "")
        ),
        "string"
      ),
      PaletAracDogruMu_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      PaletBarkod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_555597_value", "value", "", "", "")
        ),
        "string"
      ),
      BarkodId_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      AracId_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      YuklemeEmriId_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_555597_value", "value", "", "", "")
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParsiyelAracYukleme/ParsiyelAracYuklemeComponent_185855_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AracYukle_KayitEkle = result?.data.aracYukle_KayitEkle;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelAracYuklemeComponent_185855_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelAracYuklemeComponent_185855_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelAracYuklemeComponent_185855_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_96705_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelAracYukleme/ParsiyelAracYuklemeComponent_185855_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AracYukle_Okutulanlar = result?.data.aracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelAracYuklemeComponent_185855_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelAracYuklemeComponent_185855_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.AracYukle_KayitEkle?.length > 0 ? this.state.AracYukle_KayitEkle[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parsiyelaracyukleme_555597_value", "", null);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelAracYuklemeComponent_185855_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelAracYuklemeComponent_185855_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelAracYuklemeComponent_185855_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("555597");

    stateVars.dataSource_193255 = this.state.AracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelAracYuklemeComponent_623195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "parsiyelaracyukleme_193255_value", "yuklemeId"),
        "Guid"
      ),
      Plaka_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelaracyukleme_96705_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3c545ed9_c28b_59db_7d8c_119a6ffe00f2_confirmation",
        this.defaultML,
        "Seçilen palet araçtan silinecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParsiyelAracYukleme/ParsiyelAracYuklemeComponent_623195_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AracYukle_KayitSil = result?.data.aracYukle_KayitSil;
      stateVars.AracYukle_Okutulanlar = result?.data.aracYukle_Okutulanlar;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelAracYuklemeComponent_623195_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelAracYuklemeComponent_623195_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.AracYukle_Okutulanlar;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ParsiyelAracYuklemePageInit();
    }
  }
}
