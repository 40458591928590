import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRaporLTLMusteriDetayli_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRaporLTLMusteriDetayli_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  ReadFromSessionStorage: string;
  ReadFromSessionStorage_dummy: string;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  Get_Rapor_LTL_MusteriDetay: any[];
  Get_Rapor_LTL_MusteriDetay_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
}

export class RaporLTLMusteriDetayli_ScreenBase extends React.PureComponent<IRaporLTLMusteriDetayli_ScreenProps, any> {
  raporltlmusteridetayli_317091_value_kuikaSelectBoxRef: React.RefObject<any>;
  raporltlmusteridetayli_946698_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "a408aca7-b134-42ac-8bec-1255bc8dfa7f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 268581, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 761318, PropertyName: "value", Value: "MÜŞTERİ BAZINDA PARSİYEL  RAPORU" },
        { Id: 317091, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 400539, PropertyName: "label", Value: "Yenile" },
        { Id: 135221, PropertyName: "label", Value: "Excele Aktar" },
        { Id: 946698, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 781525, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 627699, PropertyName: "value", Value: "[datafield:firmaadi]" },
        { Id: 167449, PropertyName: "title", Value: "Teslimat Ay" },
        { Id: 144755, PropertyName: "value", Value: "[datafield:teslimatayadi]" },
        { Id: 611298, PropertyName: "title", Value: "Teslimat Sayısı" },
        { Id: 113177, PropertyName: "value", Value: "[datafield:teslimatsayisi]" },
        { Id: 429683, PropertyName: "title", Value: "Palet Sayısı" },
        { Id: 746010, PropertyName: "value", Value: "[datafield:paletsayisi]" },
        { Id: 722440, PropertyName: "title", Value: "Toplam Tutar" },
        { Id: 311842, PropertyName: "value", Value: "[datafield:toplamfiyat]" },
        { Id: 23709, PropertyName: "title", Value: "Masraf" },
        { Id: 222869, PropertyName: "value", Value: "[datafield:masraftutar]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.raporltlmusteridetayli_317091_value_kuikaSelectBoxRef = React.createRef();
    this.raporltlmusteridetayli_946698_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      ReadFromSessionStorage: "",
      Get_Helpers_LoginUserInfo: [],
      SetValueOf: "",
      Get_Rapor_LTL_MusteriDetay: [],
      ExportExcel: "",
      dataSource_317091: [
        { key: "2023", text: "2023" },
        { key: "2024", text: "2024" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("raporltlmusteridetayli", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RaporLTLMusteriDetayliPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("raporltlmusteridetayli", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("raporltlmusteridetayli", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RaporLTLMusteriDetayliPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  RaporLTLMusteriDetayliPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("FirmaNo");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriDetayliPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RaporLTLMusteriDetayliPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriDetayli/RaporLTLMusteriDetayliPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriDetayliPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporLTLMusteriDetayliPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "raporltlmusteridetayli_317091_value",
      "2024",
      null,
      "Key"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriDetayliPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RaporLTLMusteriDetayliPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      TeslimatYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "raporltlmusteridetayli_317091_value", "value", "", "key", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriDetayli/RaporLTLMusteriDetayliPageInit3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Rapor_LTL_MusteriDetay = result?.data.get_Rapor_LTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriDetayliPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporLTLMusteriDetayliPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_946698 = this.state.Get_Rapor_LTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RaporLTLMusteriDetayliComponent_208479_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "RaporLTLMusteriDetayli",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "858703",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporLTLMusteriDetayliComponent_400539_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      ),
      TeslimatYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "raporltlmusteridetayli_317091_value", "value", "", "key", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriDetayli/RaporLTLMusteriDetayliComponent_400539_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Rapor_LTL_MusteriDetay = result?.data.get_Rapor_LTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporLTLMusteriDetayliComponent_400539_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporLTLMusteriDetayliComponent_400539_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_946698 = this.state.Get_Rapor_LTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporLTLMusteriDetayliComponent_135221_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.Get_Rapor_LTL_MusteriDetay),
        "any"
      ),
      header_0: ReactSystemFunctions.convertToTypeByName("MÜŞTERİ BAZINDA PARSİYEL RAPORU", "string"),
      footer_0: ReactSystemFunctions.convertToTypeByName("SU-Fİ Lojistik", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporLTLMusteriDetayli/RaporLTLMusteriDetayliComponent_135221_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [858703] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RaporLTLMusteriDetayliPageInit();
    }
  }
}
