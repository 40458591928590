import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYuklemeTeslimatBilgi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYuklemeTeslimatBilgi_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Tanim_AracListe: any[];
  Get_Tanim_AracListe_dummy: any[];
  Get_Tanim_SurucuListe: any[];
  Get_Tanim_SurucuListe_dummy: any[];
  Get_Tanim_MusteriNoktaListe: any[];
  Get_Tanim_MusteriNoktaListe_dummy: any[];
  Get_Tanim_MusteriNoktaListe_Target: any[];
  Get_Tanim_MusteriNoktaListe_Target_dummy: any[];
  Siparis_Get_SevkEmriDetay: any[];
  Siparis_Get_SevkEmriDetay_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  UploadFile: any;
  UploadFile_dummy: any;
  Siparis_Add_ImageToSevkEmriDetay: number;
  Siparis_Add_ImageToSevkEmriDetay_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  Yukleme_TeslimatGuncelle: number;
  Yukleme_TeslimatGuncelle_dummy: number;
  isComp68145Visible: "visible" | "hidden";
  isComp148235Visible: "visible" | "hidden";
  isComp487428Visible: "visible" | "hidden";
  isComp206755Enabled: "enabled" | "disabled";
  isComp244827Enabled: "enabled" | "disabled";
  isComp243178Enabled: "enabled" | "disabled";
  isComp281991Enabled: "enabled" | "disabled";
  isComp830996Enabled: "enabled" | "disabled";
  isComp949947Enabled: "enabled" | "disabled";
  isComp249847Enabled: "enabled" | "disabled";
  isComp158907Enabled: "enabled" | "disabled";
}

export class YuklemeTeslimatBilgi_ScreenBase extends React.PureComponent<IYuklemeTeslimatBilgi_ScreenProps, any> {
  yuklemeteslimatbilgi_206755_value_kuikaAutoCompleteRef: React.RefObject<any>;
  yuklemeteslimatbilgi_244827_value_kuikaAutoCompleteRef: React.RefObject<any>;
  yuklemeteslimatbilgi_281991_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemeteslimatbilgi_830996_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemeteslimatbilgi_249847_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemeteslimatbilgi_158907_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemeteslimatbilgi_95704_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "37eda0b1-0ee4-42c2-91b2-264474d43e43",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 65302, PropertyName: "value", Value: "YÜKLEME - VARIŞ ADRESLERİ" },
        { Id: 541626, PropertyName: "value", Value: "Yükleme Noktası" },
        { Id: 206755, PropertyName: "placeholder", Value: "Search..." },
        { Id: 682918, PropertyName: "value", Value: "Varış Noktası" },
        { Id: 244827, PropertyName: "placeholder", Value: "Search..." },
        { Id: 265509, PropertyName: "value", Value: "Ön  Toplama Yapılacak" },
        { Id: 5207, PropertyName: "value", Value: "ARAÇ BİLGİLERİ" },
        { Id: 501297, PropertyName: "value", Value: "Araç Plaka" },
        { Id: 281991, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 489227, PropertyName: "value", Value: "Sürücü" },
        { Id: 830996, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 853831, PropertyName: "value", Value: "ÜRÜN - FİYAT BİLGİLERİ" },
        { Id: 99168, PropertyName: "value", Value: "Ürün Cinsi" },
        { Id: 949947, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 439568, PropertyName: "value", Value: "Palet Adedi" },
        { Id: 899952, PropertyName: "placeholder", Value: "0" },
        { Id: 653895, PropertyName: "value", Value: "Palet Tipi" },
        { Id: 249847, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 307859, PropertyName: "value", Value: "Derece" },
        { Id: 158907, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 262440, PropertyName: "value", Value: "TESLİMAT BİLGİSİ" },
        { Id: 854474, PropertyName: "value", Value: "İrsaliye Numarası" },
        { Id: 694695, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 673219, PropertyName: "label", Value: "İrsaliye Resmi Yükle" },
        { Id: 668368, PropertyName: "value", Value: "Teslim Edildi" },
        { Id: 307408, PropertyName: "value", Value: "Teslimat Tarihi" },
        { Id: 95704, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 8606, PropertyName: "label", Value: "Vazgeç" },
        { Id: 205082, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.yuklemeteslimatbilgi_206755_value_kuikaAutoCompleteRef = React.createRef();
    this.yuklemeteslimatbilgi_244827_value_kuikaAutoCompleteRef = React.createRef();
    this.yuklemeteslimatbilgi_281991_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemeteslimatbilgi_830996_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemeteslimatbilgi_249847_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemeteslimatbilgi_158907_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemeteslimatbilgi_95704_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_UserInfoByUserName: [],
      Get_Tanim_AracListe: [],
      Get_Tanim_SurucuListe: [],
      Get_Tanim_MusteriNoktaListe: [],
      Get_Tanim_MusteriNoktaListe_Target: [],
      Siparis_Get_SevkEmriDetay: [],
      ChangeEnabledOf: "",
      ChangeVisibilityOf: "",
      UploadFile: "",
      Siparis_Add_ImageToSevkEmriDetay: 0,
      GoBack: "",
      Notify: false,
      Yukleme_TeslimatGuncelle: 0,
      dataSource_249847: [
        { key: "80x120", text: "80x120" },
        { key: "100x120", text: "100x120" }
      ],
      dataSource_158907: [
        { key: "-18", text: "-18" },
        { key: "+4", text: "+4" },
        { key: "+18", text: "+18" }
      ],
      isComp68145Visible: "visible",
      isComp148235Visible: "visible",
      isComp487428Visible: "hidden",
      isComp206755Enabled: "enabled",
      isComp244827Enabled: "enabled",
      isComp243178Enabled: "enabled",
      isComp281991Enabled: "enabled",
      isComp830996Enabled: "enabled",
      isComp949947Enabled: "enabled",
      isComp249847Enabled: "enabled",
      isComp158907Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yuklemeteslimatbilgi", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YuklemeTeslimatBilgiPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yuklemeteslimatbilgi", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yuklemeteslimatbilgi", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YuklemeTeslimatBilgiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      yuklemeteslimatbilgi_206755_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.yuklemeNokta ?? undefined,
      yuklemeteslimatbilgi_244827_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.varisNokta ?? undefined,
      yuklemeteslimatbilgi_243178_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.onToplamaYapilacak ?? undefined,
      yuklemeteslimatbilgi_281991_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.aracId ?? undefined,
      yuklemeteslimatbilgi_830996_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.surucuId ?? undefined,
      yuklemeteslimatbilgi_949947_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.urunCinsi ?? undefined,
      yuklemeteslimatbilgi_899952_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.paletAdedi ?? undefined,
      yuklemeteslimatbilgi_249847_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.paletTipi ?? undefined,
      yuklemeteslimatbilgi_158907_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.nakliyeDerece ?? undefined,
      yuklemeteslimatbilgi_694695_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.irsaliyeNo ?? undefined,
      yuklemeteslimatbilgi_358699_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.irsaliyeResim ?? undefined,
      yuklemeteslimatbilgi_846146_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.teslimAlindi ?? undefined,
      yuklemeteslimatbilgi_95704_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.teslimAlmaTarihi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YuklemeTeslimatBilgiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MusteriId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MusteriId ?? this.props.screenInputs.musteriid,
        "Guid"
      ),
      MusteriId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MusteriId ?? this.props.screenInputs.musteriid,
        "Guid"
      ),
      SiparisId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      SevkEmriId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkEmriId ?? this.props.screenInputs.sevkemriid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YuklemeTeslimatBilgi/YuklemeTeslimatBilgiPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Tanim_AracListe = result?.data.get_Tanim_AracListe;

    formVars.yuklemeteslimatbilgi_281991_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.aracId : null;
    formVars.yuklemeteslimatbilgi_281991_options = stateVars.Get_Tanim_AracListe;
    stateVars.Get_Tanim_SurucuListe = result?.data.get_Tanim_SurucuListe;

    formVars.yuklemeteslimatbilgi_830996_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.surucuId : null;
    formVars.yuklemeteslimatbilgi_830996_options = stateVars.Get_Tanim_SurucuListe;
    stateVars.Get_Tanim_MusteriNoktaListe = result?.data.get_Tanim_MusteriNoktaListe;

    formVars.yuklemeteslimatbilgi_206755_options = stateVars.Get_Tanim_MusteriNoktaListe;
    formVars.yuklemeteslimatbilgi_206755_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.yuklemeNokta : null;
    stateVars.Get_Tanim_MusteriNoktaListe_Target = result?.data.get_Tanim_MusteriNoktaListe_Target;

    formVars.yuklemeteslimatbilgi_244827_options = stateVars.Get_Tanim_MusteriNoktaListe_Target;
    formVars.yuklemeteslimatbilgi_244827_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.varisNokta : null;
    stateVars.Siparis_Get_SevkEmriDetay = result?.data.siparis_Get_SevkEmriDetay;
    formVars.yuklemeteslimatbilgi_206755_options = stateVars.Get_Tanim_MusteriNoktaListe;
    formVars.yuklemeteslimatbilgi_206755_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.yuklemeNokta : null;
    formVars.yuklemeteslimatbilgi_244827_options = stateVars.Get_Tanim_MusteriNoktaListe_Target;
    formVars.yuklemeteslimatbilgi_244827_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.varisNokta : null;
    formVars.yuklemeteslimatbilgi_243178_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0
        ? stateVars.Siparis_Get_SevkEmriDetay[0]?.onToplamaYapilacak
        : null
    );
    formVars.yuklemeteslimatbilgi_281991_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.aracId : null;
    formVars.yuklemeteslimatbilgi_281991_options = stateVars.Get_Tanim_AracListe;
    formVars.yuklemeteslimatbilgi_830996_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.surucuId : null;
    formVars.yuklemeteslimatbilgi_830996_options = stateVars.Get_Tanim_SurucuListe;
    formVars.yuklemeteslimatbilgi_949947_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.urunCinsi : null
    );
    formVars.yuklemeteslimatbilgi_899952_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.paletAdedi : null
    );
    formVars.yuklemeteslimatbilgi_249847_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.paletTipi : null;
    formVars.yuklemeteslimatbilgi_158907_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.nakliyeDerece : null;
    formVars.yuklemeteslimatbilgi_694695_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeNo : null
    );
    formVars.yuklemeteslimatbilgi_163143_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi : null
    );
    formVars.yuklemeteslimatbilgi_358699_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResim : null
    );
    formVars.yuklemeteslimatbilgi_846146_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.teslimAlindi : null
    );
    formVars.yuklemeteslimatbilgi_95704_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.teslimAlmaTarihi : null
    );
    stateVars.isComp487428Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.Siparis_Get_SevkEmriDetay?.length > 0
            ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeTeslimatBilgiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp206755Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp244827Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp243178Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp281991Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp830996Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp949947Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp249847Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp158907Enabled", "disabled");

    formVars.yuklemeteslimatbilgi_206755_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.yuklemeNokta : null
    );

    stateVars.dataSource_206755 = this.state.Get_Tanim_MusteriNoktaListe;
    stateVars.dataSource_206755 = this.state.Get_Tanim_MusteriNoktaListe;

    formVars.yuklemeteslimatbilgi_244827_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.varisNokta : null
    );

    stateVars.dataSource_244827 = this.state.Get_Tanim_MusteriNoktaListe_Target;
    stateVars.dataSource_244827 = this.state.Get_Tanim_MusteriNoktaListe_Target;
    formVars.yuklemeteslimatbilgi_243178_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.onToplamaYapilacak
        : null
    );

    formVars.yuklemeteslimatbilgi_281991_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.aracId : null
    );

    stateVars.dataSource_281991 = this.state.Get_Tanim_AracListe;
    stateVars.dataSource_281991 = this.state.Get_Tanim_AracListe;
    formVars.yuklemeteslimatbilgi_830996_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.surucuId : null
    );

    stateVars.dataSource_830996 = this.state.Get_Tanim_SurucuListe;
    stateVars.dataSource_830996 = this.state.Get_Tanim_SurucuListe;
    formVars.yuklemeteslimatbilgi_949947_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.urunCinsi : null
    );

    formVars.yuklemeteslimatbilgi_899952_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.paletAdedi : null
    );

    formVars.yuklemeteslimatbilgi_249847_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.paletTipi : null
    );

    formVars.yuklemeteslimatbilgi_158907_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.nakliyeDerece : null
    );

    formVars.yuklemeteslimatbilgi_694695_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeNo : null
    );

    formVars.yuklemeteslimatbilgi_163143_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi
        : null
    );

    stateVars.isComp487428Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.Siparis_Get_SevkEmriDetay?.length > 0
            ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.yuklemeteslimatbilgi_358699_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResim : null
    );

    formVars.yuklemeteslimatbilgi_846146_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.teslimAlindi : null
    );

    formVars.yuklemeteslimatbilgi_95704_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.teslimAlmaTarihi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YuklemeTeslimatBilgiComponent_243178_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_243178_value", "value", "", "", "")
        ),
        false
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp68145Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_243178_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_243178_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_243178_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_243178_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp148235Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_243178_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_243178_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_243178_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_243178_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp68145Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_243178_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_243178_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_243178_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_243178_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp148235Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  YuklemeTeslimatBilgiComponent_673219_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_673219_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_673219_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IrsaliyeResim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      ResimAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkEmriId ?? this.props.screenInputs.sevkemriid,
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      SevkEmriId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkEmriId ?? this.props.screenInputs.sevkemriid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeTeslimatBilgi/YuklemeTeslimatBilgiComponent_673219_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Add_ImageToSevkEmriDetay = result?.data.siparis_Add_ImageToSevkEmriDetay;
    stateVars.Siparis_Get_SevkEmriDetay = result?.data.siparis_Get_SevkEmriDetay;
    formVars.yuklemeteslimatbilgi_206755_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.yuklemeNokta : null;
    formVars.yuklemeteslimatbilgi_244827_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.varisNokta : null;
    formVars.yuklemeteslimatbilgi_243178_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0
        ? stateVars.Siparis_Get_SevkEmriDetay[0]?.onToplamaYapilacak
        : null
    );
    formVars.yuklemeteslimatbilgi_281991_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.aracId : null;
    formVars.yuklemeteslimatbilgi_830996_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.surucuId : null;
    formVars.yuklemeteslimatbilgi_949947_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.urunCinsi : null
    );
    formVars.yuklemeteslimatbilgi_899952_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.paletAdedi : null
    );
    formVars.yuklemeteslimatbilgi_249847_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.paletTipi : null;
    formVars.yuklemeteslimatbilgi_158907_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.nakliyeDerece : null;
    formVars.yuklemeteslimatbilgi_694695_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeNo : null
    );
    formVars.yuklemeteslimatbilgi_163143_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi : null
    );
    formVars.yuklemeteslimatbilgi_358699_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResim : null
    );
    formVars.yuklemeteslimatbilgi_846146_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.teslimAlindi : null
    );
    formVars.yuklemeteslimatbilgi_95704_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.teslimAlmaTarihi : null
    );
    stateVars.isComp487428Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.Siparis_Get_SevkEmriDetay?.length > 0
            ? stateVars.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_673219_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_673219_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yuklemeteslimatbilgi_206755_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.yuklemeNokta : null
    );

    formVars.yuklemeteslimatbilgi_244827_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.varisNokta : null
    );

    formVars.yuklemeteslimatbilgi_243178_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.onToplamaYapilacak
        : null
    );

    formVars.yuklemeteslimatbilgi_281991_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.aracId : null
    );

    formVars.yuklemeteslimatbilgi_830996_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.surucuId : null
    );

    formVars.yuklemeteslimatbilgi_949947_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.urunCinsi : null
    );

    formVars.yuklemeteslimatbilgi_899952_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.paletAdedi : null
    );

    formVars.yuklemeteslimatbilgi_249847_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.paletTipi : null
    );

    formVars.yuklemeteslimatbilgi_158907_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.nakliyeDerece : null
    );

    formVars.yuklemeteslimatbilgi_694695_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeNo : null
    );

    formVars.yuklemeteslimatbilgi_163143_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi
        : null
    );

    formVars.yuklemeteslimatbilgi_358699_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResim : null
    );

    formVars.yuklemeteslimatbilgi_846146_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.teslimAlindi : null
    );

    formVars.yuklemeteslimatbilgi_95704_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.teslimAlmaTarihi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeTeslimatBilgiComponent_8606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "72f0572a_c5d2_701e_3737_33e0e1b939a1_confirmation",
        this.defaultML,
        "Kaydedilmemiş veriler iptal edilecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  YuklemeTeslimatBilgiComponent_205082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_694695_value", "value", "", "", "")
        ),
        ""
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "c8d46359_fca6_ed2c_3480_df6a03bb999d_notify",
          this.defaultML,
          "Teslimatı yapılan işin irsaliye numarası girilmelidir !"
        ),
        "danger",
        "top-center",
        0,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_205082_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_205082_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_205082_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.Siparis_Get_SevkEmriDetay?.length > 0
            ? this.state.Siparis_Get_SevkEmriDetay[0]?.irsaliyeResimAdi
            : null
        ),
        null
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "f996ccab_7685_df0c_8823_20afe63f8f66_notify",
          this.defaultML,
          "Teslimatı yapılan işin irsaliye resmi yüklenmelidir !"
        ),
        "danger",
        "top-center",
        0,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_205082_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_205082_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_205082_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeslimAlindi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_846146_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeslimAlmaTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_95704_value", "value", "", "", "")
        ),
        "Date"
      ),
      IrsaliyeNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_694695_value", "value", "", "", "")
        ),
        "string"
      ),
      PaletAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_899952_value", "value", "", "", "")
        ),
        "number"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkEmriId ?? this.props.screenInputs.sevkemriid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemeteslimatbilgi_694695_value", "value", "", "", "")
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YuklemeTeslimatBilgi/YuklemeTeslimatBilgiComponent_205082_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Yukleme_TeslimatGuncelle = result?.data.yukleme_TeslimatGuncelle;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_205082_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeTeslimatBilgiComponent_205082_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeTeslimatBilgiComponent_205082_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
