import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  KCol,
  KDatePicker,
  KDrawer,
  KModal,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  NumberInput,
  Switch,
  TextArea,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SiparisDetayParsiyel_ScreenBase } from "./siparisdetayparsiyel-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { SiparisSevkEmriDetay_Screen } from "../siparissevkemridetay/siparissevkemridetay";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class SiparisDetayParsiyel_Screen extends SiparisDetayParsiyel_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              siparisdetayparsiyel_184425_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.siparisNo ?? undefined,
              siparisdetayparsiyel_289156_value:
                this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.musteriSiparisNo ?? undefined,
              siparisdetayparsiyel_348053_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.tarih ?? undefined,
              siparisdetayparsiyel_906945_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.musteriId ?? undefined,
              siparisdetayparsiyel_271174_value: this.state.Siparis_Get_Parsiyel_ById?.at?.(0)?.aciklama ?? undefined,
              siparisdetayparsiyel_118776_value: undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="siparisdetayparsiyel_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KRow
                  id="152165"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="355636"
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="22475"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="258771"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SiparisDetayParsiyelComponent_258771_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "20px",
                            color: "rgba(149, 36, 29, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <Label
                        id="984146"
                        value={ReactSystemFunctions.translate(this.ml, 984146, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "18px",
                            color: "rgba(43, 42, 76, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="7325"
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="261518"
                      value={ReactSystemFunctions.getMyUsername()}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(140, 140, 140, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="siparisdetayparsiyel_body"
                style={
                  {
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="303355"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="466204"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KPanel
                      id="842011"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          maxWidth: "800px",
                          minWidth: "360px",
                          textAlign: "-webkit-center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="730851"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomWidth: 1,
                            borderColor: "rgba(217, 217, 217, 1)",
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="451897"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="162294"
                            value={ReactSystemFunctions.translate(this.ml, 162294, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>
                      </KRow>

                      <KRow
                        id="941845"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="11542"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="310475"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="136597"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="77061"
                                direction="vertical"
                                size={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="59869"
                                  value={ReactSystemFunctions.translate(this.ml, 59869, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="siparisdetayparsiyel_184425_value">
                                  <TextInput
                                    id="184425"
                                    editability={this.state.isComp184425Enabled}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      184425,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="636174"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="332931"
                                direction="vertical"
                                size={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="814991"
                                  value={ReactSystemFunctions.translate(this.ml, 814991, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="siparisdetayparsiyel_289156_value">
                                  <TextInput
                                    id="289156"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      289156,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </VerticalStack>
                            </KCol>
                          </KRow>

                          <KRow
                            id="685631"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="558937"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="77465"
                                direction="vertical"
                                size={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="943220"
                                  value={ReactSystemFunctions.translate(this.ml, 943220, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="siparisdetayparsiyel_348053_value">
                                  <KDatePicker
                                    id="348053"
                                    kuikaRef={this.siparisdetayparsiyel_348053_value_kuikaDateRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      348053,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    format="DD/MM/YYYY"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></KDatePicker>
                                </Form.Item>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="560920"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="334661"
                                direction="vertical"
                                size={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="574267"
                                  value={ReactSystemFunctions.translate(this.ml, 574267, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="siparisdetayparsiyel_906945_value">
                                  <KSelectBox
                                    id="906945"
                                    kuikaRef={this.siparisdetayparsiyel_906945_value_kuikaSelectBoxRef}
                                    options={this.state.Get_Tanim_MusteriListe_LTL}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      906945,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="firmaAdi"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </VerticalStack>
                            </KCol>
                          </KRow>

                          <KRow
                            id="868897"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="855344"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="206225"
                                direction="vertical"
                                size={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="365389"
                                  value={ReactSystemFunctions.translate(this.ml, 365389, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="siparisdetayparsiyel_271174_value">
                                  <TextArea
                                    id="271174"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      271174,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoSize={false}
                                    showCount={false}
                                    formatter="None"
                                    readOnly={false}
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></TextArea>
                                </Form.Item>
                              </VerticalStack>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="216579"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="120907"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="siparisdetayparsiyel_118776_value" noStyle>
                            <KTab
                              id="118776"
                              type="line"
                              size="default"
                              tabPosition="top"
                              selectedTabColor="rgba(212, 10, 19, 1)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <KTabItem
                                id="664583"
                                tab={ReactSystemFunctions.translate(this.ml, 664583, "tab", this.defaultML)}
                                forceRender={true}
                                key={664583}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <KRow
                                  id="145831"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="812323"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  >
                                    <Button
                                      id="621201"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.SiparisDetayParsiyelComponent_621201_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 621201, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(149, 36, 29, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="247611"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="428727"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="61825"
                                      kuikaRef={this.siparisdetayparsiyel_61825_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.Siparis_Get_SevkEmirListe}
                                      size="middle"
                                      bordered={true}
                                      loading={false}
                                      transformedOnMobileResolution={false}
                                      showHeader={true}
                                      fixedHeader={false}
                                      editable={false}
                                      tableWidthMode="fit"
                                      globalSearch={false}
                                      searchable={false}
                                      sorter={false}
                                      filtering={false}
                                      pagination={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      columnChooser={false}
                                      resizableColumns={false}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      insertRowActive={false}
                                      insertRowPosition="top"
                                      showNoDataFound={true}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        61825,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      multiSelect={false}
                                      export={false}
                                      editableAlwaysActive={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="225554"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="794778"
                                        hoverFontColor="red"
                                        hoverBgColor="#F5F5F5"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderColor: "rgba(217, 217, 217, 1)",
                                            borderStyle: "solid",
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="161036"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            161036,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="922089"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="190424"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.SiparisDetayParsiyelComponent_190424_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="edit"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Icon
                                              id="791152"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.SiparisDetayParsiyelComponent_791152_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="delete_outline"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(250, 84, 28, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Icon
                                              id="722409"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.SiparisDetayParsiyelComponent_722409_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="print"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>
                                          </HorizontalStack>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="595857"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            595857,
                                            "title",
                                            this.defaultML
                                          )}
                                          isEditableColumn={true}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="494226"
                                            value="[datafield:siraNo]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <Form.Item className="kFormItem" name="Table_61825_siraNo">
                                            <NumberInput
                                              id="96534"
                                              onChange={(e: any) => {
                                                this.props.form.setFieldValue(
                                                  "Table_61825_siraNo",
                                                  e?.target?.value ?? e
                                                );
                                              }}
                                              defaultValue="[datafield:siraNo]"
                                              decimalSeparator="."
                                              decimalScale={2}
                                              disabled={false}
                                              placeholder={ReactSystemFunctions.translate(
                                                this.ml,
                                                96534,
                                                "placeholder",
                                                this.defaultML
                                              )}
                                              fixedDecimalScale={false}
                                              allowNegative={true}
                                              allowEmptyFormatting={false}
                                              isThousandSeperatorOn={true}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderTopWidth: 1,
                                                  borderRightWidth: 1,
                                                  borderBottomWidth: 1,
                                                  borderLeftWidth: 1,
                                                  borderColor: "rgba(218, 218, 218, 1)",
                                                  borderStyle: "solid",
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)"
                                                } as any
                                              }
                                            ></NumberInput>
                                          </Form.Item>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="630845"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            630845,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="136405"
                                            value="[datafield:yuklemeYeri]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="205776"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            205776,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="203950"
                                            value="[datafield:varisYeri]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="401051"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            401051,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="662659"
                                            value="[datafield:paletAdedi]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="422013"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            422013,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="siparisdetayparsiyel_802801_value">
                                            <Switch
                                              id="802801"
                                              value="[datafield:yuklemeYapildi]"
                                              disabled={false}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)"
                                                } as any
                                              }
                                            ></Switch>
                                          </Form.Item>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="231707"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            231707,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="siparisdetayparsiyel_2400_value">
                                            <Switch
                                              id="2400"
                                              value="[datafield:depoyaAlindi]"
                                              disabled={false}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)"
                                                } as any
                                              }
                                            ></Switch>
                                          </Form.Item>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="808179"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            808179,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="siparisdetayparsiyel_653264_value">
                                            <Switch
                                              id="653264"
                                              value="[datafield:teslimAlindi]"
                                              disabled={false}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)"
                                                } as any
                                              }
                                            ></Switch>
                                          </Form.Item>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>
                                  </KCol>
                                </KRow>
                              </KTabItem>

                              <KTabItem
                                id="585054"
                                tab={ReactSystemFunctions.translate(this.ml, 585054, "tab", this.defaultML)}
                                forceRender={true}
                                key={585054}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              ></KTabItem>
                            </KTab>
                          </Form.Item>
                        </KCol>
                      </KRow>

                      <KRow
                        id="38245"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="31999"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Button
                            id="8606"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.SiparisDetayParsiyelComponent_8606_onClick();
                            }}
                            showCursorPointer
                            label={ReactSystemFunctions.translate(this.ml, 8606, "label", this.defaultML)}
                            size="middle"
                            loading={false}
                            ghost={false}
                            block={false}
                            htmlType="button"
                            iconPosition="left"
                            danger={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(43, 42, 76, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 16,
                                paddingBottom: 8,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "16px",
                                color: "rgba(43, 42, 76, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></Button>
                        </KCol>

                        <KCol
                          id="359378"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Button
                            id="205082"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.SiparisDetayParsiyelComponent_205082_onClick();
                            }}
                            showCursorPointer
                            label={ReactSystemFunctions.translate(this.ml, 205082, "label", this.defaultML)}
                            size="middle"
                            loading={false}
                            ghost={false}
                            block={false}
                            htmlType="button"
                            iconPosition="left"
                            danger={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(149, 36, 29, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 16,
                                paddingBottom: 8,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "16px",
                                color: "rgba(255, 255, 255, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></Button>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KCol>
                </KRow>

                <KModal
                  id="968196"
                  visible={this.state.NavVisible968196}
                  onOk={() => {
                    this.setState({ NavVisible968196: false }, () => {
                      this.callCallbackFunction(968196);
                    });
                  }}
                  onCancel={() => {
                    this.setState({ NavVisible968196: false }, () => {
                      this.callCallbackFunction(968196);
                    });
                  }}
                  closable={true}
                  width="1000px"
                  footer={null}
                  height="75vh"
                  children={
                    this.state.NavVisible968196 && (
                      <SiparisSevkEmriDetay_Screen
                        onClose={() => {
                          this.setState({ NavVisible968196: false }, () => {
                            this.callCallbackFunction(968196);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                ></KModal>

                <KModal
                  id="348695"
                  visible={this.state.NavVisible348695}
                  onOk={() => {
                    this.setState({ NavVisible348695: false }, () => {
                      this.callCallbackFunction(348695);
                    });
                  }}
                  onCancel={() => {
                    this.setState({ NavVisible348695: false }, () => {
                      this.callCallbackFunction(348695);
                    });
                  }}
                  closable={true}
                  width="1000px"
                  footer={null}
                  height="75vh"
                  children={
                    this.state.NavVisible348695 && (
                      <SiparisSevkEmriDetay_Screen
                        onClose={() => {
                          this.setState({ NavVisible348695: false }, () => {
                            this.callCallbackFunction(348695);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                ></KModal>

                <KDrawer
                  id="453804"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible453804: false }, () => {
                      this.callCallbackFunction(453804);
                    });
                  }}
                  visible={this.state.NavVisible453804}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible453804 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible453804: false }, () => {
                            this.callCallbackFunction(453804);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(SiparisDetayParsiyel_Screen))))
  )
);
export { tmp as SiparisDetayParsiyel_Screen };
//export default tmp;
//export { tmp as SiparisDetayParsiyel_Screen };
