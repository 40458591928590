import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISiparisSevkEmriDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISiparisSevkEmriDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Tanim_AracListe: any[];
  Get_Tanim_AracListe_dummy: any[];
  Get_Tanim_SurucuListe: any[];
  Get_Tanim_SurucuListe_dummy: any[];
  Get_Tanim_MusteriNoktaListe: any[];
  Get_Tanim_MusteriNoktaListe_dummy: any[];
  Get_Tanim_MusteriNoktaListe_Target: any[];
  Get_Tanim_MusteriNoktaListe_Target_dummy: any[];
  Get_Tanim_TedarikciListe: any[];
  Get_Tanim_TedarikciListe_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  Siparis_Get_SevkEmriDetay: any[];
  Siparis_Get_SevkEmriDetay_dummy: any[];
  Multiply: number;
  Multiply_dummy: number;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  Sum: number;
  Sum_dummy: number;
  Siparis_Add_SevkEmri: number;
  Siparis_Add_SevkEmri_dummy: number;
  isComp68145Visible: "visible" | "hidden";
  isComp148235Visible: "visible" | "hidden";
  isComp912531Visible: "visible" | "hidden";
  isComp908275Enabled: "enabled" | "disabled";
}

export class SiparisSevkEmriDetay_ScreenBase extends React.PureComponent<ISiparisSevkEmriDetay_ScreenProps, any> {
  siparissevkemridetay_203689_value_kuikaDateRef: React.RefObject<any>;
  siparissevkemridetay_995984_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparissevkemridetay_878662_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparissevkemridetay_281991_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparissevkemridetay_830996_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparissevkemridetay_249847_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparissevkemridetay_811972_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparissevkemridetay_158907_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparissevkemridetay_969512_value_kuikaDateRef: React.RefObject<any>;
  siparissevkemridetay_95704_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "a6f2899e-5087-4011-abe5-a59751dcd3c1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 741963, PropertyName: "value", Value: "Tarih" },
        { Id: 203689, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 65302, PropertyName: "value", Value: "YÜKLEME - VARIŞ ADRESLERİ" },
        { Id: 541626, PropertyName: "value", Value: "Yükleme Noktası" },
        { Id: 995984, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 682918, PropertyName: "value", Value: "Varış Noktası" },
        { Id: 878662, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 265509, PropertyName: "value", Value: "Ön  Toplama Yapılacak" },
        { Id: 5207, PropertyName: "value", Value: "ARAÇ BİLGİLERİ" },
        { Id: 501297, PropertyName: "value", Value: "Araç Plaka" },
        { Id: 281991, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 489227, PropertyName: "value", Value: "Sürücü" },
        { Id: 830996, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 853831, PropertyName: "value", Value: "ÜRÜN - FİYAT BİLGİLERİ" },
        { Id: 99168, PropertyName: "value", Value: "Ürün Cinsi" },
        { Id: 949947, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 439568, PropertyName: "value", Value: "Palet Adedi" },
        { Id: 899952, PropertyName: "placeholder", Value: "0" },
        { Id: 653895, PropertyName: "value", Value: "Palet Tipi" },
        { Id: 249847, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 688024, PropertyName: "value", Value: "Fiyat" },
        { Id: 731384, PropertyName: "placeholder", Value: "0" },
        { Id: 853665, PropertyName: "value", Value: "Özel Fiyat" },
        { Id: 490674, PropertyName: "placeholder", Value: "0" },
        { Id: 95129, PropertyName: "value", Value: "Ön Toplama Fiyatı" },
        { Id: 805950, PropertyName: "placeholder", Value: "0" },
        { Id: 209626, PropertyName: "value", Value: "Toplam" },
        { Id: 908275, PropertyName: "placeholder", Value: "0" },
        { Id: 726985, PropertyName: "value", Value: "Tedarikçi" },
        { Id: 811972, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 197601, PropertyName: "value", Value: "Masraf" },
        { Id: 434580, PropertyName: "placeholder", Value: "0" },
        { Id: 304833, PropertyName: "value", Value: "Kdv Dahil" },
        { Id: 682869, PropertyName: "value", Value: "Faturalanacak" },
        { Id: 307859, PropertyName: "value", Value: "Derece" },
        { Id: 158907, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 480760, PropertyName: "value", Value: "Palet Ağırlığı" },
        { Id: 555265, PropertyName: "placeholder", Value: "15" },
        { Id: 262440, PropertyName: "value", Value: "YÜKLEME - TESLİMAT DURUMLARI" },
        { Id: 120422, PropertyName: "value", Value: "Yükleme Yapıldı" },
        { Id: 130816, PropertyName: "value", Value: "Yükleme Tarihi" },
        { Id: 969512, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 668368, PropertyName: "value", Value: "Teslim Edildi" },
        { Id: 307408, PropertyName: "value", Value: "Teslimat Tarihi" },
        { Id: 95704, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 8606, PropertyName: "label", Value: "Vazgeç" },
        { Id: 205082, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.siparissevkemridetay_203689_value_kuikaDateRef = React.createRef();
    this.siparissevkemridetay_995984_value_kuikaSelectBoxRef = React.createRef();
    this.siparissevkemridetay_878662_value_kuikaSelectBoxRef = React.createRef();
    this.siparissevkemridetay_281991_value_kuikaSelectBoxRef = React.createRef();
    this.siparissevkemridetay_830996_value_kuikaSelectBoxRef = React.createRef();
    this.siparissevkemridetay_249847_value_kuikaSelectBoxRef = React.createRef();
    this.siparissevkemridetay_811972_value_kuikaSelectBoxRef = React.createRef();
    this.siparissevkemridetay_158907_value_kuikaSelectBoxRef = React.createRef();
    this.siparissevkemridetay_969512_value_kuikaDateRef = React.createRef();
    this.siparissevkemridetay_95704_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Tanim_AracListe: [],
      Get_Tanim_SurucuListe: [],
      Get_Tanim_MusteriNoktaListe: [],
      Get_Tanim_MusteriNoktaListe_Target: [],
      Get_Tanim_TedarikciListe: [],
      ChangeEnabledOf: "",
      Siparis_Get_SevkEmriDetay: [],
      Multiply: 0,
      ChangeVisibilityOf: "",
      Sum: 0,
      Siparis_Add_SevkEmri: 0,
      dataSource_249847: [
        { key: "80x120", text: "80x120" },
        { key: "100x120", text: "100x120" }
      ],
      dataSource_158907: [
        { key: "-18", text: "-18" },
        { key: "+4", text: "+4" },
        { key: "+18", text: "+18" }
      ],
      isComp68145Visible: "visible",
      isComp148235Visible: "visible",
      isComp912531Visible: "hidden",
      isComp908275Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siparissevkemridetay", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SiparisSevkEmriDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("siparissevkemridetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("siparissevkemridetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SiparisSevkEmriDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      siparissevkemridetay_203689_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.tarih ?? undefined,
      siparissevkemridetay_995984_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.yuklemeNokta ?? undefined,
      siparissevkemridetay_878662_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.varisNokta ?? undefined,
      siparissevkemridetay_243178_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.onToplamaYapilacak ?? undefined,
      siparissevkemridetay_281991_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.aracId ?? undefined,
      siparissevkemridetay_830996_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.surucuId ?? undefined,
      siparissevkemridetay_949947_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.urunCinsi ?? undefined,
      siparissevkemridetay_899952_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.paletAdedi ?? undefined,
      siparissevkemridetay_249847_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.paletTipi ?? undefined,
      siparissevkemridetay_731384_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.fiyat ?? undefined,
      siparissevkemridetay_490674_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.ozelFiyat ?? undefined,
      siparissevkemridetay_805950_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.onToplamaFiyati ?? undefined,
      siparissevkemridetay_908275_value: this.state.Sum,
      siparissevkemridetay_811972_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.tedarikciId ?? undefined,
      siparissevkemridetay_434580_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.masrafTutar ?? undefined,
      siparissevkemridetay_549657_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.kdvDahil ?? undefined,
      siparissevkemridetay_216544_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.faturaOlussun ?? undefined,
      siparissevkemridetay_158907_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.nakliyeDerece ?? undefined,
      siparissevkemridetay_555265_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.paletAgirlik ?? undefined,
      siparissevkemridetay_275883_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.yuklemeYapildi ?? undefined,
      siparissevkemridetay_969512_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.yuklemeTarihi ?? undefined,
      siparissevkemridetay_846146_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.teslimAlindi ?? undefined,
      siparissevkemridetay_95704_value: this.state.Siparis_Get_SevkEmriDetay?.at?.(0)?.teslimAlmaTarihi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SiparisSevkEmriDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MusteriId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MusteriId ?? this.props.screenInputs.musteriid,
        "Guid"
      ),
      MusteriId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MusteriId ?? this.props.screenInputs.musteriid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisSevkEmriDetay/SiparisSevkEmriDetayPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Tanim_AracListe = result?.data.get_Tanim_AracListe;

    formVars.siparissevkemridetay_281991_options = stateVars.Get_Tanim_AracListe;
    stateVars.Get_Tanim_SurucuListe = result?.data.get_Tanim_SurucuListe;

    formVars.siparissevkemridetay_830996_options = stateVars.Get_Tanim_SurucuListe;
    stateVars.Get_Tanim_MusteriNoktaListe = result?.data.get_Tanim_MusteriNoktaListe;

    formVars.siparissevkemridetay_995984_options = stateVars.Get_Tanim_MusteriNoktaListe;
    stateVars.Get_Tanim_MusteriNoktaListe_Target = result?.data.get_Tanim_MusteriNoktaListe_Target;

    formVars.siparissevkemridetay_878662_options = stateVars.Get_Tanim_MusteriNoktaListe_Target;
    stateVars.Get_Tanim_TedarikciListe = result?.data.get_Tanim_TedarikciListe;

    formVars.siparissevkemridetay_811972_options = stateVars.Get_Tanim_TedarikciListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisSevkEmriDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisSevkEmriDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp908275Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisSevkEmriDetayPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SiparisId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      SevkEmriId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkEmriId ?? this.props.screenInputs.sevkemriid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisSevkEmriDetay/SiparisSevkEmriDetayPageInit2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Get_SevkEmriDetay = result?.data.siparis_Get_SevkEmriDetay;
    formVars.siparissevkemridetay_203689_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.tarih : null
    );
    formVars.siparissevkemridetay_995984_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.yuklemeNokta : null;
    formVars.siparissevkemridetay_878662_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.varisNokta : null;
    formVars.siparissevkemridetay_243178_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0
        ? stateVars.Siparis_Get_SevkEmriDetay[0]?.onToplamaYapilacak
        : null
    );
    formVars.siparissevkemridetay_281991_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.aracId : null;
    formVars.siparissevkemridetay_830996_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.surucuId : null;
    formVars.siparissevkemridetay_949947_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.urunCinsi : null
    );
    formVars.siparissevkemridetay_899952_value = ReactSystemFunctions.toString(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.paletAdedi : null
    );
    formVars.siparissevkemridetay_249847_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.paletTipi : null;
    formVars.siparissevkemridetay_731384_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.fiyat : null
    );
    formVars.siparissevkemridetay_490674_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.ozelFiyat : null
    );
    formVars.siparissevkemridetay_805950_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.onToplamaFiyati : null
    );
    formVars.siparissevkemridetay_811972_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.tedarikciId : null;
    formVars.siparissevkemridetay_434580_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.masrafTutar : null
    );
    formVars.siparissevkemridetay_549657_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.kdvDahil : null
    );
    formVars.siparissevkemridetay_216544_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.faturaOlussun : null
    );
    formVars.siparissevkemridetay_158907_value =
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.nakliyeDerece : null;
    formVars.siparissevkemridetay_555265_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.paletAgirlik : null
    );
    formVars.siparissevkemridetay_275883_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.yuklemeYapildi : null
    );
    formVars.siparissevkemridetay_969512_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.yuklemeTarihi : null
    );
    formVars.siparissevkemridetay_846146_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.teslimAlindi : null
    );
    formVars.siparissevkemridetay_95704_value = ReactSystemFunctions.value(
      this,
      stateVars.Siparis_Get_SevkEmriDetay?.length > 0 ? stateVars.Siparis_Get_SevkEmriDetay[0]?.teslimAlmaTarihi : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisSevkEmriDetayPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisSevkEmriDetayPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Multiply = await ReactSystemFunctions.multiply(
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "siparissevkemridetay_731384_value", "value", "", "", "")
      ),
      ReactSystemFunctions.toDouble(
        this,
        ReactSystemFunctions.value(this, "siparissevkemridetay_899952_value", "value", "", "", "")
      )
    );
    formVars.siparissevkemridetay_203689_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.tarih : null
    );

    formVars.siparissevkemridetay_995984_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.yuklemeNokta : null
    );

    stateVars.dataSource_995984 = this.state.Get_Tanim_MusteriNoktaListe;
    stateVars.dataSource_995984 = this.state.Get_Tanim_MusteriNoktaListe;
    formVars.siparissevkemridetay_878662_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.varisNokta : null
    );

    stateVars.dataSource_878662 = this.state.Get_Tanim_MusteriNoktaListe_Target;
    stateVars.dataSource_878662 = this.state.Get_Tanim_MusteriNoktaListe_Target;
    formVars.siparissevkemridetay_243178_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.onToplamaYapilacak
        : null
    );

    formVars.siparissevkemridetay_281991_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.aracId : null
    );

    stateVars.dataSource_281991 = this.state.Get_Tanim_AracListe;
    stateVars.dataSource_281991 = this.state.Get_Tanim_AracListe;
    formVars.siparissevkemridetay_830996_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.surucuId : null
    );

    stateVars.dataSource_830996 = this.state.Get_Tanim_SurucuListe;
    stateVars.dataSource_830996 = this.state.Get_Tanim_SurucuListe;
    formVars.siparissevkemridetay_949947_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.urunCinsi : null
    );

    formVars.siparissevkemridetay_899952_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.paletAdedi : null
    );

    formVars.siparissevkemridetay_249847_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.paletTipi : null
    );

    formVars.siparissevkemridetay_731384_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.fiyat : null
    );

    formVars.siparissevkemridetay_490674_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.ozelFiyat : null
    );

    formVars.siparissevkemridetay_805950_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.onToplamaFiyati : null
    );

    formVars.siparissevkemridetay_811972_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.tedarikciId : null
    );

    stateVars.dataSource_811972 = this.state.Get_Tanim_TedarikciListe;
    stateVars.dataSource_811972 = this.state.Get_Tanim_TedarikciListe;
    formVars.siparissevkemridetay_434580_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.masrafTutar : null
    );

    formVars.siparissevkemridetay_549657_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.kdvDahil : null
    );

    formVars.siparissevkemridetay_216544_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.faturaOlussun : null
    );

    formVars.siparissevkemridetay_158907_value = ReactSystemFunctions.toString(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.nakliyeDerece : null
    );

    formVars.siparissevkemridetay_555265_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.paletAgirlik : null
    );

    formVars.siparissevkemridetay_275883_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.yuklemeYapildi : null
    );

    formVars.siparissevkemridetay_969512_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.yuklemeTarihi : null
    );

    formVars.siparissevkemridetay_846146_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0 ? this.state.Siparis_Get_SevkEmriDetay[0]?.teslimAlindi : null
    );

    formVars.siparissevkemridetay_95704_value = ReactSystemFunctions.value(
      this,
      this.state.Siparis_Get_SevkEmriDetay?.length > 0
        ? this.state.Siparis_Get_SevkEmriDetay[0]?.teslimAlmaTarihi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_89525_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "91a2dfa5_a04d_2814_85e5_22f66ef3aec9_confirmation",
        this.defaultML,
        "Kaydedilmemş veriler iptal edilecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_243178_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_243178_value", "value", "", "", "")
        ),
        false
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp68145Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_243178_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_243178_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_243178_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_243178_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp148235Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_243178_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_243178_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_243178_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_243178_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp68145Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_243178_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_243178_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_243178_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_243178_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp148235Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_899952_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Multiply = await ReactSystemFunctions.multiply(
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "siparissevkemridetay_731384_value", "value", "", "", "")
      ),
      ReactSystemFunctions.toDouble(
        this,
        ReactSystemFunctions.value(this, "siparissevkemridetay_899952_value", "value", "", "", "")
      )
    );
    stateVars.Sum = await ReactSystemFunctions.sum(
      ReactSystemFunctions.value(this, stateVars.Multiply),
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "siparissevkemridetay_805950_value", "value", "", "", "")
      )
    );
    formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, stateVars.Sum);
    formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, stateVars.Sum);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_731384_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Multiply = await ReactSystemFunctions.multiply(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_731384_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_899952_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_731384_onBlur1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_731384_onBlur1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_731384_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        null
      )
    ) {
      stateVars.Sum = await ReactSystemFunctions.sum(
        ReactSystemFunctions.value(this, this.state.Multiply),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_805950_value", "value", "", "", "")
        )
      );
      formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, stateVars.Sum);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_731384_onBlur2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_731384_onBlur2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_731384_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        null
      )
    ) {
      formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, this.state.Sum);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_490674_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Multiply = await ReactSystemFunctions.multiply(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_899952_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_490674_onBlur1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_490674_onBlur1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_490674_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      stateVars.Sum = await ReactSystemFunctions.sum(
        ReactSystemFunctions.value(this, this.state.Multiply),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_805950_value", "value", "", "", "")
        )
      );
      formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, stateVars.Sum);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_490674_onBlur2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_490674_onBlur2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_490674_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, this.state.Sum);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_805950_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Multiply = await ReactSystemFunctions.multiply(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_731384_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_899952_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_805950_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      stateVars.Sum = await ReactSystemFunctions.sum(
        ReactSystemFunctions.value(this, this.state.Multiply),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_805950_value", "value", "", "", "")
        )
      );
      formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, stateVars.Sum);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_805950_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      stateVars.Multiply = await ReactSystemFunctions.multiply(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toDouble(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_899952_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_805950_onBlur3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      stateVars.Sum = await ReactSystemFunctions.sum(
        ReactSystemFunctions.value(this, this.state.Multiply),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_805950_value", "value", "", "", "")
        )
      );
      formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, stateVars.Sum);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SiparisSevkEmriDetayComponent_805950_onBlur4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_805950_onBlur4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "0"
      )
    ) {
      formVars.siparissevkemridetay_908275_value = ReactSystemFunctions.value(this, this.state.Sum);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_8606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "72f0572a_c5d2_701e_3737_33e0e1b939a1_confirmation",
        this.defaultML,
        "Kaydedilmemş veriler iptal edilecek. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SiparisSevkEmriDetayComponent_205082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SevkEmriId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkEmriId ?? this.props.screenInputs.sevkemriid,
        "Guid"
      ),
      SiraNo_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      SiparisId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SiparisId ?? this.props.screenInputs.siparisid,
        "Guid"
      ),
      YuklemeYapildi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_275883_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeslimAlindi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_846146_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TeslimAlmaTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_95704_value", "value", "", "", "")
        ),
        "Date"
      ),
      YuklemeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_969512_value", "value", "", "", "")
        ),
        "Date"
      ),
      Tarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_203689_value", "value", "", "", "")
        ),
        "Date"
      ),
      PaletAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_899952_value", "value", "", "", "")
        ),
        "number"
      ),
      UrunCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_949947_value", "value", "", "", "")
        ),
        "string"
      ),
      PaletTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_249847_value", "value", "", "key", "")
        ),
        "string"
      ),
      NakliyeDerece_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_158907_value", "value", "", "key", "")
        ),
        "string"
      ),
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparissevkemridetay_281991_value",
            "value",
            "Get_Tanim_AracListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SurucuId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparissevkemridetay_830996_value",
            "value",
            "Get_Tanim_SurucuListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      YuklemeNokta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparissevkemridetay_995984_value",
            "value",
            "Get_Tanim_MusteriNoktaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      VarisNokta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparissevkemridetay_878662_value",
            "value",
            "Get_Tanim_MusteriNoktaListe_Target",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Fiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_731384_value", "value", "", "", "")
        ),
        "number"
      ),
      OzelFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_490674_value", "value", "", "", "")
        ),
        "number"
      ),
      KdvDahil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_549657_value", "value", "", "", "")
        ),
        "boolean"
      ),
      FaturaOlussun_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_216544_value", "value", "", "", "")
        ),
        "boolean"
      ),
      OnToplamaYapilacak_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_243178_value", "value", "", "", "")
        ),
        "boolean"
      ),
      OnToplamaFiyati_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_805950_value", "value", "", "", "")
        ),
        "number"
      ),
      ToplamFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_908275_value", "value", "", "", "")
        ),
        "number"
      ),
      TedarikciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "siparissevkemridetay_811972_value",
            "value",
            "Get_Tanim_TedarikciListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      MasrafTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_434580_value", "value", "", "", "")
        ),
        "number"
      ),
      PaletAgirlik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparissevkemridetay_555265_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisSevkEmriDetay/SiparisSevkEmriDetayComponent_205082_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Siparis_Add_SevkEmri = result?.data.siparis_Add_SevkEmri;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisSevkEmriDetayComponent_205082_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisSevkEmriDetayComponent_205082_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
