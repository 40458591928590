import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYuklemeBekleyenListe_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYuklemeBekleyenListe_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Yukleme_Delete_Temp_UserId: number;
  Yukleme_Delete_Temp_UserId_dummy: number;
  Yukleme_Get_BekleyenListe: any[];
  Yukleme_Get_BekleyenListe_dummy: any[];
  Get_Tanim_AracListe: any[];
  Get_Tanim_AracListe_dummy: any[];
  Get_Tanim_SurucuListe: any[];
  Get_Tanim_SurucuListe_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  Get_Yonetim_CikisDepoListesi: any[];
  Get_Yonetim_CikisDepoListesi_dummy: any[];
  Get_Yonetim_GirisDepoListesi: any[];
  Get_Yonetim_GirisDepoListesi_dummy: any[];
  Yukleme_Add_Temp_All: number;
  Yukleme_Add_Temp_All_dummy: number;
  Yukleme_Get_ToplamPaletSayisi: any[];
  Yukleme_Get_ToplamPaletSayisi_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  Yukleme_Save_Master: number;
  Yukleme_Save_Master_dummy: number;
  Yukleme_Add_Temp: number;
  Yukleme_Add_Temp_dummy: number;
  Yukleme_Delete_Temp: number;
  Yukleme_Delete_Temp_dummy: number;
  isComp141348Enabled: "enabled" | "disabled";
}

export class YuklemeBekleyenListe_ScreenBase extends React.PureComponent<IYuklemeBekleyenListe_ScreenProps, any> {
  yuklemebekleyenliste_196798_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemebekleyenliste_395040_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemebekleyenliste_440347_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemebekleyenliste_265732_value_kuikaSelectBoxRef: React.RefObject<any>;
  yuklemebekleyenliste_193255_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "7fd4ddb4-038b-4e5a-8867-468453da5126",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "YÜKLEME OLUŞTURMA" },
        { Id: 545838, PropertyName: "value", Value: "Yükleme No" },
        { Id: 597368, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 344309, PropertyName: "value", Value: "Çıkış Depo" },
        { Id: 196798, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 271507, PropertyName: "value", Value: "Varış Depo" },
        { Id: 395040, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 284092, PropertyName: "value", Value: "Araç" },
        { Id: 440347, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 75795, PropertyName: "value", Value: "Sürücü" },
        { Id: 265732, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 657109, PropertyName: "value", Value: "Açıklama" },
        { Id: 500547, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 426889, PropertyName: "value", Value: "Navlun Bedeli" },
        { Id: 235086, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 166901, PropertyName: "value", Value: "Seçilen Palet Sayısı" },
        { Id: 141348, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 269914, PropertyName: "label", Value: "Tümünü Seç" },
        { Id: 82851, PropertyName: "label", Value: "Tümünü Temizle" },
        { Id: 550332, PropertyName: "label", Value: "Yeni Yükleme Oluştur" },
        { Id: 193255, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 342782, PropertyName: "title", Value: "İşlemler" },
        { Id: 653167, PropertyName: "title", Value: "Tarih" },
        { Id: 259672, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 957391, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 631987, PropertyName: "value", Value: "[datafield:musteriadi]" },
        { Id: 351493, PropertyName: "title", Value: "Sipariş No" },
        { Id: 783660, PropertyName: "value", Value: "[datafield:siparisno]" },
        { Id: 576392, PropertyName: "title", Value: "Yükleme Yeri" },
        { Id: 224432, PropertyName: "value", Value: "[datafield:yuklemeyeri]" },
        { Id: 375948, PropertyName: "title", Value: "Varış Yeri" },
        { Id: 315784, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 448855, PropertyName: "title", Value: "Varış İl" },
        { Id: 77669, PropertyName: "value", Value: "[datafield:varisil]" },
        { Id: 90810, PropertyName: "title", Value: "Ön Toplama Yapılacak" },
        { Id: 673245, PropertyName: "title", Value: "Derece" },
        { Id: 508336, PropertyName: "value", Value: "[datafield:nakliyederece]" },
        { Id: 212889, PropertyName: "title", Value: "Ürün Cinsi" },
        { Id: 226097, PropertyName: "value", Value: "[datafield:uruncinsi]" },
        { Id: 576161, PropertyName: "title", Value: "Palet Sayısı" },
        { Id: 558168, PropertyName: "value", Value: "[datafield:paletadedi]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.yuklemebekleyenliste_196798_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemebekleyenliste_395040_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemebekleyenliste_440347_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemebekleyenliste_265732_value_kuikaSelectBoxRef = React.createRef();
    this.yuklemebekleyenliste_193255_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Yukleme_Delete_Temp_UserId: 0,
      Yukleme_Get_BekleyenListe: [],
      Get_Tanim_AracListe: [],
      Get_Tanim_SurucuListe: [],
      ChangeEnabledOf: "",
      Get_Yonetim_CikisDepoListesi: [],
      Get_Yonetim_GirisDepoListesi: [],
      Yukleme_Add_Temp_All: 0,
      Yukleme_Get_ToplamPaletSayisi: [],
      SetValueOf: "",
      Notify: false,
      Yukleme_Save_Master: 0,
      Yukleme_Add_Temp: 0,
      Yukleme_Delete_Temp: 0,
      isComp141348Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yuklemebekleyenliste", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YuklemeBekleyenListePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yuklemebekleyenliste", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yuklemebekleyenliste", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YuklemeBekleyenListePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YuklemeBekleyenListePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YuklemeBekleyenListe/YuklemeBekleyenListePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Yukleme_Delete_Temp_UserId = result?.data.yukleme_Delete_Temp_UserId;
    stateVars.Yukleme_Get_BekleyenListe = result?.data.yukleme_Get_BekleyenListe;

    stateVars.Get_Tanim_AracListe = result?.data.get_Tanim_AracListe;

    formVars.yuklemebekleyenliste_440347_options = stateVars.Get_Tanim_AracListe;
    stateVars.Get_Tanim_SurucuListe = result?.data.get_Tanim_SurucuListe;

    formVars.yuklemebekleyenliste_265732_options = stateVars.Get_Tanim_SurucuListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp141348Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListePageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YuklemeBekleyenListePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      DepoId_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      DepoId_1: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeBekleyenListe/YuklemeBekleyenListePageInit2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Yonetim_CikisDepoListesi = result?.data.get_Yonetim_CikisDepoListesi;

    formVars.yuklemebekleyenliste_196798_options = stateVars.Get_Yonetim_CikisDepoListesi;
    stateVars.Get_Yonetim_GirisDepoListesi = result?.data.get_Yonetim_GirisDepoListesi;

    formVars.yuklemebekleyenliste_395040_options = stateVars.Get_Yonetim_GirisDepoListesi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListePageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListePageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_196798 = this.state.Get_Yonetim_CikisDepoListesi;
    stateVars.dataSource_196798 = this.state.Get_Yonetim_CikisDepoListesi;

    stateVars.dataSource_395040 = this.state.Get_Yonetim_GirisDepoListesi;
    stateVars.dataSource_395040 = this.state.Get_Yonetim_GirisDepoListesi;

    stateVars.dataSource_440347 = this.state.Get_Tanim_AracListe;
    stateVars.dataSource_440347 = this.state.Get_Tanim_AracListe;

    stateVars.dataSource_265732 = this.state.Get_Tanim_SurucuListe;
    stateVars.dataSource_265732 = this.state.Get_Tanim_SurucuListe;

    stateVars.dataSource_193255 = this.state.Yukleme_Get_BekleyenListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YuklemeBekleyenListeComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YuklemeBekleyenListe",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeBekleyenListeComponent_196798_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      DepoId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yuklemebekleyenliste_196798_value",
            "value",
            "Get_Yonetim_CikisDepoListesi",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_196798_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Yonetim_GirisDepoListesi = result?.data.get_Yonetim_GirisDepoListesi;

    formVars.yuklemebekleyenliste_395040_options = stateVars.Get_Yonetim_GirisDepoListesi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListeComponent_196798_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_196798_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_395040 = this.state.Get_Yonetim_GirisDepoListesi;
    stateVars.dataSource_395040 = this.state.Get_Yonetim_GirisDepoListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeBekleyenListeComponent_395040_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      DepoId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yuklemebekleyenliste_395040_value",
            "value",
            "Get_Yonetim_GirisDepoListesi",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_395040_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Yonetim_CikisDepoListesi = result?.data.get_Yonetim_CikisDepoListesi;

    formVars.yuklemebekleyenliste_196798_options = stateVars.Get_Yonetim_CikisDepoListesi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListeComponent_395040_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_395040_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_196798 = this.state.Get_Yonetim_CikisDepoListesi;
    stateVars.dataSource_196798 = this.state.Get_Yonetim_CikisDepoListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeBekleyenListeComponent_269914_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      UserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_269914_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Yukleme_Add_Temp_All = result?.data.yukleme_Add_Temp_All;
    stateVars.Yukleme_Get_BekleyenListe = result?.data.yukleme_Get_BekleyenListe;

    stateVars.Yukleme_Get_ToplamPaletSayisi = result?.data.yukleme_Get_ToplamPaletSayisi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListeComponent_269914_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_269914_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yuklemebekleyenliste_141348_value",
      ReactSystemFunctions.toString(
        this,
        this.state.Yukleme_Get_ToplamPaletSayisi?.length > 0
          ? this.state.Yukleme_Get_ToplamPaletSayisi[0]?.secilenPaletSayisi
          : null
      ),
      null
    );

    stateVars.dataSource_193255 = this.state.Yukleme_Get_BekleyenListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeBekleyenListeComponent_82851_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      UserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_82851_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Yukleme_Delete_Temp_UserId = result?.data.yukleme_Delete_Temp_UserId;
    stateVars.Yukleme_Get_BekleyenListe = result?.data.yukleme_Get_BekleyenListe;

    stateVars.Yukleme_Get_ToplamPaletSayisi = result?.data.yukleme_Get_ToplamPaletSayisi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListeComponent_82851_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_82851_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yuklemebekleyenliste_141348_value",
      ReactSystemFunctions.toString(
        this,
        this.state.Yukleme_Get_ToplamPaletSayisi?.length > 0
          ? this.state.Yukleme_Get_ToplamPaletSayisi[0]?.secilenPaletSayisi
          : null
      ),
      null
    );

    stateVars.dataSource_193255 = this.state.Yukleme_Get_BekleyenListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeBekleyenListeComponent_550332_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "20568619_18f7_1a7d_2d48_4c6d6bb9ea65_notify",
        this.defaultML,
        "Araç seçmeden yükleme oluşturulamaz"
      ),
      "danger",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListeComponent_550332_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_550332_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      YuklemeId_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      YuklemeNo_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.firmaId
            : null
        ),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yuklemebekleyenliste_500547_value", "value", "", "", "")
        ),
        "string"
      ),
      AracId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yuklemebekleyenliste_440347_value",
            "value",
            "Get_Tanim_AracListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SurucuId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yuklemebekleyenliste_265732_value",
            "value",
            "Get_Tanim_SurucuListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      NavlunBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toDecimal(
          this,
          ReactSystemFunctions.value(this, "yuklemebekleyenliste_235086_value", "value", "", "", "")
        ),
        "number"
      ),
      CikisDepo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yuklemebekleyenliste_196798_value",
            "value",
            "Get_Yonetim_CikisDepoListesi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      VarisDepo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yuklemebekleyenliste_395040_value",
            "value",
            "Get_Yonetim_GirisDepoListesi",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "yuklemebekleyenliste_440347_value",
            "value",
            "Get_Tanim_AracListe",
            "id",
            "id"
          )
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_550332_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Yukleme_Save_Master = result?.data.yukleme_Save_Master;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeBekleyenListeComponent_550332_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeBekleyenListeComponent_550332_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_550332_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.YuklemeBekleyenListeComponent_550332_onClick3_,
      "YuklemeBekleyenListe",
      "YuklemeListesi",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_550332_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "94eff47e_a165_bf00_4bf5_49f6e8989663_notify",
        this.defaultML,
        "Sürücü seçmeden yükleme oluşturulamaz"
      ),
      "danger",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YuklemeBekleyenListeComponent_374019_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SevkEmriDetayId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "yuklemebekleyenliste_193255_value", "id"),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "yuklemebekleyenliste_193255_value", "secildi"),
        false
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_374019_onChange",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Yukleme_Add_Temp = result?.data.yukleme_Add_Temp;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeBekleyenListeComponent_374019_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeBekleyenListeComponent_374019_onChange1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_374019_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "yuklemebekleyenliste_193255_value", "tempId"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "yuklemebekleyenliste_193255_value", "secildi"),
        true
      )
    ) {
      stateVars.Confirm = await Swal.fire({
        title: ReactSystemFunctions.translateCustomActions(
          this.ml,
          "521c2026_cf5d_0fc8_f1da_41f5ca8d8f95_confirmation",
          this.defaultML,
          "Seçili kayıt yükleme listesinden çıkartılacak. Emin misiniz ?"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Onayla"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Confirmer"
            : "Confirm",
        cancelButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Vazgeç"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Annuler"
            : "Cancel",
        customClass: {
          title: "swal2-font-poppins"
        }
      });

      if (stateVars.Confirm.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_374019_onChange1_",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.Yukleme_Delete_Temp = result?.data.yukleme_Delete_Temp;
        this.props.form.setFieldsValue(formVars);

        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
            isErrorOccurred = await this.YuklemeBekleyenListeComponent_374019_onChange2_();
            resolve();
          });
        });
      }
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YuklemeBekleyenListeComponent_374019_onChange2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_374019_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YuklemeBekleyenListe/YuklemeBekleyenListeComponent_374019_onChange2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Yukleme_Get_BekleyenListe = result?.data.yukleme_Get_BekleyenListe;

    stateVars.Yukleme_Get_ToplamPaletSayisi = result?.data.yukleme_Get_ToplamPaletSayisi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YuklemeBekleyenListeComponent_374019_onChange3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YuklemeBekleyenListeComponent_374019_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yuklemebekleyenliste_141348_value",
      ReactSystemFunctions.toString(
        this,
        this.state.Yukleme_Get_ToplamPaletSayisi?.length > 0
          ? this.state.Yukleme_Get_ToplamPaletSayisi[0]?.secilenPaletSayisi
          : null
      ),
      null
    );

    stateVars.dataSource_193255 = this.state.Yukleme_Get_BekleyenListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.YuklemeBekleyenListePageInit();
    }
  }
}
