import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITanimMusteriTedarikciBilgiKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITanimMusteriTedarikciBilgiKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_FirmaListe: any[];
  Get_Yonetim_FirmaListe_dummy: any[];
  Get_Yonetim_UlkeListe: any[];
  Get_Yonetim_UlkeListe_dummy: any[];
  Get_Tanim_MusteriTedarikci_ById: any[];
  Get_Tanim_MusteriTedarikci_ById_dummy: any[];
  Get_Yonetim_SehirListe_ByUlkeId: any[];
  Get_Yonetim_SehirListe_ByUlkeId_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  Add_Tanim_MusteriTedarikci: number;
  Add_Tanim_MusteriTedarikci_dummy: number;
  Update_Tanim_MusteriTedarikci: number;
  Update_Tanim_MusteriTedarikci_dummy: number;
}

export class TanimMusteriTedarikciBilgiKart_ScreenBase extends React.PureComponent<
  ITanimMusteriTedarikciBilgiKart_ScreenProps,
  any
> {
  tanimmusteritedarikcibilgikart_810791_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimmusteritedarikcibilgikart_128337_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimmusteritedarikcibilgikart_529531_value_kuikaSelectBoxRef: React.RefObject<any>;
  tanimmusteritedarikcibilgikart_673631_value_kuikaAutoCompleteRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "31d003c4-8e38-4a5e-81ee-9ba816c1335c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 891214, PropertyName: "value", Value: "MÜŞTERİ-TEDARİKÇİ KARTI" },
        { Id: 249608, PropertyName: "value", Value: "Firma Kodu" },
        { Id: 810791, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 125168, PropertyName: "value", Value: "Tipi" },
        { Id: 128337, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 767051, PropertyName: "value", Value: "Firma Adı" },
        { Id: 607530, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 442030, PropertyName: "value", Value: "Full Truck" },
        { Id: 457881, PropertyName: "value", Value: "Parsiyel" },
        { Id: 994318, PropertyName: "value", Value: "İlgili Kişi" },
        { Id: 293322, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 684043, PropertyName: "value", Value: "Telefon" },
        { Id: 249909, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 172445, PropertyName: "value", Value: "E-Posta" },
        { Id: 742449, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 963162, PropertyName: "value", Value: "Erp Kodu" },
        { Id: 658282, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 19732, PropertyName: "value", Value: "Vergi No" },
        { Id: 329303, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 901754, PropertyName: "value", Value: "Vergi Dairesi" },
        { Id: 384250, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 478031, PropertyName: "value", Value: "Ülke" },
        { Id: 529531, PropertyName: "placeholder", Value: "Ülke Seçiniz..." },
        { Id: 352045, PropertyName: "value", Value: "Şehir" },
        { Id: 673631, PropertyName: "placeholder", Value: "Şehir Seçiniz..." },
        { Id: 116678, PropertyName: "value", Value: "Adres" },
        { Id: 211124, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 974814, PropertyName: "label", Value: "Vazgeç" },
        { Id: 865072, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tanimmusteritedarikcibilgikart_810791_value_kuikaSelectBoxRef = React.createRef();
    this.tanimmusteritedarikcibilgikart_128337_value_kuikaSelectBoxRef = React.createRef();
    this.tanimmusteritedarikcibilgikart_529531_value_kuikaSelectBoxRef = React.createRef();
    this.tanimmusteritedarikcibilgikart_673631_value_kuikaAutoCompleteRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_FirmaListe: [],
      Get_Yonetim_UlkeListe: [],
      Get_Tanim_MusteriTedarikci_ById: [],
      Get_Yonetim_SehirListe_ByUlkeId: [],
      GoBack: "",
      Add_Tanim_MusteriTedarikci: 0,
      Update_Tanim_MusteriTedarikci: 0,
      dataSource_128337: [
        { key: "M", text: "MÜŞTERİ" },
        { key: "T", text: "TEDARİKÇİ" },
        { key: "D", text: "DİĞER" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tanimmusteritedarikcibilgikart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TanimMusteriTedarikciBilgiKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tanimmusteritedarikcibilgikart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tanimmusteritedarikcibilgikart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TanimMusteriTedarikciBilgiKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tanimmusteritedarikcibilgikart_810791_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.firmaId ?? undefined,
      tanimmusteritedarikcibilgikart_128337_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.tipi ?? undefined,
      tanimmusteritedarikcibilgikart_607530_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.firmaAdi ?? undefined,
      tanimmusteritedarikcibilgikart_372848_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.ftl ?? undefined,
      tanimmusteritedarikcibilgikart_989227_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.ltl ?? undefined,
      tanimmusteritedarikcibilgikart_293322_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.ilgiliKisiAdi ?? undefined,
      tanimmusteritedarikcibilgikart_249909_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.telefon ?? undefined,
      tanimmusteritedarikcibilgikart_742449_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.ePosta ?? undefined,
      tanimmusteritedarikcibilgikart_658282_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.ePosta ?? undefined,
      tanimmusteritedarikcibilgikart_329303_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.vergiNo ?? undefined,
      tanimmusteritedarikcibilgikart_384250_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.vergiDairesi ?? undefined,
      tanimmusteritedarikcibilgikart_529531_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.ulkeId ?? undefined,
      tanimmusteritedarikcibilgikart_673631_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.sehirId ?? undefined,
      tanimmusteritedarikcibilgikart_211124_value:
        this.state.Get_Tanim_MusteriTedarikci_ById?.at?.(0)?.adres ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TanimMusteriTedarikciBilgiKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriTedarikciBilgiKart/TanimMusteriTedarikciBilgiKartPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Yonetim_FirmaListe = result?.data.get_Yonetim_FirmaListe;

    formVars.tanimmusteritedarikcibilgikart_810791_options = stateVars.Get_Yonetim_FirmaListe;
    stateVars.Get_Yonetim_UlkeListe = result?.data.get_Yonetim_UlkeListe;

    formVars.tanimmusteritedarikcibilgikart_529531_options = stateVars.Get_Yonetim_UlkeListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimMusteriTedarikciBilgiKart/TanimMusteriTedarikciBilgiKartPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Get_Tanim_MusteriTedarikci_ById = result?.data.get_Tanim_MusteriTedarikci_ById;
      formVars.tanimmusteritedarikcibilgikart_810791_value =
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.firmaId
          : null;
      formVars.tanimmusteritedarikcibilgikart_128337_value =
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.tipi
          : null;
      formVars.tanimmusteritedarikcibilgikart_607530_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.firmaAdi
          : null
      );
      formVars.tanimmusteritedarikcibilgikart_372848_value = ReactSystemFunctions.value(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0 ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.ftl : null
      );
      formVars.tanimmusteritedarikcibilgikart_989227_value = ReactSystemFunctions.value(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0 ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.ltl : null
      );
      formVars.tanimmusteritedarikcibilgikart_293322_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.ilgiliKisiAdi
          : null
      );
      formVars.tanimmusteritedarikcibilgikart_249909_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.telefon
          : null
      );
      formVars.tanimmusteritedarikcibilgikart_742449_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.ePosta
          : null
      );
      formVars.tanimmusteritedarikcibilgikart_658282_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.ePosta
          : null
      );
      formVars.tanimmusteritedarikcibilgikart_329303_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.vergiNo
          : null
      );
      formVars.tanimmusteritedarikcibilgikart_384250_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.vergiDairesi
          : null
      );
      formVars.tanimmusteritedarikcibilgikart_529531_value =
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.ulkeId
          : null;
      formVars.tanimmusteritedarikcibilgikart_673631_value =
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.sehirId
          : null;
      formVars.tanimmusteritedarikcibilgikart_211124_value = ReactSystemFunctions.toString(
        this,
        stateVars.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? stateVars.Get_Tanim_MusteriTedarikci_ById[0]?.adres
          : null
      );
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_810791_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.firmaId
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;
    stateVars.dataSource_810791 = this.state.Get_Yonetim_FirmaListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_128337_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.tipi
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_607530_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.firmaAdi
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_372848_value = ReactSystemFunctions.value(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.ftl
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_989227_value = ReactSystemFunctions.value(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.ltl
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_293322_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.ilgiliKisiAdi
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_249909_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.telefon
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_742449_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.ePosta
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_658282_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.ePosta
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_329303_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.vergiNo
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_384250_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.vergiDairesi
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_529531_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.ulkeId
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_529531 = this.state.Get_Yonetim_UlkeListe;
    stateVars.dataSource_529531 = this.state.Get_Yonetim_UlkeListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit16_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_673631_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.sehirId
          : null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      formVars.tanimmusteritedarikcibilgikart_211124_value = ReactSystemFunctions.toString(
        this,
        this.state.Get_Tanim_MusteriTedarikci_ById?.length > 0
          ? this.state.Get_Tanim_MusteriTedarikci_ById[0]?.adres
          : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TanimMusteriTedarikciBilgiKartComponent_529531_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UlkeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusteritedarikcibilgikart_529531_value",
            "value",
            "Get_Yonetim_UlkeListe",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TanimMusteriTedarikciBilgiKart/TanimMusteriTedarikciBilgiKartComponent_529531_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Yonetim_SehirListe_ByUlkeId = result?.data.get_Yonetim_SehirListe_ByUlkeId;

    formVars.tanimmusteritedarikcibilgikart_673631_options = stateVars.Get_Yonetim_SehirListe_ByUlkeId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartComponent_529531_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartComponent_529531_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_673631 = this.state.Get_Yonetim_SehirListe_ByUlkeId;
    stateVars.dataSource_673631 = this.state.Get_Yonetim_SehirListe_ByUlkeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TanimMusteriTedarikciBilgiKartComponent_974814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "43fc490a_712c_ab21_16b0_3731dcc99abb_confirmation",
        this.defaultML,
        "Kayıt edilmeyen veriler iptal edilecek"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TanimMusteriTedarikciBilgiKartComponent_865072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FirmaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_607530_value", "value", "", "", "")
        ),
        "string"
      ),
      Tipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_128337_value", "value", "", "key", "")
        ),
        "string"
      ),
      EPosyta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_742449_value", "value", "", "", "")
        ),
        "string"
      ),
      IlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_293322_value", "value", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_249909_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusteritedarikcibilgikart_810791_value",
            "value",
            "Get_Yonetim_FirmaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      ErpKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_658282_value", "value", "", "", "")
        ),
        "string"
      ),
      UlkeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusteritedarikcibilgikart_529531_value",
            "value",
            "Get_Yonetim_UlkeListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusteritedarikcibilgikart_673631_value",
            "value",
            "Get_Yonetim_SehirListe_ByUlkeId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_211124_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_384250_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_329303_value", "value", "", "", "")
        ),
        "string"
      ),
      FTL_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_372848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      LTL_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_989227_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimMusteriTedarikciBilgiKart/TanimMusteriTedarikciBilgiKartComponent_865072_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Add_Tanim_MusteriTedarikci = result?.data.add_Tanim_MusteriTedarikci;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartComponent_865072_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartComponent_865072_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartComponent_865072_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FirmaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_607530_value", "value", "", "", "")
        ),
        "string"
      ),
      Tipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_128337_value", "value", "", "key", "")
        ),
        "string"
      ),
      EPosta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_742449_value", "value", "", "", "")
        ),
        "string"
      ),
      IlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_293322_value", "value", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_249909_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      FirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusteritedarikcibilgikart_810791_value",
            "value",
            "Get_Yonetim_FirmaListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      ErpKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_658282_value", "value", "", "", "")
        ),
        "string"
      ),
      UlkeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusteritedarikcibilgikart_529531_value",
            "value",
            "Get_Yonetim_UlkeListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tanimmusteritedarikcibilgikart_673631_value",
            "value",
            "Get_Yonetim_SehirListe_ByUlkeId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_211124_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_384250_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_329303_value", "value", "", "", "")
        ),
        "string"
      ),
      FTL_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_372848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      LTL_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tanimmusteritedarikcibilgikart_989227_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.MusteriTedarikciId ?? this.props.screenInputs.musteritedarikciid,
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TanimMusteriTedarikciBilgiKart/TanimMusteriTedarikciBilgiKartComponent_865072_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.Update_Tanim_MusteriTedarikci = result?.data.update_Tanim_MusteriTedarikci;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartComponent_865072_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TanimMusteriTedarikciBilgiKartComponent_865072_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TanimMusteriTedarikciBilgiKartComponent_865072_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
