import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParsiyelMusteriSevk_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParsiyelMusteriSevk_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Yonetim_DepoListe: any[];
  Get_Yonetim_DepoListe_dummy: any[];
  MusteriSevk_BelgeOku: any[];
  MusteriSevk_BelgeOku_dummy: any[];
  MusteriSevk_AracaYuklenenler: any[];
  MusteriSevk_AracaYuklenenler_dummy: any[];
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  Get_Tanim_AracByPlaka: any[];
  Get_Tanim_AracByPlaka_dummy: any[];
  SetFocusTo: any;
  SetFocusTo_dummy: any;
  MusteriSevk_YeniBelge: any[];
  MusteriSevk_YeniBelge_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  MusteriSevk_BelgeTamamla: number;
  MusteriSevk_BelgeTamamla_dummy: number;
  MusteriSevk_KayitEkle: number;
  MusteriSevk_KayitEkle_dummy: number;
  MusteriSevk_KayitSil: number;
  MusteriSevk_KayitSil_dummy: number;
  isComp988373Visible: "visible" | "hidden";
  isComp124440Visible: "visible" | "hidden";
}

export class ParsiyelMusteriSevk_ScreenBase extends React.PureComponent<IParsiyelMusteriSevk_ScreenProps, any> {
  parsiyelmusterisevk_213603_value_kuikaSelectBoxRef: React.RefObject<any>;
  parsiyelmusterisevk_193255_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "19a16d89-a4dd-451d-8d05-cdc550744b0f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "MÜŞTERİYE SEVK" },
        { Id: 19888, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 275970, PropertyName: "value", Value: "Çıkış Deposu" },
        { Id: 213603, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 258869, PropertyName: "value", Value: "Araç Plakası" },
        { Id: 500313, PropertyName: "placeholder", Value: "Araç plakasını okutun ..." },
        { Id: 542212, PropertyName: "value", Value: "Sürücü Adı" },
        { Id: 410855, PropertyName: "label", Value: "Yeni Sevk" },
        { Id: 185855, PropertyName: "label", Value: "Tamamla" },
        { Id: 462904, PropertyName: "value", Value: "Palet Barkodu" },
        { Id: 555597, PropertyName: "placeholder", Value: "Palet barkodunu okutun ..." },
        { Id: 320081, PropertyName: "label", Value: "Vazgeç" },
        { Id: 997405, PropertyName: "label", Value: "Ekle" },
        { Id: 193255, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 272683, PropertyName: "title", Value: "İşlem" },
        { Id: 389021, PropertyName: "title", Value: "Barkod" },
        { Id: 272957, PropertyName: "value", Value: "[datafield:barkod]" },
        { Id: 72781, PropertyName: "title", Value: "Ürün Cinsi" },
        { Id: 696211, PropertyName: "value", Value: "[datafield:uruncinsi]" },
        { Id: 733547, PropertyName: "title", Value: "Çıkış Yeri" },
        { Id: 113319, PropertyName: "value", Value: "[datafield:cikisyeri]" },
        { Id: 18272, PropertyName: "title", Value: "Varış Yeri" },
        { Id: 53533, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 453204, PropertyName: "title", Value: "Toplam Palet Sayısı" },
        { Id: 339359, PropertyName: "value", Value: "[datafield:paletadedi]" },
        { Id: 732477, PropertyName: "title", Value: "Palet Tipi" },
        { Id: 432326, PropertyName: "value", Value: "[datafield:palettipi]" },
        { Id: 706659, PropertyName: "title", Value: "Derece" },
        { Id: 879049, PropertyName: "value", Value: "[datafield:nakliyederece]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.parsiyelmusterisevk_213603_value_kuikaSelectBoxRef = React.createRef();
    this.parsiyelmusterisevk_193255_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Yonetim_DepoListe: [],
      MusteriSevk_BelgeOku: [],
      MusteriSevk_AracaYuklenenler: [],
      ToggleVisibilityOf: "",
      Get_Tanim_AracByPlaka: [],
      SetFocusTo: "",
      MusteriSevk_YeniBelge: [],
      SetValueOf: "",
      MusteriSevk_BelgeTamamla: 0,
      MusteriSevk_KayitEkle: 0,
      MusteriSevk_KayitSil: 0,
      isComp988373Visible: "hidden",
      isComp124440Visible: "hidden"
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevk", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ParsiyelMusteriSevkPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevk", "");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["SistemAdmin", "SuperUser", "Operasyon", "OperasyonLTL", "DepoLTL"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevk", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParsiyelMusteriSevkPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      parsiyelmusterisevk_213603_value: this.state.MusteriSevk_BelgeOku?.at?.(0)?.cikisDepo ?? undefined,
      parsiyelmusterisevk_500313_value: this.state.MusteriSevk_BelgeOku?.at?.(0)?.plaka ?? undefined,
      parsiyelmusterisevk_771350_value: this.state.MusteriSevk_BelgeOku?.at?.(0)?.surucuAdi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ParsiyelMusteriSevkPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParsiyelMusteriSevk/ParsiyelMusteriSevkPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Yonetim_DepoListe = result?.data.get_Yonetim_DepoListe;

    formVars.parsiyelmusterisevk_213603_options = stateVars.Get_Yonetim_DepoListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_213603 = this.state.Get_Yonetim_DepoListe;
    stateVars.dataSource_213603 = this.state.Get_Yonetim_DepoListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelMusteriSevk",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_19888_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SevkNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_19888_value", "value", "", "", "")
        ),
        "number"
      ),
      SevkNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_19888_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevk/ParsiyelMusteriSevkComponent_19888_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MusteriSevk_BelgeOku = result?.data.musteriSevk_BelgeOku;
    formVars.parsiyelmusterisevk_213603_value =
      stateVars.MusteriSevk_BelgeOku?.length > 0 ? stateVars.MusteriSevk_BelgeOku[0]?.cikisDepo : null;
    formVars.parsiyelmusterisevk_500313_value = ReactSystemFunctions.toString(
      this,
      stateVars.MusteriSevk_BelgeOku?.length > 0 ? stateVars.MusteriSevk_BelgeOku[0]?.plaka : null
    );
    formVars.parsiyelmusterisevk_771350_value = ReactSystemFunctions.toString(
      this,
      stateVars.MusteriSevk_BelgeOku?.length > 0 ? stateVars.MusteriSevk_BelgeOku[0]?.surucuAdi : null
    );
    stateVars.MusteriSevk_AracaYuklenenler = result?.data.musteriSevk_AracaYuklenenler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkComponent_19888_onPressEnter1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_19888_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.parsiyelmusterisevk_213603_value = ReactSystemFunctions.toString(
      this,
      this.state.MusteriSevk_BelgeOku?.length > 0 ? this.state.MusteriSevk_BelgeOku[0]?.cikisDepo : null
    );

    formVars.parsiyelmusterisevk_500313_value = ReactSystemFunctions.toString(
      this,
      this.state.MusteriSevk_BelgeOku?.length > 0 ? this.state.MusteriSevk_BelgeOku[0]?.plaka : null
    );

    formVars.parsiyelmusterisevk_771350_value = ReactSystemFunctions.toString(
      this,
      this.state.MusteriSevk_BelgeOku?.length > 0 ? this.state.MusteriSevk_BelgeOku[0]?.surucuAdi : null
    );

    stateVars.dataSource_193255 = this.state.MusteriSevk_AracaYuklenenler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_19888_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.MusteriSevk_BelgeOku?.length > 0 ? this.state.MusteriSevk_BelgeOku[0]?.id : null
        ),
        "0"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp988373Visible");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteriSevkComponent_19888_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteriSevkComponent_19888_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_19888_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.MusteriSevk_BelgeOku?.length > 0 ? this.state.MusteriSevk_BelgeOku[0]?.id : null
        ),
        "0"
      )
    ) {
      stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp124440Visible");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_500313_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_500313_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SevkNo_1: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevk/ParsiyelMusteriSevkComponent_500313_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Tanim_AracByPlaka = result?.data.get_Tanim_AracByPlaka;
    stateVars.MusteriSevk_AracaYuklenenler = result?.data.musteriSevk_AracaYuklenenler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkComponent_500313_onPressEnter1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_500313_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("555597");

    stateVars.dataSource_193255 = this.state.MusteriSevk_AracaYuklenenler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_410855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Plaka_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_500313_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SevkNo_1: ReactSystemFunctions.convertToTypeByName(0, "number"),
      BelgeId_1: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SurucuAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_771350_value", "value", "", "", "")
        ),
        "string"
      ),
      CikisDepo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "parsiyelmusterisevk_213603_value",
            "value",
            "Get_Yonetim_DepoListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      CreatedBy_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevk/ParsiyelMusteriSevkComponent_410855_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Tanim_AracByPlaka = result?.data.get_Tanim_AracByPlaka;
    stateVars.MusteriSevk_YeniBelge = result?.data.musteriSevk_YeniBelge;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkComponent_410855_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_410855_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "parsiyelmusterisevk_19888_value",
      ReactSystemFunctions.toString(
        this,
        this.state.MusteriSevk_YeniBelge?.length > 0 ? this.state.MusteriSevk_YeniBelge[0]?.sevkBelgeNo : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkComponent_410855_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_410855_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.MusteriSevk_YeniBelge?.length > 0 ? this.state.MusteriSevk_YeniBelge[0]?.sevkBelgeNo : null
        ),
        "0"
      )
    ) {
      stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp988373Visible");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteriSevkComponent_410855_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteriSevkComponent_410855_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_410855_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.MusteriSevk_YeniBelge?.length > 0 ? this.state.MusteriSevk_YeniBelge[0]?.sevkBelgeNo : null
        ),
        "0"
      )
    ) {
      stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp124440Visible");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_185855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MusteriSevk_YeniBelge?.length > 0 ? this.state.MusteriSevk_YeniBelge[0]?.sevkBelgeId : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevk/ParsiyelMusteriSevkComponent_185855_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MusteriSevk_BelgeTamamla = result?.data.musteriSevk_BelgeTamamla;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkComponent_185855_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_185855_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelMusteriSevk",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_320081_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parsiyelmusterisevk_555597_value", "", null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_997405_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SevkBelgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MusteriSevk_YeniBelge?.length > 0 ? this.state.MusteriSevk_YeniBelge[0]?.sevkBelgeId : null
        ),
        "Guid"
      ),
      BarkodId_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      PaletBarkod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_555597_value", "value", "", "", "")
        ),
        "string"
      ),
      SevkNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_19888_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevk/ParsiyelMusteriSevkComponent_997405_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MusteriSevk_KayitEkle = result?.data.musteriSevk_KayitEkle;
    stateVars.MusteriSevk_AracaYuklenenler = result?.data.musteriSevk_AracaYuklenenler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkComponent_997405_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_997405_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MalKabul_BarkodOkut?.length > 0 ? this.state.MalKabul_BarkodOkut[0]?.sonuc : null
        ),
        "OK"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parsiyelmusterisevk_555597_value", "", null);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteriSevkComponent_997405_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteriSevkComponent_997405_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_997405_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("555597");

    stateVars.dataSource_193255 = this.state.MusteriSevk_AracaYuklenenler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkComponent_392400_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "parsiyelmusterisevk_193255_value", "id"),
        "Guid"
      ),
      Plaka_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_500313_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SevkNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevk_19888_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1c8adfcb_b57c_b75b_c242_83a332261fed_confirmation",
        this.defaultML,
        "Seçilen kayıt araçtan çıkartılacak. Emin misiniz ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParsiyelMusteriSevk/ParsiyelMusteriSevkComponent_392400_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.MusteriSevk_KayitSil = result?.data.musteriSevk_KayitSil;
      stateVars.Get_Tanim_AracByPlaka = result?.data.get_Tanim_AracByPlaka;
      stateVars.MusteriSevk_AracaYuklenenler = result?.data.musteriSevk_AracaYuklenenler;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParsiyelMusteriSevkComponent_392400_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkComponent_392400_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.MusteriSevk_AracaYuklenenler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ParsiyelMusteriSevkPageInit();
    }
  }
}
