import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRaporFTLMusteriDetayli_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRaporFTLMusteriDetayli_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  ReadFromSessionStorage: string;
  ReadFromSessionStorage_dummy: string;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  Get_Rapor_FTL_MusteriDetay: any[];
  Get_Rapor_FTL_MusteriDetay_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
}

export class RaporFTLMusteriDetayli_ScreenBase extends React.PureComponent<IRaporFTLMusteriDetayli_ScreenProps, any> {
  raporftlmusteridetayli_247612_value_kuikaSelectBoxRef: React.RefObject<any>;
  raporftlmusteridetayli_217982_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "8b9cf6c2-03c9-4d23-a21c-f05cf511c293",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 53715, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 510105, PropertyName: "value", Value: "MÜŞTERİ BAZINDA FTL  RAPORU" },
        { Id: 826956, PropertyName: "label", Value: "Yenile" },
        { Id: 383222, PropertyName: "label", Value: "Excele Aktar" },
        { Id: 217982, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 156356, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 934519, PropertyName: "value", Value: "[datafield:firmaadi]" },
        { Id: 809449, PropertyName: "title", Value: "Teslimay Ay" },
        { Id: 544658, PropertyName: "value", Value: "[datafield:teslimatayadi]" },
        { Id: 210685, PropertyName: "title", Value: "Teslimat Sayısı" },
        { Id: 443188, PropertyName: "value", Value: "[datafield:teslimedilen]" },
        { Id: 394826, PropertyName: "title", Value: "Tutar" },
        { Id: 344735, PropertyName: "value", Value: "[datafield:tutar]" },
        { Id: 238978, PropertyName: "title", Value: "Kdv" },
        { Id: 680766, PropertyName: "value", Value: "[datafield:kdv]" },
        { Id: 552741, PropertyName: "title", Value: "Net Tutar" },
        { Id: 920347, PropertyName: "value", Value: "[datafield:nettutar]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.raporftlmusteridetayli_247612_value_kuikaSelectBoxRef = React.createRef();
    this.raporftlmusteridetayli_217982_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      ReadFromSessionStorage: "",
      Get_Helpers_LoginUserInfo: [],
      SetValueOf: "",
      Get_Rapor_FTL_MusteriDetay: [],
      ExportExcel: "",
      dataSource_247612: [
        { key: "2023", text: "2023" },
        { key: "2024", text: "2024" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("raporftlmusteridetayli", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RaporFTLMusteriDetayliPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("raporftlmusteridetayli", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("raporftlmusteridetayli", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RaporFTLMusteriDetayliPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  RaporFTLMusteriDetayliPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("FirmaNo");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporFTLMusteriDetayliPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RaporFTLMusteriDetayliPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporFTLMusteriDetayli/RaporFTLMusteriDetayliPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporFTLMusteriDetayliPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporFTLMusteriDetayliPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "raporftlmusteridetayli_247612_value",
      "2024",
      null,
      "Key"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporFTLMusteriDetayliPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RaporFTLMusteriDetayliPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      TeslimYili_0: ReactSystemFunctions.convertToTypeByName(2024, "number"),
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporFTLMusteriDetayli/RaporFTLMusteriDetayliPageInit3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Rapor_FTL_MusteriDetay = result?.data.get_Rapor_FTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporFTLMusteriDetayliPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporFTLMusteriDetayliPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_217982 = this.state.Get_Rapor_FTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RaporFTLMusteriDetayliComponent_992552_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "RaporFTLMusteriDetayli",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "135022",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporFTLMusteriDetayliComponent_826956_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeslimYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "raporftlmusteridetayli_247612_value", "value", "", "key", "")
        ),
        "number"
      ),
      FirmaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(this, this.state.ReadFromSessionStorage),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporFTLMusteriDetayli/RaporFTLMusteriDetayliComponent_826956_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Rapor_FTL_MusteriDetay = result?.data.get_Rapor_FTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporFTLMusteriDetayliComponent_826956_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporFTLMusteriDetayliComponent_826956_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_217982 = this.state.Get_Rapor_FTL_MusteriDetay;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporFTLMusteriDetayliComponent_383222_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.Get_Rapor_FTL_MusteriDetay),
        "any"
      ),
      header_0: ReactSystemFunctions.convertToTypeByName("Müşteri Bazında FTL Raporu", "string"),
      footer_0: ReactSystemFunctions.convertToTypeByName("SU-FI Lojistik", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporFTLMusteriDetayli/RaporFTLMusteriDetayliComponent_383222_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [135022] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RaporFTLMusteriDetayliPageInit();
    }
  }
}
