const screenInputData = {
  YonetimIlceKart: [{"name":"IlceId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	YonetimSehirKart: [{"name":"SehirId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SiparisDetayParsiyel: [{"name":"SiparisId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TanimMusteriNoktaTanim: [{"name":"MusteriId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SiparisSevkEmriDetay: [{"name":"SiparisId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MusteriId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SevkEmriId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ParsiyelMusteriSevkListeDetay: [{"name":"SevkNo","type":"Int32","cardinality":1,"isExpandedPanel":null}],
	YonetimFirmaKart: [{"name":"FirmaId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ParsiyelPalet: [{"name":"BarkodNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"VarisYeri","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"DepoGirisTarihi","type":"DateTime","cardinality":1,"isExpandedPanel":null},{"name":"Derece","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"SiparisNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"YuklemeYeri","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"VarisAcikAdres","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"ToplamPaletAdedi","type":"Int32","cardinality":1,"isExpandedPanel":null}],
	YonetimKullaniciKart: [{"name":"KullaniciId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SiparisDetayFullTruck: [{"name":"SiparisId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	YonetimUlkeKart: [{"name":"UlkeId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	MalKabulEtiket: [{"name":"SevkEmriId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"BarkodNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	TanimAracBilgiKart: [{"name":"AracId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	YonetimKullaniciEkle: [{"name":"KullaniciId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	YuklemeTeslimatBilgi: [{"name":"SiparisId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MusteriId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SevkEmriId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TanimSurucuBilgiKart: [{"name":"SurucuId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TanimMusteriTedarikciBilgiKart: [{"name":"MusteriTedarikciId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TanimMusteriNoktaBilgiKart: [{"name":"NoktaId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MusteriId","type":"Guid","cardinality":1,"isExpandedPanel":null}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
