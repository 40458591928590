import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILeftMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILeftMenu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp479039Visible: "visible" | "hidden";
  isComp754458Visible: "visible" | "hidden";
  isComp908276Visible: "visible" | "hidden";
  isComp768011Visible: "visible" | "hidden";
  isComp915018Visible: "visible" | "hidden";
  isComp613953Visible: "visible" | "hidden";
  isComp707006Visible: "visible" | "hidden";
  isComp243193Visible: "visible" | "hidden";
  isComp410722Visible: "visible" | "hidden";
  isComp386389Visible: "visible" | "hidden";
  isComp120181Visible: "visible" | "hidden";
  isComp80073Visible: "visible" | "hidden";
  isComp526917Visible: "visible" | "hidden";
  isComp751126Visible: "visible" | "hidden";
  isComp584577Visible: "visible" | "hidden";
  isComp838330Visible: "visible" | "hidden";
  isComp791902Visible: "visible" | "hidden";
  isComp447921Visible: "visible" | "hidden";
  isCompleftmenu_822360AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_692740AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_479039AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_754458AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_768011AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_908276AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_915018AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_707006AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_243193AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_613953AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_337088AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_410722AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_36582AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_386389AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_333943AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_120181AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_80073AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_999949AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_439737AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_554980AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_578039AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_794751AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_865692AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_311104AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_467559AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_664809AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_952581AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_609856AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_723339AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_865727AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_526917AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_881292AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_585871AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_751126AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_584577AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_838330AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_791902AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_447921AuthorizationVisible: "visible" | "hidden";
}

export class LeftMenu_ScreenBase extends React.PureComponent<ILeftMenu_ScreenProps, any> {
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "702694f1-53f6-4615-8fe4-202c29674592",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 501123, PropertyName: "value", Value: "Nakliye Portal Menu" },
        { Id: 950642, PropertyName: "value", Value: "Yönetim" },
        { Id: 804331, PropertyName: "value", Value: "Firma Tanımları" },
        { Id: 745671, PropertyName: "value", Value: "Kullanıcı Tanımları" },
        { Id: 855303, PropertyName: "value", Value: "Ülke Tanımları" },
        { Id: 4216, PropertyName: "value", Value: "Şehir Tanımları" },
        { Id: 77959, PropertyName: "value", Value: "İlçe Tanımları" },
        { Id: 237342, PropertyName: "value", Value: "Tanımlar" },
        { Id: 479825, PropertyName: "value", Value: "Araç Tanımları" },
        { Id: 558967, PropertyName: "value", Value: "Sürücü Tanımları" },
        { Id: 840053, PropertyName: "value", Value: "Müşteri- Tedarikçi Tanımları" },
        { Id: 707001, PropertyName: "value", Value: "Sipariş İşlemleri" },
        { Id: 36582, PropertyName: "value", Value: "Full Truck Nakliye" },
        { Id: 333943, PropertyName: "value", Value: "Parsiyel Nakliye" },
        { Id: 886155, PropertyName: "value", Value: "Yük Oluşturma" },
        { Id: 999949, PropertyName: "value", Value: "Yük Listesi" },
        { Id: 474664, PropertyName: "value", Value: "Depo İşlemleri" },
        { Id: 578039, PropertyName: "value", Value: "Mal Kabul" },
        { Id: 865692, PropertyName: "value", Value: "Araç Yükleme" },
        { Id: 467559, PropertyName: "value", Value: "Depo Transfer Mal Kabul" },
        { Id: 952581, PropertyName: "value", Value: "Müşteriye Sevk" },
        { Id: 723339, PropertyName: "value", Value: "Müşteriye Sevk Irsaliye Basımı" },
        { Id: 189916, PropertyName: "value", Value: "Barkod İşlemleri" },
        { Id: 585871, PropertyName: "value", Value: "Barkod Listesi" },
        { Id: 528438, PropertyName: "value", Value: "Raporlar" },
        { Id: 812793, PropertyName: "value", Value: "Full Truck Raporu" },
        { Id: 6798, PropertyName: "value", Value: "Full Truck Raporu - Müşteri Detaylı" },
        { Id: 681346, PropertyName: "value", Value: "Parsiyel Raporu" },
        { Id: 950094, PropertyName: "value", Value: "Parsiyel Raporu - Müşteri Detaylı" },
        { Id: 831602, PropertyName: "value", Value: "Parsiyel Raporu - Müşteri İl Detaylı" },
        { Id: 188381, PropertyName: "value", Value: "Çıkış" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Get_Helpers_LoginUserInfo: [],
      NAVIGATE: "",
      GoBack: "",
      ToggleVisibilityOf: "",
      Logout: false,
      goToSignInActivity: "",
      isComp479039Visible: "visible",
      isComp754458Visible: "visible",
      isComp908276Visible: "visible",
      isComp768011Visible: "visible",
      isComp915018Visible: "visible",
      isComp613953Visible: "visible",
      isComp707006Visible: "visible",
      isComp243193Visible: "visible",
      isComp410722Visible: "visible",
      isComp386389Visible: "visible",
      isComp120181Visible: "visible",
      isComp80073Visible: "visible",
      isComp526917Visible: "visible",
      isComp751126Visible: "visible",
      isComp584577Visible: "visible",
      isComp838330Visible: "visible",
      isComp791902Visible: "visible",
      isComp447921Visible: "visible",
      isCompleftmenu_822360AuthorizationVisible: "visible",
      isCompleftmenu_692740AuthorizationVisible: "visible",
      isCompleftmenu_479039AuthorizationVisible: "visible",
      isCompleftmenu_754458AuthorizationVisible: "visible",
      isCompleftmenu_768011AuthorizationVisible: "visible",
      isCompleftmenu_908276AuthorizationVisible: "visible",
      isCompleftmenu_915018AuthorizationVisible: "visible",
      isCompleftmenu_707006AuthorizationVisible: "visible",
      isCompleftmenu_243193AuthorizationVisible: "visible",
      isCompleftmenu_613953AuthorizationVisible: "visible",
      isCompleftmenu_337088AuthorizationVisible: "visible",
      isCompleftmenu_410722AuthorizationVisible: "visible",
      isCompleftmenu_36582AuthorizationVisible: "visible",
      isCompleftmenu_386389AuthorizationVisible: "visible",
      isCompleftmenu_333943AuthorizationVisible: "visible",
      isCompleftmenu_120181AuthorizationVisible: "visible",
      isCompleftmenu_80073AuthorizationVisible: "visible",
      isCompleftmenu_999949AuthorizationVisible: "visible",
      isCompleftmenu_439737AuthorizationVisible: "visible",
      isCompleftmenu_554980AuthorizationVisible: "visible",
      isCompleftmenu_578039AuthorizationVisible: "visible",
      isCompleftmenu_794751AuthorizationVisible: "visible",
      isCompleftmenu_865692AuthorizationVisible: "visible",
      isCompleftmenu_311104AuthorizationVisible: "visible",
      isCompleftmenu_467559AuthorizationVisible: "visible",
      isCompleftmenu_664809AuthorizationVisible: "visible",
      isCompleftmenu_952581AuthorizationVisible: "visible",
      isCompleftmenu_609856AuthorizationVisible: "visible",
      isCompleftmenu_723339AuthorizationVisible: "visible",
      isCompleftmenu_865727AuthorizationVisible: "visible",
      isCompleftmenu_526917AuthorizationVisible: "visible",
      isCompleftmenu_881292AuthorizationVisible: "visible",
      isCompleftmenu_585871AuthorizationVisible: "visible",
      isCompleftmenu_751126AuthorizationVisible: "visible",
      isCompleftmenu_584577AuthorizationVisible: "visible",
      isCompleftmenu_838330AuthorizationVisible: "visible",
      isCompleftmenu_791902AuthorizationVisible: "visible",
      isCompleftmenu_447921AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
      return;
    }

    if (true) {
      await this.LeftMenuPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_822360AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_692740AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_479039AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "SistemAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_754458AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_768011AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_908276AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_915018AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "SistemAdmin",
      "SuperUser"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_707006AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_243193AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_613953AuthorizationVisible", [
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_337088AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_410722AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_36582AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_386389AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_333943AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_120181AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_80073AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_999949AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_439737AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_554980AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_578039AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_794751AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_865692AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_311104AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_467559AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_664809AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_952581AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_609856AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_723339AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_865727AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_526917AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_881292AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "3124c881-afa9-64c4-a413-a7dab050596f",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL",
      "DepoLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_585871AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_751126AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_584577AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "ae9cc18c-1277-f9c1-29a1-4976ebb6081e",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonFTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_838330AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_791902AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_447921AuthorizationVisible", [
      "340e8cb3-f976-dd61-e380-0461d10a97c8",
      "9459e43c-c7a1-4bc0-bf4c-db425158076b",
      "5dd30a97-5b3d-4d32-69d1-4079db8ccde8",
      "b4db309c-2564-ed82-7346-5121a6f66ad4",
      "SistemAdmin",
      "SuperUser",
      "Operasyon",
      "OperasyonLTL"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.LeftMenuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LeftMenuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "LeftMenu/LeftMenuPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  LeftMenuComponent_855479_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_620279_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_822360_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YonetimUlkeTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_692740_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp479039Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp754458Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp908276Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp768011Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp915018Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_479039_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YonetimFirmaTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_754458_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YonetimKullaniciTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_908276_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YonetimSehirTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_915018_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YonetimIlceTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_676438_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp613953Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp707006Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp243193Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_707006_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TanimAracTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_243193_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TanimSurucuTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_613953_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TanimMusteriTedarikciTanim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_337088_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp410722Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp386389Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp120181Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp80073Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_410722_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SiparisListesiFullTruck",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_386389_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SiparisListesiParsiyel",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_120181_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YuklemeBekleyenListe",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_80073_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YuklemeListesi",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_439737_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp526917Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_554980_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ParsiyelMusteridenMalKabul",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_794751_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ParsiyelAracYukleme",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_311104_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ParsiyelDatMalKabul",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_664809_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ParsiyelMusteriSevk",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_609856_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ParsiyelMusteriSevkListe",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_526917_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "BarkodListesi",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_29977_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp751126Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp584577Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp838330Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp791902Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp447921Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_751126_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "RaporFullTruckListe",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_584577_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "RaporFTLMusteriDetayli",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_838330_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "RaporParsiyelYukleme",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_791902_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "RaporLTLMusteriDetayli",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_447921_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "RaporLTLMusteriIlDetayli",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_20424_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, , , , , , , , , , , , , , , , , , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.LeftMenuPageInit();
    }
  }
}
