import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYonetimIlceTanim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYonetimIlceTanim_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_IlceListe: any[];
  Get_Yonetim_IlceListe_dummy: any[];
  Delete_Yonetim_Ilce: number;
  Delete_Yonetim_Ilce_dummy: number;
}

export class YonetimIlceTanim_ScreenBase extends React.PureComponent<IYonetimIlceTanim_ScreenProps, any> {
  yonetimilcetanim_371125_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "0a49368b-dbe7-475a-999a-1c26a95b26e0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 740733, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 989668, PropertyName: "value", Value: "İlçe Tanımları" },
        { Id: 537809, PropertyName: "label", Value: "İlçe Ekle" },
        { Id: 371125, PropertyName: "nodatafoundmessage", Value: "Kayıt bulunamadı" },
        { Id: 787271, PropertyName: "title", Value: "İşlemler" },
        { Id: 381194, PropertyName: "title", Value: "Ülke Kodu" },
        { Id: 513495, PropertyName: "value", Value: "[datafield:ulkekodu]" },
        { Id: 707461, PropertyName: "title", Value: "Şehir Kodu" },
        { Id: 565911, PropertyName: "value", Value: "[datafield:sehirkodu]" },
        { Id: 668850, PropertyName: "title", Value: "Şehir Adı" },
        { Id: 379132, PropertyName: "value", Value: "[datafield:sehiradi]" },
        { Id: 256574, PropertyName: "title", Value: "İlçe Adı" },
        { Id: 155560, PropertyName: "value", Value: "[datafield:ilceadi]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.yonetimilcetanim_371125_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_IlceListe: [],
      Delete_Yonetim_Ilce: 0
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yonetimilcetanim", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YonetimIlceTanimPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yonetimilcetanim", "");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["SistemAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yonetimilcetanim", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YonetimIlceTanimPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YonetimIlceTanimPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YonetimIlceTanim/YonetimIlceTanimPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Yonetim_IlceListe = result?.data.get_Yonetim_IlceListe;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YonetimIlceTanimPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YonetimIlceTanimPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_371125 = this.state.Get_Yonetim_IlceListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YonetimIlceTanimComponent_542684_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YonetimIlceTanim",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "179034",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YonetimIlceTanimComponent_537809_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("YonetimIlceKart", "IlceId", "00000000-0000-0000-0000-000000000000");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YonetimIlceTanim",
      "YonetimIlceKart",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "577129",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YonetimIlceTanimComponent_433037_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "YonetimIlceKart",
      "IlceId",
      ReactSystemFunctions.value(this, "yonetimilcetanim_371125_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YonetimIlceTanim",
      "YonetimIlceKart",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "924286",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YonetimIlceTanimComponent_988522_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "Guid"
      ),
      IlceId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "yonetimilcetanim_371125_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YonetimIlceTanim/YonetimIlceTanimComponent_988522_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Delete_Yonetim_Ilce = result?.data.delete_Yonetim_Ilce;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [179034, 577129, 924286] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.YonetimIlceTanimPageInit();
    }
  }
}
