import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParsiyelMusteriSevkListeDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParsiyelMusteriSevkListeDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  MusteriSevk_BelgeOku: any[];
  MusteriSevk_BelgeOku_dummy: any[];
  MusteriSevk_BelgeDetayGoster: any[];
  MusteriSevk_BelgeDetayGoster_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
}

export class ParsiyelMusteriSevkListeDetay_ScreenBase extends React.PureComponent<
  IParsiyelMusteriSevkListeDetay_ScreenProps,
  any
> {
  parsiyelmusterisevklistedetay_371125_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "676337d9-00e1-42f0-96ce-cf8b5788b14e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 740733, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 989668, PropertyName: "value", Value: "Sevk Belgesi Detayları" },
        { Id: 563869, PropertyName: "value", Value: "-" },
        { Id: 39196, PropertyName: "label", Value: "Kapat" },
        { Id: 371125, PropertyName: "nodatafoundmessage", Value: "Kayıt bulunamadı" },
        { Id: 455963, PropertyName: "title", Value: "Barkod" },
        { Id: 53287, PropertyName: "value", Value: "[datafield:barkod]" },
        { Id: 948148, PropertyName: "title", Value: "Çıkış Yeri" },
        { Id: 372881, PropertyName: "value", Value: "[datafield:cikisyeri]" },
        { Id: 603153, PropertyName: "title", Value: "Varış Yeri" },
        { Id: 410851, PropertyName: "value", Value: "[datafield:varisyeri]" },
        { Id: 925168, PropertyName: "title", Value: "Ürün  Cinsi" },
        { Id: 984009, PropertyName: "value", Value: "[datafield:uruncinsi]" },
        { Id: 175998, PropertyName: "title", Value: "Nakliye Derece" },
        { Id: 307354, PropertyName: "value", Value: "[datafield:nakliyederece]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.parsiyelmusterisevklistedetay_371125_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_LoginUserInfo: [],
      MusteriSevk_BelgeOku: [],
      MusteriSevk_BelgeDetayGoster: [],
      SetValueOf: "",
      GoBack: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevklistedetay", "");
      return;
    }

    if (true) {
      await this.ParsiyelMusteriSevkListeDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevklistedetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevklistedetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParsiyelMusteriSevkListeDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      parsiyelmusterisevklistedetay_563869_value: ReactSystemFunctions.translate(
        this.ml,
        563869,
        "value",
        this.defaultML
      )
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ParsiyelMusteriSevkListeDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SevkNo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkNo ?? this.props.screenInputs.sevkno,
        "number"
      ),
      SevkNo_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SevkNo ?? this.props.screenInputs.sevkno,
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevkListeDetay/ParsiyelMusteriSevkListeDetayPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.MusteriSevk_BelgeOku = result?.data.musteriSevk_BelgeOku;
    stateVars.MusteriSevk_BelgeDetayGoster = result?.data.musteriSevk_BelgeDetayGoster;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkListeDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkListeDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "parsiyelmusterisevklistedetay_563869_value",
      this.props.screenInputs.SevkNo ?? this.props.screenInputs.sevkno,
      null
    );

    stateVars.dataSource_371125 = this.state.MusteriSevk_BelgeDetayGoster;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParsiyelMusteriSevkListeDetayComponent_542684_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelMusteriSevkListeDetay",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "179034",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkListeDetayComponent_39196_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [179034] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ParsiyelMusteriSevkListeDetayPageInit();
    }
  }
}
