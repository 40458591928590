import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRaporParsiyelYukleme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRaporParsiyelYukleme_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_UserInfoByUserName: any[];
  Get_Helpers_UserInfoByUserName_dummy: any[];
  Get_Raporlar_ParsiyelYukleme: any[];
  Get_Raporlar_ParsiyelYukleme_dummy: any[];
  Export_Rapor_ParsiyelYukleme: any[];
  Export_Rapor_ParsiyelYukleme_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
}

export class RaporParsiyelYukleme_ScreenBase extends React.PureComponent<IRaporParsiyelYukleme_ScreenProps, any> {
  raporparsiyelyukleme_193255_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "45aef9ae-1273-466d-b88f-3b3c238ea295",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 980166, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 162294, PropertyName: "value", Value: "PARSİYEL YÜKLEME RAPORU" },
        { Id: 387923, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 224893, PropertyName: "label", Value: "Ara" },
        { Id: 269723, PropertyName: "label", Value: "Excele Aktar" },
        { Id: 193255, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 653167, PropertyName: "title", Value: "Ürün Alış Tarihi" },
        { Id: 259672, PropertyName: "value", Value: "[datafield:urunalistarihi]" },
        { Id: 698923, PropertyName: "title", Value: "Sevk Tarihi" },
        { Id: 473919, PropertyName: "value", Value: "[datafield:sevktarihi]" },
        { Id: 751575, PropertyName: "title", Value: "Müşteri" },
        { Id: 217091, PropertyName: "value", Value: "[datafield:musteriadi]" },
        { Id: 20850, PropertyName: "title", Value: "Lokasyon" },
        { Id: 908675, PropertyName: "value", Value: "[datafield:sehiradi]" },
        { Id: 310202, PropertyName: "title", Value: "İlçe" },
        { Id: 606916, PropertyName: "value", Value: "[datafield:ilceadi]" },
        { Id: 804988, PropertyName: "title", Value: "Teslim Müşterisi" },
        { Id: 867319, PropertyName: "value", Value: "[datafield:teslimatmusterisi]" },
        { Id: 62047, PropertyName: "title", Value: "Palet Sayısı" },
        { Id: 208503, PropertyName: "value", Value: "[datafield:paletsayisi]" },
        { Id: 587324, PropertyName: "title", Value: "Birim Fiyat" },
        { Id: 691588, PropertyName: "value", Value: "[datafield:birimfiyat]" },
        { Id: 364262, PropertyName: "title", Value: "Masraf Tutarı" },
        { Id: 708069, PropertyName: "value", Value: "[datafield:masraftutar]" },
        { Id: 334470, PropertyName: "title", Value: "Navlun" },
        { Id: 358245, PropertyName: "value", Value: "[datafield:navlunbedeli]" },
        { Id: 331383, PropertyName: "title", Value: "Toplam Tutar" },
        { Id: 322009, PropertyName: "value", Value: "[datafield:toplamfiyat]" },
        { Id: 920739, PropertyName: "title", Value: "Derece" },
        { Id: 42129, PropertyName: "value", Value: "[datafield:nakliyederece]" },
        { Id: 366764, PropertyName: "title", Value: "İrsaliye" },
        { Id: 432105, PropertyName: "value", Value: "[datafield:irsaliyeno]" },
        { Id: 684701, PropertyName: "title", Value: "Araç Bilgisi" },
        { Id: 80674, PropertyName: "value", Value: "[datafield:aracplakasi]" },
        { Id: 358159, PropertyName: "title", Value: "Şoför" },
        { Id: 856580, PropertyName: "value", Value: "[datafield:surucuadi]" },
        { Id: 845247, PropertyName: "title", Value: "Teslimat Tarihi" },
        { Id: 697914, PropertyName: "value", Value: "[datafield:teslimattarihi]" },
        { Id: 336732, PropertyName: "title", Value: "Yükleme No" },
        { Id: 978513, PropertyName: "value", Value: "[datafield:yuklemeno]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.raporparsiyelyukleme_193255_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_UserInfoByUserName: [],
      Get_Raporlar_ParsiyelYukleme: [],
      Export_Rapor_ParsiyelYukleme: [],
      ExportExcel: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("raporparsiyelyukleme", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RaporParsiyelYuklemePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("raporparsiyelyukleme", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("raporparsiyelyukleme", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RaporParsiyelYuklemePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  RaporParsiyelYuklemePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchText_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "RaporParsiyelYukleme/RaporParsiyelYuklemePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_UserInfoByUserName = result?.data.get_Helpers_UserInfoByUserName;
    stateVars.Get_Raporlar_ParsiyelYukleme = result?.data.get_Raporlar_ParsiyelYukleme;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporParsiyelYuklemePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporParsiyelYuklemePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.Get_Raporlar_ParsiyelYukleme;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RaporParsiyelYuklemeComponent_491786_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "RaporParsiyelYukleme",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "29182",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporParsiyelYuklemeComponent_224893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SearchText_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "raporparsiyelyukleme_387923_value", "value", "", "", "")
        ),
        "string"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporParsiyelYukleme/RaporParsiyelYuklemeComponent_224893_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Raporlar_ParsiyelYukleme = result?.data.get_Raporlar_ParsiyelYukleme;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RaporParsiyelYuklemeComponent_224893_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RaporParsiyelYuklemeComponent_224893_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_193255 = this.state.Get_Raporlar_ParsiyelYukleme;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RaporParsiyelYuklemeComponent_269723_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.Get_Helpers_UserInfoByUserName?.length > 0
            ? this.state.Get_Helpers_UserInfoByUserName[0]?.id
            : null
        ),
        "Guid"
      ),
      SearchText_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "raporparsiyelyukleme_387923_value", "value", "", "", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("PARSİYEL YÜKLEME LİSTESİ", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("ARUNA LOJİSTİK YÖNETİM PORTAL", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RaporParsiyelYukleme/RaporParsiyelYuklemeComponent_269723_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Export_Rapor_ParsiyelYukleme = result?.data.export_Rapor_ParsiyelYukleme;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [29182] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RaporParsiyelYuklemePageInit();
    }
  }
}
