import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParsiyelMusteriSevkListe_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParsiyelMusteriSevkListe_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Get_Helpers_LoginUserInfo: any[];
  Get_Helpers_LoginUserInfo_dummy: any[];
  Get_Yonetim_DepoListe: any[];
  Get_Yonetim_DepoListe_dummy: any[];
  MusteriSevk_BelgeListesi: any[];
  MusteriSevk_BelgeListesi_dummy: any[];
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: any;
  KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport_dummy: any;
  DownloadReport: any;
  DownloadReport_dummy: any;
  isComp447051Enabled: "enabled" | "disabled";
}

export class ParsiyelMusteriSevkListe_ScreenBase extends React.PureComponent<
  IParsiyelMusteriSevkListe_ScreenProps,
  any
> {
  parsiyelmusterisevkliste_916891_value_kuikaDateRef: React.RefObject<any>;
  parsiyelmusterisevkliste_60847_value_kuikaDateRef: React.RefObject<any>;
  parsiyelmusterisevkliste_119948_value_kuikaSelectBoxRef: React.RefObject<any>;
  parsiyelmusterisevkliste_371125_value_kuikaTableRef: React.RefObject<any>;
  parsiyelmusterisevkliste_447051_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "585b6303-ac4a-473f-8893-c915ede3bf84",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "1a31fdda-f849-44dd-bfcc-7e1ebdcb9a79",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 740733, PropertyName: "value", Value: "Aruna Nakliye Yönetim Portalı" },
        { Id: 989668, PropertyName: "value", Value: "Müşteriye Sevk Edilenler" },
        { Id: 821226, PropertyName: "value", Value: "Başlangıç Tarihi" },
        { Id: 916891, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 147581, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 60847, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 165714, PropertyName: "value", Value: "Çıkış Depo" },
        { Id: 119948, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 310552, PropertyName: "label", Value: "Listele" },
        { Id: 371125, PropertyName: "nodatafoundmessage", Value: "Kayıt bulunamadı" },
        { Id: 787271, PropertyName: "title", Value: "İşlemler" },
        { Id: 914224, PropertyName: "title", Value: "Tarih" },
        { Id: 447051, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 539468, PropertyName: "title", Value: "Plaka" },
        { Id: 338411, PropertyName: "value", Value: "[datafield:plaka]" },
        { Id: 457475, PropertyName: "title", Value: "Sürücü Adı" },
        { Id: 921516, PropertyName: "value", Value: "[datafield:surucuadi]" }
      ]
    }
  ];
  defaultML = "en_US";

  screenHash = "";

  constructor(props) {
    super(props);
    this.parsiyelmusterisevkliste_916891_value_kuikaDateRef = React.createRef();
    this.parsiyelmusterisevkliste_60847_value_kuikaDateRef = React.createRef();
    this.parsiyelmusterisevkliste_119948_value_kuikaSelectBoxRef = React.createRef();
    this.parsiyelmusterisevkliste_371125_value_kuikaTableRef = React.createRef();
    this.parsiyelmusterisevkliste_447051_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Get_Helpers_LoginUserInfo: [],
      Get_Yonetim_DepoListe: [],
      MusteriSevk_BelgeListesi: [],
      KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport: "",
      DownloadReport: "",
      isComp447051Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevkliste", "");
      return;
    }

    if (true) {
      await this.ParsiyelMusteriSevkListePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevkliste", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parsiyelmusterisevkliste", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParsiyelMusteriSevkListePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ParsiyelMusteriSevkListePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevkListe/ParsiyelMusteriSevkListePageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Get_Helpers_LoginUserInfo = result?.data.get_Helpers_LoginUserInfo;
    stateVars.Get_Yonetim_DepoListe = result?.data.get_Yonetim_DepoListe;

    formVars.parsiyelmusterisevkliste_119948_options = stateVars.Get_Yonetim_DepoListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkListePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkListePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_119948 = this.state.Get_Yonetim_DepoListe;
    stateVars.dataSource_119948 = this.state.Get_Yonetim_DepoListe;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParsiyelMusteriSevkListeComponent_542684_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelMusteriSevkListe",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "179034",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkListeComponent_310552_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BitisTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevkliste_60847_value", "value", "", "", "")
        ),
        "Date"
      ),
      CikisDepo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "parsiyelmusterisevkliste_119948_value",
            "value",
            "Get_Yonetim_DepoListe",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Baslangic_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parsiyelmusterisevkliste_916891_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevkListe/ParsiyelMusteriSevkListeComponent_310552_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MusteriSevk_BelgeListesi = result?.data.musteriSevk_BelgeListesi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParsiyelMusteriSevkListeComponent_310552_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParsiyelMusteriSevkListeComponent_310552_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_371125 = this.state.MusteriSevk_BelgeListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkListeComponent_433037_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ParsiyelMusteriSevkListeDetay",
      "SevkNo",
      ReactSystemFunctions.value(this, "parsiyelmusterisevkliste_371125_value", "sevkNo")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ParsiyelMusteriSevkListe",
      "ParsiyelMusteriSevkListeDetay",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "924286",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParsiyelMusteriSevkListeComponent_771480_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      _ReportName_0: ReactSystemFunctions.convertToTypeByName("MusteriSevkIrsaliyesi", "string"),
      _SevkEmriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "parsiyelmusterisevkliste_371125_value", "id"),
        "string"
      ),
      _UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.Get_Helpers_LoginUserInfo?.length > 0 ? this.state.Get_Helpers_LoginUserInfo[0]?.id : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParsiyelMusteriSevkListe/ParsiyelMusteriSevkListeComponent_771480_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KuikaReportGetReportBase64_GetReportBaseFromArunakuikareport =
      result?.data.kuikaReportGetReportBase64_GetReportBaseFromArunakuikareport;
    stateVars.DownloadReport = result?.data.downloadReport;
    ReactSystemFunctions.downloadFile(result?.data?.downloadReport);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [179034, 924286] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ParsiyelMusteriSevkListePageInit();
    }
  }
}
